<template>
    <Teleport to="#appContent">
        <div class="quote-comment" v-click-away="closeComment">
            <div class="comment-container" :class="commentIsOpen && 'open'">
                <heading-block
                    class="w-100 comment-container-heading"
                    :align="'center'"
                    :heading="'Comments'"
                    :description="''"
                    :headingSizeClass="'font-20 sm-font-16'"
                ></heading-block>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="'white'"
                    :imageUrl="$imagePath.cross"
                    :clickHandler="toggleComment"
                    width="15px"
                    height="15px"
                    class="ml-15px close-icon cursor-pointer"
                ></bev-image>
                <CommentList
                    :commentOnNumber="commentOnNumber"
                    :key="`commentList${newCommentAddedCounter}`"
                    v-if="commentIsOpen"
                />
                <CommentAction
                    :commentOnNumber="commentOnNumber"
                    v-if="commentIsOpen"
                />
            </div>
            <div class="comment-button">
                <bev-button
                    :themeType="'white'"
                    :clickHandler="toggleComment"
                    :buttonType="'button'"
                >
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="$colors.bevColors.baseDark"
                        :imageUrl="$imagePath.comment"
                        :imageAlt="'comment'"
                        width="25px"
                        height="25px"
                        class="cursor-pointer"
                    ></bev-image>
                </bev-button>
            </div>
        </div>
    </Teleport>
</template>

<script>
/**
 * Quote/Policy Comments Component
 */
import { mixin as clickaway } from "vue3-click-away";
import CommentList from "@/components/Views/Details/Comments/CommentList/Main.vue";
import CommentAction from "@/components/Views/Details/Comments/CommentAction.vue";
export default {
    data() {
        return {
            commentIsOpen: false,
        };
    },
    props: {
        commentOnNumber: String,
        newCommentAddedCounter: Number,
    },
    mixins: [clickaway],
    components: {
        CommentList,
        CommentAction,
    },
    methods: {
        toggleComment() {
            this.commentIsOpen = !this.commentIsOpen;
        },
        closeComment() {
            this.commentIsOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.quote-comment {
    z-index: 20;
    height: 100%;
    width: 100%;
    .comment-container {
        background: var(--baseShade1);
        box-shadow: 0 1px 16px 0 rgb(0 0 0 / 56%);
        position: absolute;
        width: 50%;
        right: -50%;
        bottom: 0;
        height: calc(100vh - 80px);
        z-index: 20;
        transition: 0.2s all ease-in-out;
        &.open {
            right: 0;
        }
        .comment-container-heading {
            padding: 20px;
            height: 70px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.13);
        }
        .close-icon {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
    .comment-button {
        bottom: 80px;
        right: 80px;
        position: absolute;
        z-index: 19;
        button {
            border-radius: 50%;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
