<template>
    <div class="bev-range-slider">
        <label class="font-16 sm-font-12">
            {{ label }}
        </label>
        <vue-slider
            :class="sliderClass"
            :height="'8px'"
            v-model="sliderV"
            :dotSize="12"
            tooltip="always"
            :order="true"
            :min="min"
            :max="max"
            :interval="interval"
            :dotStyle="sliderStyle"
            :processStyle="processStyle"
            :railStyle="bgStyle"
            :tooltipStyle="tooltipStyle"
            :enableCross="false"
            :minRange="minRange"
            @change="setValue"
            @dragEnd="dragEnd"
            :disabled="disabled"
        >
            <template v-slot:tooltip="{ value }">
                <div
                    class="vue-slider-dot-tooltip vue-slider-dot-tooltip-top vue-slider-dot-tooltip-show"
                >
                    <div
                        class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top"
                    >
                        <span class="vue-slider-dot-tooltip-text">{{
                            tooltipValueHandler(value)
                        }}</span>
                    </div>
                </div>
            </template>
        </vue-slider>
    </div>
</template>

<script>
/**
 * BEV Range Slider component
 * @param {null} modelValue value for the slider
 * @param {Number} min minimum value for the slider
 * @param {Number} max maximum value for the slider
 * @param {String} label label to be shown on the slider
 * @param {String} unit unit for the slider (ms, s, mm, kg etc)
 * @param {Function} tooltipValueHandler handler for the tooltip on the slider hover
 * @param {Number} minRange minRange for the vue-slider component
 * @param {String} sliderClass class for the slider component
 * @param {Number} interval interval for the slider component
 * @param model for two way binding data to slider
 **/

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
    data() {
        return {
            sliderV: null,
        };
    },
    computed: {
        sliderStyle() {
            return {
                backgroundColor: this.$colors.bevColors.activeColorV1,
                boxShadow: `0px 0px 0px 7px ${this.$colors.bevColorWithOpacity.activeColorV1(
                    0.34
                )}`,
            };
        },
        bgStyle() {
            return {
                backgroundColor: this.$colors.bevColors["baseDark"],
            };
        },
        processStyle() {
            return { backgroundColor: this.$colors.bevColors.activeColorV1 };
        },
        tooltipStyle() {
            return {
                backgroundColor: this.$colors.bevColors.activeColorV1,
                color: this.$colors.bevColors.baseLight,
                fontSize: "12px",
            };
        },
    },
    components: {
        VueSlider,
    },
    props: {
        modelValue: null,
        min: Number,
        max: Number,
        label: String,
        unit: String,
        tooltipValueHandler: Function,
        minRange: {
            type: Number,
            default: 1,
        },
        sliderClass: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        interval: {
            type: Number,
            default: 1,
        },
    },
    emits: ["update:modelValue", "dragEnd"],
    mounted() {
        this.sliderV = this.modelValue;
    },
    methods: {
        setValue(e) {
            this.$emit("update:modelValue", e);
        },
        dragEnd(e) {
            this.$emit("dragEnd", e);
        },
    },
    watch: {
        modelValue: function () {
            if (this.sliderV != this.modelValue) {
                this.sliderV = this.modelValue;
            }
        },
    },
};
</script>

<style scoped>
.vue-slider-dot-tooltip-inner {
    background-color: var(--activeColorV1);
    color: var(--baseLight);
    font-size: 12px;
}
.vue-slider-dot {
    cursor: pointer !important;
}
</style>
