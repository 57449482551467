<template>
    <div class="w-100 mt-30px px-4% cover-details-chart position-relative">
        <SliderBarGraph
            v-if="graphData.length > 0"
            :sliderPoint="trigger"
            :chartId="'weather_prediction'"
            :xAxesLabel="'Year'"
            :yAxesLabel="'Rainfall (mm)'"
            :gridColor="$colors.bevColors.baseShade2"
            :graphColor="'white'"
            :pointerColor="$colors.bevColors.activeColorV1"
            :pointerColorLight="$colors.bevColorWithOpacity.activeColorV1(0.34)"
            :barThickness="41"
            :graphData="[0, ...graphData, 0]"
            :barBgColor="$colors.bevColors.activeColorV2"
            :barLightBgColor="$colors.bevColorWithOpacity.activeColorV2(0.4)"
            :labels="[0, ...shortTermGraphlabels, 0]"
            :payoutData="[0, ...shortTermPayoutData, 0]"
        />
        <spinner
            v-if="graphLoading"
            :spinnerColor="$colors.bevColors.veryLightSecondary"
            :width="'30px'"
            :height="'30px'"
            class="center-positioned"
        ></spinner>
        <GraphMessage
            :retryData="getGraphData"
            :message="graphMessage"
            :messageType="graphError ? 'error' : 'empty'"
            v-if="!graphLoading && (graphEntryLength === 0 || graphError)"
        />
    </div>
</template>

<script>
/**
    Live Pricing Graph for Parametric Weather COver Details in Buy Policy Flow
    * @param {Array} graphData list of graphData
    * @param {String} trigger trigger for the live pricing graph to show sliderPoint
    * @param {Boolean} graphLoading check if graphData is loading
    * @param {Boolean} graphError check if graphData has error
    * @param {Number} graphEntryLength graph data length
    * @param {Array} shortTermGraphlabels list of graph labels
    * @param {Array} shortTermPayoutData list of payout data for the graph
    * @param {Array} getGraphData handler to getGraphData
**/
import GraphMessage from "@/components/Views/BuyPolicy/CoverDetails/GraphMessage";
import SliderBarGraph from "@/components/Common/Graph/SliderBarGraph";
export default {
    props: {
        graphData: Array,
        trigger: String,
        graphLoading: Boolean,
        graphError: Boolean,
        graphEntryLength: Number,
        shortTermGraphlabels: Array,
        shortTermPayoutData: Array,
        getGraphData: Function,
    },
    components: {
        GraphMessage,
        SliderBarGraph,
    },
    computed: {
        graphMessage() {
            if (!this.graphLoading) {
                if (this.graphError) {
                    return "Failed to fetch data";
                } else {
                    return "Please provide all details to calculate graph data";
                }
            } else {
                return "";
            }
        },
    },
};
</script>
