import { getDay, format, differenceInCalendarDays } from "date-fns";
import { mapState, mapGetters } from "vuex";
import isEmpty from "lodash.isempty";
import isObject from "lodash.isobject";
import date_time from "@/constant/dateTime";
import $ from "umbrellajs";
import GridService from "@/services/GridService";
export default {
    computed: {
        ...mapState("auth", ["user", "roles"]),
        ...mapGetters("auth", ["userAttributes"]),
        ...mapState(["subDomainDetail", "productSubDomainDetail"]),
        ...mapState("buyPolicy", [
            "productsJSON",
            "defaultCurrency",
            "selectedCurrency",
            "inValidatedData",
        ]),
        ...mapState("referenceData", ["productKeyMap", "currencyList"]),
    },
    methods: {
        displayErrorMessage(errorMessage) {
            this.$notify({
                group: "BEV",
                title: "Error",
                text: errorMessage,
                type: "error",
                duration: 10000,
            });
        },
        formatToUtcDate(date) {
            const value = new Date(date);
            const offset = value.getTimezoneOffset();
            const utcDate = value.getTime() - offset * 60 * 1000;
            return utcDate;
        },
        async validatePostalCode(country, city, postcode) {
            try {
                const res = await GridService.validatePostalCode(
                    country,
                    city,
                    postcode
                );
                return res.data.valid;
            } catch (error) {
                return false;
            }
        },
        checkPersonalDetails() {
            return Boolean(
                this.userAttributes.dob &&
                    this.userAttributes.mobileNumber &&
                    this.userAttributes.landlineNumber &&
                    this.userAttributes.addressLine1 &&
                    this.userAttributes.addressLine2 &&
                    this.userAttributes.country &&
                    this.userAttributes.city &&
                    this.userAttributes.zipCode
            );
        },
        copyToClipboard(textToCopy) {
            if (navigator.clipboard && window.isSecureContext) {
                return navigator.clipboard.writeText(textToCopy);
            }
        },
        productLabelHandler(key, value) {
            if (key in this.productKeyMap) {
                return (
                    this.productKeyMap[key][value] ||
                    (value === "label" ? key : "")
                );
            } else {
                return key;
            }
        },
        formatDateValue(date, formatType, formatStr) {
            if (date) {
                const weekDays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ];
                if (formatType === "day-time") {
                    return (
                        weekDays[getDay(date)] + "|" + format(date, "h:mm a")
                    );
                } else {
                    return format(new Date(date), formatStr);
                }
            }
            return "";
        },
        getFormattedDateFromMiliseconds(date, formatType, formatStr) {
            if (date) {
                return this.formatDateValue(date, formatType, formatStr);
            } else {
                return null;
            }
        },
        formatCoverDuration(startDate, endDate, startTime, endTime) {
            return (
                this.formatDateValue(startDate, "normal", "dd/MM/yyyy") +
                " " +
                this.getValueByKey(date_time.hourLabel, "startRange", startTime)
                    .label +
                " - " +
                this.formatDateValue(endDate, "normal", "dd/MM/yyyy") +
                " " +
                this.getValueByKey(date_time.hourLabel, "endRange", endTime)
                    .label
            );
        },
        toTitleCase(str) {
            return str
                ?.toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        },
        findInList(list, value, valueType, key = "") {
            return list.findIndex((item) => {
                if (valueType === "string") {
                    return item === value;
                } else {
                    return item[key] === value;
                }
            });
        },
        setChartProperties(
            chart,
            titleHandler,
            labelHandler,
            colors,
            haveFilter,
            filterHandler,
            titleFontSize
        ) {
            if (chart) {
                chart.options.plugins.tooltip = {
                    enabled: true,
                    displayColors: false,
                    ...(haveFilter && {
                        filter: function (tooltipItem) {
                            return filterHandler(tooltipItem);
                        },
                    }),
                    callbacks: {
                        title: (tooltipItem) => {
                            if (!tooltipItem || tooltipItem.length === 0) {
                                return false;
                            } else {
                                return titleHandler(
                                    tooltipItem[0]?.dataset?.data || [],
                                    tooltipItem
                                );
                            }
                        },
                        label: (tooltipItem, data) =>
                            labelHandler(tooltipItem, data),
                    },
                    backgroundColor: colors.bgColor,
                    position: "nearest",
                    borderColor: colors.borderColor,
                    borderWidth: 2,
                    titleFont: {
                        size: titleFontSize || 22,
                    },
                    titleColor: colors.borderColor,
                    bodyFont: {
                        size: 16,
                    },
                    bodyColor: colors.borderColor,
                    padding: {
                        x: 20,
                        y: 15,
                    },
                    cornerRadius: 8,
                    shadowOffsetX: 4,
                    shadowOffsetY: 4,
                    shadowBlur: 11,
                    caretSize: 0,
                    shadowColor: colors.shadowColor,
                };
                chart.update();
            }
        },
        findInString(str, value) {
            return str.includes(value);
        },
        isObjectEmpty(obj) {
            return isEmpty(obj);
        },
        isValObject(obj) {
            return isObject(obj);
        },
        removeCommaFromNumber(value) {
            return typeof value === "string"
                ? value.split(",").join("")
                : value;
        },
        appendToList(source, target, key, extraCondition) {
            if (Array.isArray(source)) {
                source.forEach((item) => {
                    let indexToFound = key
                        ? target.findIndex((i) => i[key] === item[key])
                        : target.findIndex((i) => i === item);
                    if (
                        indexToFound < 0 &&
                        (!extraCondition ||
                            (extraCondition && extraCondition(item)))
                    ) {
                        target.push(item);
                    }
                });
            } else {
                Object.keys(source).forEach((item) => {
                    let indexToFound = key
                        ? target.findIndex((i) => i[key] === item)
                        : target.findIndex((i) => i === item);
                    if (indexToFound < 0) {
                        target.push({
                            [key]: item,
                            highRisk: source[item],
                        });
                    }
                });
            }
        },
        getTotalCount(count, size) {
            return Math.ceil(count / size);
        },
        findCurrencyByCode(code) {
            return this.currencyList[code] || null;
        },
        currencyDropdownTextRenderer(currencyData) {
            return currencyData.value
                ? `${currencyData.value} - ${
                      currencyData?.metaData?.name || "----"
                  }`
                : "";
        },
        getCurrencyCode() {
            return this.selectedCurrency || this.defaultCurrency;
        },
        thousandMask(value, type) {
            let formattedValue =
                typeof value === "string" ? value.replaceAll(",", "") : value;
            const currencyRegex = /\B(?=(\d{3})+(?!\d))/g;
            if (!isNaN(Number(formattedValue))) {
                if (type === "currency") {
                    return this.addPrefix(
                        Number(formattedValue)
                            .toFixed(2)
                            .replace(currencyRegex, ","),
                        this.getCurrencyCode()
                    );
                } else {
                    return Number(formattedValue)
                        .toFixed(0)
                        .replace(currencyRegex, ",");
                }
            } else {
                return formattedValue;
            }
        },
        checkRole(roles, role) {
            return (
                roles.findIndex((r) => {
                    return r.name === role;
                }) > -1
            );
        },
        getValueByKey(list, key, value) {
            return list[list.findIndex((val) => val[key] === value)] || null;
        },
        splitCamelCase(word) {
            let str = word.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
            return str[0].toUpperCase() + str.substring(1);
        },
        splitUnderscoreToCaptialise(word) {
            return word.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
                return ($1 && " ") + $2.toUpperCase();
            });
        },
        convertToStartCase(str) {
            return this.splitUnderscoreToCaptialise(this.splitCamelCase(str));
        },
        addPrefix(string, prefix) {
            return prefix + " " + string;
        },
        calculateDays(startDate, endDate) {
            // inclusive of endDate so want (difference + 1)
            let daysLeft = differenceInCalendarDays(endDate, startDate) + 1;
            return daysLeft > 1 ? `(${daysLeft} days)` : `(${daysLeft} day)`;
        },
        getEditableAttributes(productKey) {
            // Handler to get editable keys of every questionBundle for given product
            // Find given product json from productsJSON
            let productJSON = this.productsJSON.find((product) => {
                return productKey === product.name;
            });
            let editableAttributes = [];
            // If productJson is found and has editableAttributes set and return those editableAttributes
            if (
                productJSON &&
                productJSON.editableAttributes &&
                productJSON.editableAttributes.length > 0
            ) {
                editableAttributes = productJSON.editableAttributes;
            }
            return editableAttributes;
        },
        setEditableAttributeDefault(attribute, productData) {
            // Handler to set default values for editableAttribute which needs to be calculated on UI side according to given method in productsJSON
            if (
                this.isValObject(attribute.defaultValue) &&
                attribute.defaultValue.fillMethod
            ) {
                switch (attribute.defaultValue.fillMethod) {
                    case "sameAsKey":
                        return productData[attribute.defaultValue.keyToMatch];
                    case "checkMinimum":
                        return Math.min(
                            Number(
                                productData[attribute.defaultValue.keyToCheck]
                            ),
                            attribute.defaultValue.staticValueToCheck
                        );
                    default:
                        return "";
                }
            } else {
                return attribute.defaultValue;
            }
        },
        setExtensionData(
            data,
            product,
            quickExtensions,
            quickExtensionsPremium,
            forDraft
        ) {
            if (quickExtensions[product]) {
                let extensionData = quickExtensions[product].map((ext) => {
                    let extData = {
                        ...quickExtensionsPremium[product][ext.key],
                    };
                    delete extData.oldData;
                    return {
                        ...ext,
                        premium:
                            forDraft && this.inValidatedData.length
                                ? null
                                : extData,
                    };
                });
                data[product] = {
                    ...data[product],
                    quickExtensions: extensionData,
                };
            }
        },
        formatProductDataForRequest(
            productDataMap,
            quickExtensions,
            quickExtensionsPremium,
            forDraft
        ) {
            let productsData = {};
            // Looping through productDataMap being created on CoverSummary page and stored in buy policy store
            Object.keys(productDataMap).forEach((product) => {
                // Check if current product is an addon of some other product
                if (productDataMap[product].addonFor) {
                    let addonFor = productDataMap[product].addonFor;
                    // Check if product for which current product product is an addon exist or not
                    if (
                        !productsData[addonFor] ||
                        isEmpty(productsData[addonFor])
                    ) {
                        // If product for which current product product is an addon does not exist set value of product to {}
                        productsData[addonFor] = {};
                    } else {
                        // If product for which current product product is an addon does exist then add current product as addon in parent product addons
                        productsData[addonFor].addOns = {
                            ...(productsData[addonFor].addOns &&
                                !isEmpty(productsData[addonFor].addOns) &&
                                productsData[addonFor].addOns),
                            [product]: {
                                ...productDataMap[product],
                                productTemplateId1:
                                    "2d751c0b-f254-49ab-89c7-1a3339245a4f",
                                productTemplateName1: "PolicyQuoteHTMLTemplate",
                            },
                        };
                        this.setExtensionData(
                            productsData[addonFor].addOns,
                            product,
                            quickExtensions,
                            quickExtensionsPremium,
                            forDraft
                        );
                        // Delete addonFor and completed keys from the product data as it was being added only for UI purpose
                        delete productsData[addonFor].addOns[product].addonFor;
                        delete productsData[addonFor].addOns[product].completed;
                    }
                } else {
                    // If current product is not an addon of some other product, then set value of the current product in prodcutsData
                    productsData[product] = {
                        ...productDataMap[product],
                        productTemplateId1:
                            "2d751c0b-f254-49ab-89c7-1a3339245a4f",
                        productTemplateName1: "PolicyQuoteHTMLTemplate",
                    };
                    this.setExtensionData(
                        productsData,
                        product,
                        quickExtensions,
                        quickExtensionsPremium,
                        forDraft
                    );
                    // Delete completed key from the product data as it was being added only for UI purpose
                    delete productsData[product].completed;
                }
                delete productsData[product].oldData;
            });
            return productsData;
        },
        getFormattedProductValue(
            product,
            productInfo,
            commonFields = {},
            parentProduct,
            ignorePremium,
            isReferred
        ) {
            let defaultFields = {
                ...(!ignorePremium && {
                    grossPremium: isReferred
                        ? this.thousandMask(
                              productInfo.recommendedPremiumValues.grossPremium,
                              "currency"
                          )
                        : this.thousandMask(
                              productInfo.grossPremium,
                              "currency"
                          ),
                }),
            };
            let eventDetails = {
                eventName: commonFields.eventName,
                eventType:
                    commonFields.eventType === "Other"
                        ? `${commonFields.eventType} - ${commonFields.customEventType}`
                        : commonFields.eventType,
                eventDates:
                    commonFields?.eventStartDate &&
                    commonFields?.eventEndDate &&
                    this.formatDateValue(
                        commonFields.eventStartDate,
                        "normal",
                        "d MMMM yyyy"
                    ) +
                        " - " +
                        this.formatDateValue(
                            commonFields.eventEndDate,
                            "normal",
                            "d MMMM yyyy"
                        ),
                eventSetting: commonFields.eventSetting,
            };
            switch (product) {
                case "event_cancellation":
                    return {
                        ...eventDetails,
                        eventHeldBeforeV2: this.toTitleCase(
                            productInfo.eventHeldBeforeV2
                        ),
                        insuredTotalExpensesV2:
                            this.thousandMask(
                                productInfo.insuredTotalExpensesV2,
                                "currency"
                            ) || "",
                        ...(productInfo.eventHeldBeforeV2 === "Yes"
                            ? {
                                  insuredTotalRevenueV2:
                                      productInfo.insuredTotalRevenueV2
                                          ? this.thousandMask(
                                                productInfo.insuredTotalRevenueV2,
                                                "currency"
                                            ) || ""
                                          : "----",
                              }
                            : {
                                  "insuredTotalRevenueV2:EvtNotHeldBefore":
                                      productInfo.insuredTotalRevenueV2
                                          ? this.thousandMask(
                                                productInfo.insuredTotalRevenueV2,
                                                "currency"
                                            ) || ""
                                          : "----",
                              }),
                        coverTypeV2: productInfo.coverTypeV2,
                        insureLimitV2:
                            this.thousandMask(
                                productInfo.insureLimitV2,
                                "currency"
                            ) || "",
                        ...(productInfo.eventHeldBeforeV2 === "Yes" && {
                            eventHeldBeforeTimesV2:
                                productInfo.eventHeldBeforeTimesV2 === 6
                                    ? "5+"
                                    : productInfo.eventHeldBeforeTimesV2,
                            lossCountAfterCovidV2:
                                productInfo.lossCountAfterCovidV2 === 6
                                    ? "5+"
                                    : productInfo.lossCountAfterCovidV2,
                        }),
                        ...(productInfo.affectedByWeatherV2 &&
                            productInfo.eventHeldBeforeV2 === "Yes" && {
                                affectedByWeatherV2:
                                    productInfo.affectedByWeatherV2,
                            }),
                        ...(eventDetails.eventSetting !== "Indoor" && {
                            proceedInSustainWindSpeedV2:
                                productInfo.proceedInSustainWindSpeedV2,
                        }),
                        ...defaultFields,
                    };
                case "event_liability":
                    return {
                        ...eventDetails,
                        noOfAttendees: productInfo.noOfAttendees,
                        limitOfIndemnity:
                            this.thousandMask(
                                productInfo.limitOfIndemnity,
                                "currency"
                            ) || "",
                        insureParticipants: this.toTitleCase(
                            productInfo.insureParticipants
                        ),
                        eventCarriedOutPrior: this.toTitleCase(
                            productInfo.eventCarriedOutPrior
                        ),
                        ...(productInfo.employersLiabilityEnabled && {
                            noOfEmployeesEL: productInfo.noOfEmployeesEL,
                            clericalEmployeeSplitEL:
                                productInfo.clericalEmployeeSplitEL,
                            manualLightEmployeeSplitEL:
                                productInfo.manualLightEmployeeSplitEL,
                            manualHeavyEmployeeSplitEL:
                                productInfo.manualHeavyEmployeeSplitEL,
                            manualHeavyEmployeeSplitDescriptionEL:
                                productInfo.manualHeavyEmployeeSplitDescriptionEL,
                            noHazardousActivityEL: this.toTitleCase(
                                productInfo.noHazardousActivityEL
                            ),
                            employeeUnderUKLawEL: this.toTitleCase(
                                productInfo.employeeUnderUKLawEL
                            ),
                            ...(productInfo.ernEL && {
                                ernEL: productInfo.ernEL,
                            }),
                        }),
                        publicLiability:
                            this.thousandMask(
                                productInfo.premiumBySubProduct?.publicLiability
                                    ?.grossPremium,
                                "currency"
                            ) || "",
                        ...(productInfo.employersLiabilityEnabled && {
                            employerLiability:
                                this.thousandMask(
                                    productInfo.premiumBySubProduct
                                        ?.employerLiability?.grossPremium,
                                    "currency"
                                ) || "",
                        }),
                        ...defaultFields,
                    };
                case "art_central_exhibitor":
                    return {
                        associatedCostOrExpenses:
                            this.thousandMask(
                                productInfo.associatedCostOrExpenses,
                                "currency"
                            ) || "",
                        ...defaultFields,
                    };
                case "money":
                    return {
                        valueOfMoney: this.thousandMask(
                            productInfo.valueOfMoney,
                            "currency"
                        ),
                        ...defaultFields,
                    };
                case "event_cancellation_terrorism":
                    return {
                        totalExpensesOrRevenue:
                            this.thousandMask(
                                parentProduct?.totalExpensesOrRevenue,
                                "currency"
                            ) || "",
                        ...defaultFields,
                    };
                default:
                    return null;
            }
        },
        toggleSidebar() {
            if ($(".navbar-toggle")) {
                $(".navbar-toggle").on("click", () => {
                    $(".navbar-toggle").toggleClass("open");
                    $(".navbar-toggle").toggleClass("collapsed");
                    $(".sidebar").toggleClass("active");
                });
            }
        },
        closeSidebar() {
            if ($(".sidebar").hasClass("active")) {
                $(".navbar-toggle").removeClass("open");
                $(".navbar-toggle").addClass("collapsed");
                $(".sidebar").removeClass("active");
            }
        },
        checkOptionValueForLivePricing(value, setDefaultTrue) {
            if (value === "Yes") {
                return true;
            } else if (value === "No") {
                return false;
            } else {
                return setDefaultTrue || false;
            }
        },
        webhookHandler(helperKey, topic, successHandler) {
            if (this[helperKey].socketClient !== null) {
                this[helperKey].socketClient.subscribe(topic, (data) => {
                    try {
                        successHandler(data);
                    } catch (err) {
                        return err;
                    }
                });
            }
            if (this[helperKey].socketTimeout !== null) {
                clearTimeout(this[helperKey].socketTimeout);
                this[helperKey].socketTimeout = null;
            }
        },
        checkNumberValueAndSet(val) {
            if (typeof val === "number") {
                return val;
            }
            return null;
        },
    },
};
