export default {
    paymentStatus: {
        successful: "SUCCESSFUL",
        pending: "PENDING",
        failed: "FAILED",
        invoiced: "INVOICED",
    },
    actions: {
        ADD_ENDORSEMENT: "ADD_ENDORSEMENT",
        QUOTE_REJECT: "REJECT",
        BIND: "BIND",
        UNDERWRITER_APPROVE: "UNDERWRITER_APPROVE",
        UNDERWRITER_DECLINE: "UNDERWRITER_DECLINE",
        CANCELLATION_APPROVED: "CANCELLATION_APPROVED",
        CANCELLATION_DECLINED: "CANCELLATION_DECLINED",
        SEND_DOCUMENT: "SEND_DOCUMENT",
        BROKER_APPROVE: "BROKER_APPROVE",
        BROKER_DECLINE: "BROKER_DECLINE",
        REFER_TO_UNDERWRITER: "REFER_TO_UNDERWRITER",
        CLONE: "CLONE",
        CANCEL_WITH_REASON: "CANCEL_WITH_REASON",
        CANCEL: "CANCEL",
        AMEND: "AMEND",
    },
    policyStatus: {
        GENERATED: {
            text: "Bound",
            value: "GENERATED",
            cardInfo: "",
            statusColor: "generatedStatus",
        },
        INPROGRESS: {
            text: "In Progress",
            value: "INPROGRESS",
            cardInfo: "Quote Generated & Application in Progress",
            statusColor: "inprogressStatus",
        },
        ONCOVER: {
            text: "On Cover",
            value: "ONCOVER",
            cardInfo: "Quote Approved & Policy On Cover",
            statusColor: "activeColorV1",
        },
        OFFCOVER: {
            text: "Off Cover",
            value: "OFFCOVER",
            cardInfo: "Policy Off Cover",
            statusColor: "grey",
        },
        PENDING: {
            text: "Pending",
            value: "PENDING",
            cardInfo: "Application is Pending",
            statusColor: "very-light-brown",
        },
        EXPIRED: {
            text: "Expired",
            value: "EXPIRED",
            cardInfo: "Policy is Expired",
            statusColor: "expiredStatus",
        },
        CANCELLED: {
            text: "Cancelled",
            value: "CANCELLED",
            cardInfo: "Policy is Cancelled",
            statusColor: "cancelledStatus",
        },
        CANCELLATION_REQUESTED: {
            text: "Cancellation Requested",
            value: "CANCELLATION_REQUESTED",
            cardInfo: "Policy cancellation is Requested",
            statusColor: "cancelledStatus",
        },
        CANCELLATION_APPROVED: {
            text: "Cancellation Approved",
            value: "CANCELLATION_APPROVED",
            cardInfo: "Policy cancellation is Approved",
            statusColor: "cancelledStatus",
        },
        CANCELLATION_DECLINED: {
            text: "Cancellation Declined",
            value: "CANCELLATION_DECLINED",
            cardInfo: "Policy cancellation is Declined",
            statusColor: "cancelledStatus",
        },
        UNDERWRITER_APPROVED: {
            value: "UNDERWRITER_APPROVED",
            text: "Underwriter Approved",
            statusColor: "underwriterApprovedStatus",
        },
        UNDERWRITER_DECLINED: {
            value: "UNDERWRITER_DECLINED",
            text: "Underwriter Declined",
            statusColor: "underwriterDeclinedStatus",
        },
        CLAIMED: {
            value: "CLAIMED",
            text: "Claimed",
            statusColor: "claimedStatus",
        },
        REFERRED: {
            value: "REFERRED",
            text: "Referred",
            statusColor: "referredStatus",
        },
        BROKER_REVIEW: {
            value: "BROKER_REVIEW",
            text: "In Broker Review",
            statusColor: "brokerReviewStatus",
        },
        BROKER_APPROVED: {
            value: "BROKER_APPROVED",
            text: "Broker Approved",
            statusColor: "brokerReviewApprovedStatus",
        },
        BROKER_DECLINED: {
            value: "BROKER_DECLINED",
            text: "Broker Declined",
            statusColor: "brokerReviewDeclinedStatus",
        },
    },
    payoutTypes: {
        binary: "Binary",
    },
    quoteStatus: {
        REJECTED: {
            value: "REJECTED",
            text: "Rejected",
            statusColor: "rejectedStatus",
        },
        INCOMPLETE_SUBMISSION: {
            value: "INCOMPLETE_SUBMISSION",
            text: "Incomplete",
            statusColor: "incompleteStatus",
        },
        APPROVED: {
            value: "APPROVED",
            text: "Bound",
            statusColor: "generatedStatus",
            isFinalState: true,
        },
        UNDERWRITER_APPROVED: {
            value: "UNDERWRITER_APPROVED",
            text: "Underwriter Approved",
            statusColor: "underwriterApprovedStatus",
        },
        UNDERWRITER_DECLINED: {
            value: "UNDERWRITER_DECLINED",
            text: "Underwriter Declined",
            statusColor: "underwriterDeclinedStatus",
        },
        GENERATED: {
            value: "GENERATED",
            text: "Generated",
            statusColor: "generatedStatus",
        },
        REFERRED: {
            value: "REFERRED",
            text: "Referred",
            statusColor: "referredStatus",
        },
        ARCHIVED: {
            value: "ARCHIVED",
            text: "Archived",
            statusColor: "expiredStatus",
            isFinalState: true,
        },
        CANCELLED: {
            text: "Cancelled",
            value: "CANCELLED",
            statusColor: "cancelledStatus",
            isFinalState: true,
        },
        EXPIRED: {
            text: "Expired",
            value: "EXPIRED",
            statusColor: "expiredStatus",
            isFinalState: true,
        },
    },
    quoteCreatedType: {
        User: "User",
        Broker: "Broker",
        Admin: "Admin",
    },
    productLabels: {
        parametric_weather: "Parametric Rain",
        liability_property: "Liability & Property",
        event_cancellation: "Event Cancellation",
        event_liability: "Event Liability",
        employers_liability: "Employers Liability",
        property_equipment: "Property / Equipment",
        money: "Money",
        art_central_exhibitor: "Art Central Hong Kong",
        event_cancellation_terrorism: "Event Cancellation Terrorism",
        civilCommotion: "Civil Commotion",
        computerSystemFailure: "Computer System Failure",
        terrorism: "Terrorism (Time and Distance)",
        terrorismFull: "Terrorism (Within Policy Period)",
        nationalMourning: "National Mourning",
        keySpeakerCover: "Key Person Cover",
    },
    quoteSubmissionMessage: {
        successful: `You have successfully applied for the cover with ${
            localStorage.getItem("appName") || "BirdsEyeView Technologies"
        }. You will receive the further updates on your cover through your Birds Eye View application.`,
        pending: `Your application with ${
            localStorage.getItem("appName") || "BirdsEyeView Technologies"
        } has been received. Premium payment has been failed due to technical issues. Please make the payment to complete the application`,
    },
    products: {
        parametric_weather: {
            image: "/img/icons/parametric.svg",
        },
        liability_property: {
            image: "/img/icons/liability_property.svg",
        },
        event_cancellation: {
            image: "/img/icons/event_cancellation.svg",
        },
        event_liability: {
            image: "/img/icons/liability_property.svg",
        },
    },
    perils: [
        {
            key: "rain",
            text: "Rain",
            image: "/img/icons/rainfall.svg",
            description:
                "Business interruption, cancellation, or loss of revenue due to rainfall",
        },
    ],
    insureType: [
        {
            key: "expenses",
            text: "100% of Expenses",
        },
        {
            key: "revenue",
            text: "100% of Revenue",
        },
    ],
    productDataBundle: {
        parametric_weather: [
            "SelectPeril",
            "SelectCoverDuration",
            "CoverDetails",
        ],
        event_cancellation: ["EventCancellationNew"],
        event_liability: ["EventLiability"],
        liability_property: ["EventDetails", "PublicLiability"],
        property_equipment: [
            "PropertyEquipmentConfirmation",
            "PropertyEquipment",
        ],
        employers_liability: ["EmployersLiability"],
        money: ["Money"],
        art_central_exhibitor: ["ArtCentralExhibitor", "RegisterUser"],
        event_cancellation_terrorism: ["EventCancellationTerrorism"],
    },
    isCommonBundle: ["EventDetails"],
    isCommonField: {
        eventName: "EventDetails",
        eventType: "EventDetails",
        eventStartDate: "EventDetails",
        eventEndDate: "EventDetails",
        eventSetting: "EventDetails",
        customEventType: "EventDetails",
    },
    nonEditable: {
        excess: {
            label: "Excess",
            unit: "£",
        },
        grossPremium: {
            label: "Gross Premium",
            unit: "£",
        },
        employerLiability: {
            label: "Employers Liability Premium",
            unit: "£",
        },
        publicLiability: {
            label: "Public Liability Premium",
            unit: "£",
        },
    },
    subProductPremiumField: {
        employerLiability: {
            label: "Employers Liability Premium",
            unit: "£",
            actualKey: "premiumBySubProduct.employerLiability.grossPremium",
        },
        publicLiability: {
            label: "Public Liability Premium",
            unit: "£",
            actualKey: "premiumBySubProduct.publicLiability.grossPremium",
        },
    },
    declarations: {
        liability_property: [
            {
                text: "that I/we have never been prosecuted under the Health and Safety at Work Act or other Statute or regulation",
                selected: false,
                id: 1,
            },
            {
                text: "That I/we have not been convicted of any criminal offence (other than minor driving offences not resulting in disqualification) in the last 5 years",
                selected: false,
                id: 2,
            },
            {
                text: "That I/we have not been declared bankrupt nor been involved in a company or business which has gone into liquidation, receivership or come to an arrangement with creditors in the last five years",
                selected: false,
                id: 3,
            },
            {
                text: "that I/we have not waived any legal rights of recovery against contractors and exhibitors?",
                selected: false,
                id: 4,
            },
            {
                text: "that I/we have checked contracts when booking venues to ensure we are not accepting responsibility for the negligence of the venue owners",
                selected: false,
                id: 5,
            },
            {
                text: "that I/we require all contractors, performers and exhibitors to provide evidence of insurance against third party liability risks before they are permitted on site",
                selected: false,
                id: 6,
            },
            {
                text: "that I/we require all exhibitors to provide evidence of insurance against third party risks before we permit them on site",
                selected: false,
                id: 7,
            },
        ],
        art_central_exhibitor: [
            {
                text: "that I/we have never been prosecuted under the Health and Safety at Work Act or other Statute or regulation",
                selected: false,
                id: 1,
            },
            {
                text: "That I/we have not been convicted of any criminal offence (other than minor driving offences not resulting in disqualification) in the last 5 years",
                selected: false,
                id: 2,
            },
            {
                text: "That I/we have not been declared bankrupt nor been involved in a company or business which has gone into liquidation, receivership or come to an arrangement with creditors in the last five years",
                selected: false,
                id: 3,
            },
            {
                text: "that I/we have not waived any legal rights of recovery against contractors and exhibitors?",
                selected: false,
                id: 4,
            },
            {
                text: "that I/we have checked contracts when booking venues to ensure we are not accepting responsibility for the negligence of the venue owners",
                selected: false,
                id: 5,
            },
            {
                text: "that I/we require all contractors, performers and exhibitors to provide evidence of insurance against third party liability risks before they are permitted on site",
                selected: false,
                id: 6,
            },
            {
                text: "that I/we require all exhibitors to provide evidence of insurance against third party risks before we permit them on site",
                selected: false,
                id: 7,
            },
        ],
        parametric_weather: [
            {
                text: "That I/we have a legal or equitable interest in the insured location during the insured period.",
                selected: false,
                id: 1,
            },
            {
                text: "That I/we understand that Aeris Weather Verification Report will be the ONLY weather data report relied upon in the settlement of a claim under this policy. <a href='https://www.birdseyeviewtechnologies.com/aerisweather' target='_blank'>Tell me more about Aeris Weather</a>",
                selected: false,
                id: 2,
            },
            {
                text: "That I/We understand this is an agreed value policy",
                selected: false,
                id: 3,
            },
            {
                text: "That the payout selected is approximately equal to the amount I/we stand to lose",
                selected: false,
                id: 4,
            },
            {
                text: "That this will be a one-off payment and I/we will not receive payments for subsequent weather events unless another BirdsEyeView parametric weather policy is purchased",
                selected: false,
                id: 5,
            },
        ],
        event_cancellation: [
            {
                text: "all contractual arrangements necessary for the event have either been made and confirmed in writing, or will be made and confirmed in writing in a prudent and timely manner prior to the event.",
                selected: false,
                id: 1,
            },
            {
                text: "all necessary licenses, visas, permits and authorisations have been obtained, or will be obtained in a prudent and timely manner prior to the event.",
                selected: false,
                id: 2,
            },
            {
                text: "there are no known existing circumstances which might give rise to a claim.",
                selected: false,
                id: 3,
            },
        ],
        common: [
            {
                text: "you have given a fair presentation of the risk to be insured by clearly disclosing all material facts and circumstances.",
                selected: false,
                id: 1,
            },
            {
                text: "you have taken care ensuring that all information provided is correct, accurate and complete.",
                selected: false,
                id: 2,
            },
            {
                text: "you understand that you must inform us as soon as possible about any changes to the information you have provided which happens before or during any period of insurance. Any such changes may result in revised terms and conditions, or changes to the premium. If you do not inform us about a change, it may affect any claim or could result in the policy being invalid.",
                selected: false,
                id: 3,
            },
        ],
    },
    termsConditionsExternalLink:
        "https://www.birdseyeviewtechnologies.com/terms-and-conditions",
};
