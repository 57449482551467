<template>
    <div>
        <p class="text-center text-white font-12 mb-20px">
            {{ formattedDate }}
        </p>
        <Comment
            v-for="(comment, i) in commentList"
            :key="i"
            :commentedBy="comment.commentedBy"
            :message="comment.commentContent"
            :commentTime="comment.creationTime"
        />
    </div>
</template>

<script>
/**
 * Single Day comment list to show comment for a single day
 * @param {String} date date for the comments
 * @param {Array} commentList list of comments for a day
 */
import { isToday } from "date-fns";
import Comment from "@/components/Views/Details/Comments/CommentList/SingleComment.vue";
export default {
    props: {
        date: String,
        commentList: Array,
    },
    components: {
        Comment,
    },
    computed: {
        formattedDate() {
            if (isToday(new Date(this.date))) {
                return "Today";
            } else {
                return this.formatDateValue(
                    new Date(this.date),
                    "date",
                    "dd MMMM yyyy"
                );
            }
        },
    },
};
</script>
