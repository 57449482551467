<template>
    <div
        class="sidebar bg-baseLight"
        :class="{ collapsed: sidebarCollapsed }"
        :style="sidebarBg"
    >
        <options class="sidebar-option justify-content-between">
            <bev-image
                :addBackground="true"
                :backgroundColor="'white'"
                :imageUrl="$imagePath.caretDown"
                :width="'14px'"
                :height="'8px'"
                class="cursor-pointer ml-10px"
                :imageAlt="'caret-down'"
                :clickHandler="closeSidebar"
            ></bev-image>
        </options>
        <sidebar-list></sidebar-list>
    </div>
</template>

<script>
/**
 * Sidebar Component
 **/
import SidebarList from "@/components/Layout/Sidebar/SidebarList";
import Options from "@/components/Layout/Navbar/Options";
import { mapState } from "vuex";
export default {
    components: {
        SidebarList,
        Options,
    },
    computed: {
        ...mapState(["sidebarCollapsed"]),
        sidebarBg() {
            return this.subDomainDetail.sidebarBgImage || {};
        },
    },
};
</script>
