<template>
    <div
        class="sidebar-list-item d-flex align-items-center text-decoration-none text-white cursor-pointer position-relative"
        :class="{ active: active }"
        @click="navigate"
        v-tooltip="{
            content: itemInfo.tooltipText || itemInfo.text,
            placement: 'right',
            disabled: !sidebarCollapsed,
        }"
    >
        <bev-image
            :addBackground="addItemBackground"
            :backgroundColor="'white'"
            :imageAlt="itemInfo.text"
            :imageUrl="itemInfo.iconUrl"
            :width="itemInfo.dimension.width"
            :height="itemInfo.dimension.height"
        ></bev-image>
        <p>{{ itemInfo.text }}</p>
    </div>
</template>

<script>
/**
    * Sidebar List Item Component
    * @param {Object<{
                    iconUrl: "",
                    text: "",
                    dimension: {
                        width: "20px",
                        height: "20px"
                    },
                }>} itemInfo for sidebar list item
    * @param {Boolean} active to check if sidebar item is active
    * @param {Function} navigate handler to navigate
**/
export default {
    props: {
        itemInfo: {
            type: Object,
            default: function () {
                return {
                    iconUrl: "",
                    text: "",
                    dimension: {
                        width: "20px",
                        height: "20px",
                    },
                };
            },
        },
        active: Boolean,
        navigate: {
            type: Function,
            default: function () {
                return () => {};
            },
        },
        sidebarCollapsed: Boolean,
        addItemBackground: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
