<template>
    <Teleport to="#appContent">
        <transition name="fade">
            <div class="popup-background" v-if="state"></div>
        </transition>
        <transition name="fade">
            <div
                class="modal-wrapper"
                :class="modalCustomClass"
                v-if="state"
                :style="{ width: width }"
            >
                <div @click="closeDialog" class="position-absolute close-img">
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="'white'"
                        :imageUrl="$imagePath.cross"
                        :imageAlt="'cross'"
                        width="15px"
                        height="15px"
                        class="cursor-pointer"
                    ></bev-image>
                </div>
                <h3 class="modal-title">{{ title }}</h3>
                <p class="modal-description">{{ description }}</p>
                <slot name="dialog-content"></slot>
                <actions
                    class="mt-20px"
                    v-if="actions.show"
                    :buttonLightProperties="actions.secondaryButton"
                    :buttonDarkProperties="actions.primaryButton"
                    :align="'center'"
                ></actions>
            </div>
        </transition>
    </Teleport>
</template>
<script>
/**
 * BEV Dialog component
 *
 * @param {String} title  title for dialog
 * @param {Boolean} state for modal state
 * @param {String} description for dialog description
 * @param {String} width of modal
 * @param {Function} closeDialog function to trigger close dialog
 * @param {Boolean} isConfirm checks if confirm dialog
 * @param {Object} actions a object to show action button as of now it supports only two
 */

export default {
    props: {
        title: String,
        state: Boolean,
        description: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "50%",
        },
        closeDialog: Function,
        isConfirm: {
            type: Boolean,
            default: false,
        },
        modalCustomClass: {
            type: String,
            default: "",
        },
        actions: {
            type: Object,
            default: function () {
                return {
                    show: false,
                    secondaryButton: {
                        text: "Back",
                        clickHandler: () => {},
                    },
                    primaryButton: {
                        text: "Next",
                        clickHandler: () => {},
                    },
                };
            },
        },
    },
};
</script>
