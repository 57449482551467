<template>
    <div
        class="pb-30px pt-80px sm-pt-30px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Exhibitor: Event Cancellation Insurance`"
            :description="'This insurance provides you with financial protection in the instance that the event you\'re attending is cancelled'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            :descriptionTooltipText="eventCancellationTooltipText"
        >
            <CoverSummaryButton
                :clickHandler="setArtCentralExhibitor.bind(this, false, false)"
            />
        </buy-policy-heading-block>
        <bev-form
            class="cancellation-form"
            :submitData="setArtCentralExhibitor.bind(this, true, false)"
            :showSingleButton="currentStepIndex === 0"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setArtCentralExhibitor.bind(this, false, true),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <div class="cancellation-form-content pt-20px mt-30px h-100">
                    <div class="row">
                        <div class="col-sm-12 mt-30px">
                            <bev-currency-input
                                :inputLabel="
                                    productLabelHandler(
                                        'associatedCostOrExpenses',
                                        'label'
                                    ) + '*'
                                "
                                :haveError="
                                    v$.associatedCostOrExpenses.$error &&
                                    v$.associatedCostOrExpenses.required
                                        .$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.fieldCannotEmptyError(
                                        'Field'
                                    )
                                "
                                :inputClass="'cost_expense'"
                                v-model="$v.associatedCostOrExpenses.$model"
                            ></bev-currency-input>
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center justify-content-between font-18 sm-font-14 fw-600 summary-amount text-activeColorV2 mt-30px"
                    >
                        <p>Premium</p>
                        <p>
                            <spinner
                                v-if="calculatingPremium"
                                :spinnerColor="
                                    $colors.bevColors.veryLightSecondary
                                "
                                :width="'15px'"
                                :height="'15px'"
                            ></spinner>
                            <span v-if="!calculatingPremium">
                                {{
                                    netPremium
                                        ? thousandMask(netPremium, "currency")
                                        : "---"
                                }}
                            </span>
                        </p>
                    </div>
                </div>
            </template>
        </bev-form>
    </div>
</template>

<script>
/**
 * QuestionBundle component for ArtCentralExhibitor
 */
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import LivePricing from "@/services/LivePricing";
import debounce from "lodash.debounce";
import customHtml from "@/constant/customHtml";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        CoverSummaryButton,
        BuyPolicyHeadingBlock,
    },
    validations() {
        return {
            // Validations for every form field
            associatedCostOrExpenses: {
                required,
            },
        };
    },
    created() {
        this.associatedCostOrExpenses =
            this.questionBundles["ArtCentralExhibitor"]
                ?.associatedCostOrExpenses || "";
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles", "currentStepIndex"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
    },
    data() {
        return {
            associatedCostOrExpenses: "",
            calculatingPremium: false,
            netPremium: 0,
            eventCancellationTooltipText:
                customHtml.eventCancellationTooltipText,
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setQuestionBundle", "setBuyPolicyStep"]),
        ...mapActions("buyPolicy", ["takeToNextStep", "takeToPreviousStep"]),
        setArtCentralExhibitor(forNext, forBack) {
            // Handler to take every filled data and set into ArtCentralExhibitor questionBundl
            this.setQuestionBundle({
                ...this.questionBundles,
                ArtCentralExhibitor: {
                    ...this.questionBundles["ArtCentralExhibitor"],
                    associatedCostOrExpenses: this.associatedCostOrExpenses,
                },
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else {
                if (this.checkFlowComplete && !forNext) {
                    this.setBuyPolicyStep({
                        step: "CoverSummary",
                        back: false,
                    });
                } else {
                    this.takeToNextStep();
                }
            }
        },
        triggerCalculatePremium: debounce(function () {
            if (this.associatedCostOrExpenses) {
                this.calculatingPremium = true;
                this.getPremium();
            } else {
                this.calculatingPremium = false;
                this.netPremium = 0;
            }
        }, 300),
        async getPremium() {
            try {
                const response = await LivePricing.livePricingCore({
                    product: "exhibitor",
                    actualProduct: "art_central_exhibitor",
                    eventName: "Art Central Hong Kong",
                    insuredLimit: this.associatedCostOrExpenses,
                });
                this.netPremium = response.data.body.netPremium;
                this.calculatingPremium = false;
            } catch (err) {
                this.netPremium = 0;
                this.calculatingPremium = false;
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        "Failed to calculate premium"
                );
            }
        },
    },
    watch: {
        associatedCostOrExpenses: function () {
            this.triggerCalculatePremium();
        },
    },
};
</script>

<style scoped>
.cancellation-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cancellation-form-content {
    width: 700px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
.summary-amount {
    padding: 15px 25px;
    border-top: 0.5px solid var(--activeColorV2);
    border-bottom: 0.5px solid var(--activeColorV2);
}
.pt-80px {
    padding-top: 80px;
}
</style>
