<template>
    <bev-dialog
        :title="''"
        :description="''"
        :closeDialog="toggleLoginDialog"
        :state="showLoginDialog"
        :width="'40%'"
        :actions="{
            show: false,
        }"
    >
        <template v-slot:dialog-content>
            <div class="text-center">
                <div
                    class="d-flex align-items-start justify-content-center mb-20px"
                >
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="'white'"
                        :imageAlt="'info'"
                        :imageUrl="$imagePath.info"
                        width="20px"
                        height="20px"
                        class="cursor-pointer mr-10px"
                    ></bev-image>
                    <p class="font-18 sm-font-14">
                        You need to login before moving forward with your
                        registered user
                    </p>
                </div>
                <bev-button
                    :themeType="'dark'"
                    :clickHandler="loginDialogHandler"
                    class="m-auto"
                >
                    Login
                </bev-button>
            </div>
        </template>
    </bev-dialog>
</template>

<script>
/**
    Make login popup for the Direct Buy flow
    * @param {Boolean} showLoginDialog state of the dialog
    * @param {Function} toggleLoginDialog toggle state of the dialog
    * @param {Function} loginDialogHandler handler for login button
**/
export default {
    props: {
        showLoginDialog: Boolean,
        toggleLoginDialog: Function,
        loginDialogHandler: Function,
    },
};
</script>
