<template>
    <div class="text-center">
        <ul class="endorsement-list mt-30px">
            <li
                v-for="(endorsement, index) in endorsements"
                :key="index"
                v-show="endorsements.length"
            >
                <p>{{ endorsement }}</p>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.danger"
                    :imageUrl="$imagePath.delete"
                    :imageAlt="'bin-icon'"
                    width="20px"
                    height="20px"
                    :tooltipText="'Delete Endorsement'"
                    :clickHandler="deleteEndorsement.bind(this, endorsement)"
                    v-if="isEditable"
                ></bev-image>
            </li>
            <li v-if="!endorsements.length" class="not-found">
                No endorsements found
            </li>
        </ul>
        <bev-button
            :themeType="'dark'"
            :clickHandler="updateQuote"
            :buttonType="'button'"
            :loading="updatingQuote"
            class="small mx-5px"
            v-if="isEditable"
            :disabled="saveEndorsementDisabled"
        >
            Save Endorsement
        </bev-button>
    </div>
</template>

<script>
export default {
    props: {
        endorsements: Array,
        details: Object,
        deleteEndorsement: Function,
        updateQuote: Function,
        updatingQuote: Boolean,
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        saveEndorsementDisabled() {
            if (!this.details.endorsements) {
                return true;
            }
            return (
                JSON.stringify(this.details.endorsements) ===
                JSON.stringify(this.endorsements)
            );
        },
    },
};
</script>

<style scoped>
ul {
    padding: 0;
}
li {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
li.not-found {
    opacity: 0.4;
    justify-content: center;
}
</style>
