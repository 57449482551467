<template>
    <div class="w-100 h-100">
        <form
            @submit.prevent
            class="d-flex flex-column justify-content-between h-100"
        >
            <div class="saved-cards d-flex align-items-center">
                <spinner
                    v-if="gettingSavedMethods"
                    :spinnerColor="$colors.bevColors.veryLightSecondary"
                    :width="'20px'"
                    :height="'20px'"
                    class="m-auto"
                ></spinner>
                <bev-selectable-tag
                    v-for="(method, index) in savedMethods"
                    :key="index"
                    :clickHandler="selectMethod.bind(this, method)"
                    :selected="
                        selectedMethod && selectedMethod.id === method.id
                    "
                    class="saved-card"
                    v-show="!gettingSavedMethods && !savedMethodError"
                >
                    <p class="card-type m-0">
                        {{ stringToStartCase(method.card.brand) }} **** ****
                        {{ method.card.last4 }}
                    </p>
                    <p class="card-detail">
                        {{ method.billing_details.name }} -
                        {{ method.card.exp_month }}/{{ method.card.exp_year }}
                    </p>
                </bev-selectable-tag>
                <div
                    class="saved-card-error"
                    v-if="savedMethodError && !gettingSavedMethods"
                >
                    <p class="flex-1 mt-10px font-18 text-danger">
                        Failed to get saved cards
                    </p>
                    <bev-button
                        :themeType="'light'"
                        :clickHandler="getSavedMethods"
                        class="small mt-10px flex-1"
                    >
                        Retry
                    </bev-button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 field w-100">
                    <fieldset
                        class="normal-input position-relative"
                        :class="{ error: cardNumberError }"
                    >
                        <legend>Card number*</legend>
                        <div
                            id="bev-stripe-card-number"
                            class="input empty pr-40px"
                        ></div>
                        <bev-image
                            :addBackground="
                                cardBrand === 'unknown' ? true : false
                            "
                            :backgroundColor="
                                cardBrand === 'unknown'
                                    ? cardNumberError
                                        ? $colors.bevColors.danger
                                        : 'white'
                                    : 'transparent'
                            "
                            :imageUrl="$imagePath.cards[cardBrand]"
                            width="30px"
                            height="30px"
                            :imageAlt="'card-icon'"
                            class="brand-icon"
                        ></bev-image>
                    </fieldset>
                </div>
                <div class="col-sm-12 field w-100 pt-20px">
                    <fieldset
                        class="normal-input"
                        :class="{ error: cardNameError }"
                    >
                        <legend>Card Name*</legend>
                        <input
                            type="text"
                            v-model="cardName"
                            class="input empty"
                        />
                    </fieldset>
                </div>
                <div class="col-sm-12 col-lg-8 field w-100 pt-20px">
                    <fieldset
                        class="normal-input"
                        :class="{ error: cardExpiryError }"
                    >
                        <legend>Expiry Date*</legend>
                        <div
                            id="bev-stripe-card-expiry"
                            class="input empty"
                        ></div>
                    </fieldset>
                </div>
                <div class="col-sm-12 col-lg-4 field w-100 pt-20px">
                    <fieldset
                        class="normal-input"
                        :class="{ error: cardCVCError }"
                    >
                        <legend>CVV*</legend>
                        <div id="bev-stripe-card-cvc" class="input empty"></div>
                    </fieldset>
                </div>
            </div>
            <div class="actions text-center mt-20px">
                <bev-checkbox
                    :inputLabel="'Save card for future purpose'"
                    @update:modelValue="updateSaveCard"
                    :modelValue="saveCard"
                    :centerAligned="true"
                    v-if="!selectedMethod"
                ></bev-checkbox>
                <bev-button
                    :themeType="'dark'"
                    :clickHandler="validateCardInfo"
                    :disabled="false"
                    :buttonType="'submit'"
                    :loading="cardLoading"
                    class="mt-20px w-100"
                >
                    Make Payment
                </bev-button>
                <p
                    class="text-danger font-12px text-center mt-10px"
                    v-if="submitError"
                >
                    {{ submitErrorMessage }}
                </p>
            </div>
        </form>
    </div>
</template>

<script>
/**
 * BEV Stripe form component
 * @param {Function} submitHandler handler to be used on stripe form submit
 * @param {Number} amount payment amount for stripe payment
 * @param {String} paymentMode mode of payment for stripe payment
 **/

import { mapGetters, mapState } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import PaymentService from "@/services/PaymentService";
import startcase from "lodash.startcase";
export default {
    props: {
        submitHandler: Function,
        amount: Number,
        paymentMode: String,
    },
    data() {
        return {
            stripe: null,
            publicKey: process.env.VUE_APP_STRIPE_PKEY,
            cardName: "",
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            cardNumberValue: "",
            cardExpiryValue: "",
            cardCvcValue: "",
            cardLoading: false,
            cardNumberError: false,
            cardNameError: false,
            cardExpiryError: false,
            cardCVCError: false,
            showBrand: false,
            submitError: false,
            submitErrorMessage: false,
            saveCard: false,
            cardBrand: "unknown",
            savedMethods: [],
            selectedMethod: null,
            gettingSavedMethods: false,
            savedMethodError: false,
        };
    },
    computed: {
        ...mapState("buyPolicy", ["selectedCurrency"]),
        ...mapGetters("auth", ["userAttributes"]),
    },
    mounted() {
        this.createStripeForm();
        if (this.userAttributes.customerId) {
            this.getSavedMethods();
        }
    },
    methods: {
        stringToStartCase(str) {
            return startcase(str);
        },
        async getSavedMethods() {
            this.gettingSavedMethods = true;
            try {
                let res = await PaymentService.getPaymentCards({
                    customer: this.userAttributes.customerId,
                    type: "CARD",
                    limit: 10,
                });
                this.savedMethods = res.data.data;
                this.gettingSavedMethods = false;
            } catch (err) {
                this.gettingSavedMethods = false;
                this.savedMethodError = true;
            }
        },
        async createStripeForm() {
            this.stripe = await loadStripe(this.publicKey);
            let elements = this.stripe.elements();

            let inputs = document.querySelectorAll(
                ".cell.bev-stripe-card.bev-stripe-card2 .input"
            );
            Array.prototype.forEach.call(inputs, function (input) {
                input.addEventListener("focus", function () {
                    input.classList.add("focused");
                });
                input.addEventListener("blur", function () {
                    input.classList.remove("focused");
                });
                input.addEventListener("keyup", function () {
                    if (input.value.length === 0) {
                        input.classList.add("empty");
                    } else {
                        input.classList.remove("empty");
                    }
                });
            });

            let elementStyles = {
                base: {
                    color: "white",
                    fontWeight: 300,
                    fontSize: "14px",
                    "::placeholder": {
                        color: "rgba(255, 255, 255, 0.6)",
                    },
                    ":-webkit-autofill": {
                        color: "rgba(255, 255, 255, 0.6)",
                    },
                    padding: "5px 10px",
                    lineHeight: "50px",
                },
                invalid: {
                    color: this.$colors.bevColors.danger,
                    "::placeholder": {
                        color: "rgba(255, 255, 255, 0.6)",
                    },
                },
            };

            const elementClasses = {
                focus: "focused",
                empty: "empty",
                invalid: "invalid",
            };

            this.cardNumber = elements.create("cardNumber", {
                iconStyle: "solid",
                style: elementStyles,
                classes: elementClasses,
            });

            this.cardNumber.mount("#bev-stripe-card-number");

            this.cardExpiry = elements.create("cardExpiry", {
                style: elementStyles,
                classes: elementClasses,
                placeholder: "",
            });
            this.cardExpiry.mount("#bev-stripe-card-expiry");

            this.cardCvc = elements.create("cardCvc", {
                style: elementStyles,
                classes: elementClasses,
            });
            this.cardCvc.mount("#bev-stripe-card-cvc");

            this.cardNumber.on("change", (event) => {
                if (event.error) {
                    this.cardNumberError = true;
                } else {
                    if (event.brand) {
                        this.setBrandIcon(event.brand);
                    }
                    this.cardNumberError = false;
                }
            });

            this.cardExpiry.on("change", (event) => {
                if (event.error) {
                    this.cardExpiryError = true;
                } else {
                    this.cardExpiryError = false;
                }
            });

            this.cardCvc.on("change", (event) => {
                if (event.error) {
                    this.cardCVCError = true;
                } else {
                    this.cardCVCError = false;
                }
            });
        },
        validateCardInfo() {
            this.submitError = false;
            this.submitErrorMessage = "";
            if (!this.selectedMethod) {
                if (this.cardName === "") {
                    this.cardNameError = true;
                    this.submitError = true;
                    this.submitErrorMessage = "Your card name is incomplete";
                    return;
                }
                this.cardLoading = true;
                let data = this.cardNumber;
                this.stripe.createToken(data).then((res) => {
                    if (!res.error) {
                        this.createPayment();
                    } else {
                        if (this.cardName === "") {
                            this.cardNameError = true;
                        }
                        this.submitError = true;
                        this.submitErrorMessage = res.error.message;
                        this.cardLoading = false;
                    }
                });
            } else {
                this.cardLoading = true;
                this.createPayment();
            }
        },
        async createPayment() {
            try {
                let response = await PaymentService.addPaymentIntent({
                    amount: Math.round(Number(this.amount) * 100),
                    currency: this.getCurrencyCode(),
                    paymentMethodTypes: "card",
                    description: `Paying for ${this.subDomainDetail.name} Policy`,
                    offsession: true,
                    customer: this.userAttributes.customerId,
                    ...(this.saveCard && { setupFutureUsage: "OFF_SESSION" }),
                });
                this.makePayment(response.data.client_secret);
            } catch (err) {
                this.cardLoading = false;
                this.submitError = true;
                this.submitErrorMessage =
                    err.response.data.message ||
                    this.$messages.errorMessages.paymentIntentCreationError;
            }
        },
        makePayment(secret) {
            this.stripe
                .confirmCardPayment(secret, {
                    payment_method: this.selectedMethod
                        ? this.selectedMethod.id
                        : {
                              card: this.cardNumber,
                              billing_details: {
                                  name: this.cardName,
                              },
                          },
                })
                .then((res) => {
                    if (res.error) {
                        if (!this.selectedMethod && this.cardName !== "") {
                            this.submitError = true;
                            this.submitErrorMessage = res.error.message;
                        }
                        this.cardLoading = false;
                        this.deletePaymentIntent(
                            res.error.payment_intent.id
                        ).then(() => {
                            this.submitHandler(
                                this.$policyValues.paymentStatus.pending,
                                "",
                                "",
                                () => {
                                    this.displayErrorMessage(
                                        "Failed to make Payment"
                                    );
                                    this.cardLoading = false;
                                }
                            );
                        });
                    } else {
                        this.submitHandler(
                            this.$policyValues.paymentStatus.successful,
                            "FULL",
                            this.paymentMode,
                            () => {
                                this.cardLoading = false;
                            }
                        );
                    }
                });
        },
        async deletePaymentIntent(id) {
            try {
                await PaymentService.removePaymentIntent(id, {
                    cancellationReason: "REQUESTED_BY_CUSTOMER",
                });
            } catch (err) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.paymentIntentDeleteError
                );
            }
        },
        setBrandIcon(brand) {
            this.cardBrand = brand || "";
        },
        updateSaveCard(value) {
            this.saveCard = value;
        },
        selectMethod(method) {
            if (this.selectedMethod === method) {
                this.selectedMethod = null;
            } else {
                this.selectedMethod = method;
            }
        },
    },
    watch: {
        cardName: function (val) {
            if (val.length > 0) {
                this.cardNameError = false;
            }
        },
    },
};
</script>

<style scoped>
/* .bev-stripe-card.bev-stripe-card2 {
  background-color: #fff;
} */

.input {
    height: 54px;
    padding: 0 18px;
    transition: inherit;
    border-radius: 6px;
    border: solid 0.5px white;
    background: none;
    width: 100%;
    font-size: 14px;
    color: white;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    box-sizing: border-box;
}

.brand-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}
.saved-cards {
    margin-bottom: 30px;
    margin-left: -5px;
    margin-right: -5px;
}
.saved-card {
    height: auto !important;
    line-height: normal !important;
    padding: 15px 20px;
    text-align: left;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin: 5px;
}
.saved-card .card-type {
    font-weight: bold;
}
.saved-card .card-detail {
    font-weight: normal;
    margin-top: 5px;
}
</style>
