<template>
    <div class="position-relative">
        <BevTable
            :tableHeight="'100vh - 400px'"
            :headers="referralHeaders"
            :data="formatReferralTableData"
            :tableLoading="referralLoading"
            :tableError="referralError"
            :retryHandler="getReferrals.bind(this, pageNumber, false)"
            :allowPagination="true"
            :totalData="totalElements"
            :paginationSize="size"
            :pageNumber="pageNumber"
            :totalPages="totalPages"
            :pageChangeHandler="pageChangeHandler"
            :tableItemClickHandler="tableItemClickHandler"
            :errorMessage="referralListErrorMessage"
            :appliedSortColumn="sortColumn"
            @sortingChanged="sortingChanged"
        />
    </div>
</template>
<script>
import PolicyService from "@/services/PolicyService";
import QuoteService from "@/services/QuoteService";
import { mapState } from "vuex";
import debounce from "lodash.debounce";
import BevTable from "@/components/Common/Table/Main.vue";
export default {
    components: {
        BevTable,
    },
    props: {
        referralType: String,
        referralSearch: String,
    },
    mounted() {
        this.getReferrals(0, true);
    },
    data() {
        return {
            referrals: [],
            pageNumber: 0,
            size: 20,
            totalElements: 0,
            totalPages: 0,
            sortColumn: "creationTime",
            sortOrder: "DESC",
            referralLoading: false,
            referralError: false,
            referralErrorMessage: "",
            routeChanged: false,
            referralHeaders: [
                {
                    key: "referralNumber",
                    label: "Referral Number",
                    sorting: "",
                },
                {
                    key: "customerName",
                    label: "Insured Name",
                    sorting: "",
                },
                {
                    key: "referralName",
                    label: "Event Name",
                    sorting: "",
                },
                {
                    key: "referralStatus",
                    label: "Referral Status",
                    sorting: "",
                },
                {
                    key: "products",
                    label: "Products",
                    sorting: "",
                },
                {
                    key: "creationTime",
                    label: "Applied On",
                    sorting: "DESC",
                },
            ],
        };
    },
    computed: {
        ...mapState("auth", ["userUnderwriterId", "userUnderwriterId"]),
        formatReferralTableData() {
            if (this.referrals.length > 0) {
                return this.referrals.map((referral) => {
                    let referralStatus =
                        this.referralType === "quotes"
                            ? referral.quoteStatus
                            : referral.policyStatus;
                    let referralStatusData =
                        this.referralType === "quotes"
                            ? this.$policyValues.quoteStatus
                            : this.$policyValues.policyStatus;
                    return {
                        id: referral.id,
                        referralNumber:
                            this.referralType === "quotes"
                                ? referral.quoteNumber
                                : referral.policyNumber,
                        customerName: referral.customerName || "----",
                        referralName: referral.policyName || "----",
                        referralStatus: {
                            type: "badge",
                            class: referralStatusData[referralStatus]
                                ?.statusColor,
                            data: referralStatus
                                ? referralStatusData[referralStatus]?.text
                                : "Referred",
                        },
                        products: referral.products
                            ? this.formatProductsString(referral.products)
                            : "----",
                        creationTime: this.formatDateValue(
                            referral.creationTime,
                            "normal",
                            "dd MMMM yyyy"
                        ),
                    };
                });
            } else {
                return [];
            }
        },
        referralListErrorMessage() {
            if (this.referralError) {
                return this.referralErrorMessage;
            } else {
                if (this.referralSearch) {
                    return this.$messages.errorMessages.notFoundWithFiltersError(
                        "policy"
                    );
                } else {
                    return this.$messages.errorMessages.notFoundError(
                        "policies"
                    );
                }
            }
        },
    },
    methods: {
        sortingChanged(data) {
            let headerItemIndex = this.referralHeaders.findIndex(
                (header) => header.key === data.column
            );
            if (headerItemIndex > -1) {
                this.referralHeaders[headerItemIndex].sorting = data.order;
            }
            this.sortColumn = data.appliedColumn;
            this.sortOrder = data.order;
            this.getReferrals(0, true);
        },
        searchFilterHandler: debounce(function () {
            this.getReferrals(0, true);
        }, 200),
        toggleDialog(props) {
            this.dialogProps = props;
        },
        async setGetReferralUrl(page) {
            let response = null;
            let sorting = "";
            if (this.sortColumn) {
                sorting = `${this.sortColumn},${this.sortOrder}`;
            }
            let requestBody = {
                ...(this.referralSearch && {
                    ...(this.referralType === "quotes"
                        ? {
                              quoteNumber: this.referralSearch.trim(),
                          }
                        : {
                              policyNumber: this.referralSearch.trim(),
                          }),
                    customerName: this.referralSearch.trim(),
                }),
                ...(this.referralType === "quotes"
                    ? {
                          quoteStatus: [
                              this.$policyValues.quoteStatus.REFERRED.value,
                          ],
                      }
                    : {
                          policyStatus: [
                              this.$policyValues.policyStatus.REFERRED.value,
                          ],
                      }),
                underwriterId:
                    this.userUnderwriterId ||
                    this.subDomainDetail.underwriterId,
            };
            if (this.referralType === "quotes") {
                response = await QuoteService.searchQuote(
                    page,
                    this.size,
                    requestBody,
                    sorting
                );
            } else {
                response = await PolicyService.getPolicies(
                    page,
                    this.size,
                    requestBody,
                    sorting
                );
            }
            return response;
        },
        async getReferrals(page, initial) {
            if (initial) {
                this.totalElements = 0;
                this.totalPages = 0;
                this.dialogProps = {};
                this.referrals = [];
            }
            this.referralLoading = true;
            this.referralError = false;
            this.referralErrorMessage = "";
            this.pageNumber = page;
            try {
                let res = await this.setGetReferralUrl(page);
                this.referrals = res.data.content;
                this.totalElements = res.data.totalElements;
                this.totalPages = res.data.totalPages;
                this.referralLoading = false;
                this.routeChanged = false;
            } catch (err) {
                this.referralError = true;
                this.referralErrorMessage =
                    err?.response?.data?.message ||
                    this.$messages.errorMessages.loadFailError("policies");
                this.referralLoading = false;
                this.routeChanged = false;
            }
        },
        pageChangeHandler(number) {
            this.getReferrals(number, false);
        },
        tableItemClickHandler(id) {
            if (this.referralType === "quotes") {
                this.$router.push({ path: `/quotes/${id}` });
            } else {
                this.$router.push({ path: `/policies/${id}` });
            }
        },
        formatProductsString(products) {
            return Object.keys(JSON.parse(products))
                .map((p) => {
                    return this.$policyValues.productLabels[p];
                })
                .join(", ");
        },
    },
    watch: {
        $route: function () {
            this.routeChanged = true;
            this.getReferrals(0, true);
        },
        referralSearch: function () {
            if (!this.routeChanged) {
                this.referralLoading = true;
                this.searchFilterHandler();
            }
        },
    },
};
</script>

<style scoped></style>
