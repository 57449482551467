<template>
    <div class="aggregate-limit-metrics">
        <div
            class="d-flex align-items-center justify-content-between flex-wrap mt-20px"
        >
            <div class="mr-5px">
                <p class="font-16 mb-10px">Aggregate Limit</p>
                <p class="fw-bold font-20 mb-10px text-activeColorV1">
                    {{ thousandMask(totalInsuredAmount, "currency") }}
                </p>
            </div>
            <div class="mr-5px">
                <p class="font-16 mb-10px">TIV</p>
                <p class="fw-bold font-20 mb-10px text-activeColorV1">
                    {{ thousandMask(sumOfInsuredAmount, "currency") }}
                </p>
            </div>
            <div>
                <p class="font-16 mb-10px">Aggregate Limit Remaining</p>
                <p class="fw-bold font-20 mb-10px text-activeColorV1">
                    {{ thousandMask(limitRemaining, "currency") }}
                </p>
            </div>
        </div>
        <div class="row mt-20px px-20px justify-content-center">
            <!-- Tiv (%) chart hide temporarily -->
            <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="pie-chart-container">
                    <PieChart
                        v-if="metricData"
                        chartId="aggregate_percentage_used"
                        graphColor="white"
                        :labels="chartLabels"
                        :bgColors="chartBgColors"
                        chartLabel="Aggregate Limit Used (%)"
                        :graphData="usedLimitChartData"
                        showPerentage
                        class="m-auto position-relative z-index-1"
                    />
                </div>
                <p class="mt-15px fw-600 text-center">TIV (%)</p>
            </div> -->
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="pie-chart-container">
                    <PieChart
                        chartId="aggregate_percentage_remaining"
                        graphColor="white"
                        :labels="chartLabels"
                        :bgColors="chartBgColors"
                        chartLabel="Aggregate Limit Used (%)"
                        :graphData="remainingLimitChartData"
                        showPerentage
                        class="m-auto position-relative z-index-1"
                    />
                </div>
                <p class="mt-15px fw-600 text-center">
                    Aggregate Limit Remaining (%)
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from "@/components/Common/Graph/PieChart.vue";
export default {
    props: {
        metricData: Object,
    },
    computed: {
        totalInsuredAmount() {
            return Number(
                this.metricData?.[`Aggregate Limit (${this.getCurrencyCode()})`]
                    ?.value || "0"
            );
        },
        sumOfInsuredAmount() {
            return Number(
                this.metricData?.["Aggregate Limit Used"]?.value || "0"
            );
        },
        limitRemaining() {
            return Math.max(
                0,
                this.totalInsuredAmount - this.sumOfInsuredAmount
            );
        },
        usedLimitChartData() {
            if (this.sumOfInsuredAmount >= this.totalInsuredAmount) {
                return [100, 0];
            } else {
                let limitUsed = (
                    (this.sumOfInsuredAmount / this.totalInsuredAmount) *
                    100
                ).toFixed(1);
                return [limitUsed, 100 - limitUsed];
            }
        },
        remainingLimitChartData() {
            if (this.limitRemaining >= this.totalInsuredAmount) {
                if (this.totalInsuredAmount === 0) {
                    return [0, 0];
                }
                return [100, 0];
            } else {
                let limitRemain = (
                    (this.limitRemaining / this.totalInsuredAmount) *
                    100
                ).toFixed(1);
                return [limitRemain, 100 - limitRemain];
            }
        },
        chartBgColors() {
            return [
                this.$colors.bevColors.activeColorV3,
                this.$colors.bevColors.baseShade2,
            ];
        },
    },
    components: {
        PieChart,
    },
    data() {
        return {
            chartLabels: ["Limit Used", ""],
        };
    },
};
</script>

<style scoped>
.pie-chart-container {
    position: relative;
}
</style>
