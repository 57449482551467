export default {
    baseBg: "#021b33",
    baseDark: "#021b33",
    white: "#ffffff",
    baseLight: "#112943",
    "pale-salmon": "#fbb39e",
    activeColorV2: "#67e8ff",
    "sea-green": "#4dfa91",
    "dodger-blue": "#3ebcff",
    activeColorV3: "#3ebcff",
    "very-light-brown": "#e2b47f",
    "input-info-message": "#e2b47f",
    buttonPrimary: "#2e4864",
    buttonPrimaryHover: "#1a324d",
    buttonPrimaryColor: "#ffff",
    buttonSecondary: "#e1ebf7",
    buttonSecondaryHover: "#ffff",
    buttonSecondaryColor: "#112943",
    "modal-bg": "#1e3855",
    danger: "#ff7777",
    veryLightSecondary: "#e1ebf7",
    activeColorV1: "#3cd1ba",
    baseShade1: "#193553",
    "black-pearl": "#011a32",
    baseShade2: "#2d4b6c",
    "light-orange": "#ffb6a0",
    yellow: "#ffcc00",
    datePickerBorder: "#ffffff",
    datePickerDefaultColor: "#ffffff",
    datePickerDisabledColor: "gray",
    datePickerButtonColor: "#ffffff",
    datePickerCellActiveColor: "#ffffff",
    datePickerCellHover: "#ffffff",
    datePickerCellInRange: "rgba(60, 209, 186, 0.51)",
    tableHeaderBg: "#ffffff",
    tableHeaderColor: "#021b33",
    tableBorder: "#e1ebf7",
    tableDataColor: "#ffffff",
    tableDataBorder: "rgba(255, 255, 255, 0.2)",
    inputInfoIcon: "#4dfa91",
    cardError: "#ffffff",
    generatedStatus: "#3ebcff",
    inprogressStatus: "#e1ebf7",
    incompleteStatus: "#ffb6a0",
    referredStatus: "#ffcc00",
    underwriterApprovedStatus: "#4dfa91",
    underwriterDeclinedStatus: "#ff7777",
    brokerReviewStatus: "#ffcc00",
    brokerReviewApprovedStatus: "#4dfa91",
    brokerReviewDeclinedStatus: "#ff7777",
    rejectedStatus: "#ff7777",
    expiredStatus: "#ff7777",
    boundStatus: "#5bc2e77",
    cancelledStatus: "#ff7777",
    claimedStatus: "#4dfa91",
    generatedStatusText: "#000000",
    incompleteStatusText: "#000000",
    referredStatusText: "#000000",
    underwriterApprovedStatusText: "#000000",
    underwriterDeclinedStatusText: "#000000",
    brokerReviewStatusText: "#000000",
    brokerReviewApprovedStatusText: "#000000",
    brokerReviewDeclinedStatusText: "#000000",
    rejectedStatusText: "#000000",
    expiredStatusText: "#000000",
    boundStatusText: "#000000",
    cancelledStatusText: "#000000",
    claimedStatusText: "#000000",
    inprogressStatusText: "#000000",
    recommendedValue: "#ffb6a0",
    footerColor: "#ffffff",
    linkColor: "#67e8ff",
};
