<template>
    <div
        class="quick-extensions"
        :style="{
            backgroundColor: $colors.bevColorWithOpacity.baseShade1(0.7),
        }"
    >
        <h4>Quote Extensions</h4>
        <Extension
            v-for="(extension, index) in extensions"
            :key="index"
            :extensionIndex="index"
            :extension="extension"
            :productHeading="productHeading"
            :recalculatingPremiumLoading="recalculatingPremiumLoading"
            @setExtensionSelected="setExtensionSelected"
            @handleFieldInputFunction="handleFieldInputFunction"
            @parentHandleAddFunction="parentHandleAddFunction"
            @parentHandleDeleteFunction="parentHandleDeleteFunction"
            :isReferred="isReferred"
            :checkIfExistInReferred="checkIfExistInReferred"
        />
    </div>
</template>

<script>
import LivePricingService from "@/services/LivePricing";
import Extension from "./Extension.vue";
import { mapMutations, mapState } from "vuex";
export default {
    name: "QuickExtension",
    components: {
        Extension,
    },
    props: {
        productHeading: String,
        productExtensions: {
            type: Array,
            default: () => [],
        },
        recalculatingPremiumLoading: Boolean,
        isReferred: {
            type: Boolean,
            default: false,
        },
        checkIfExistInReferred: Function,
    },
    created() {
        this.extensions = JSON.parse(JSON.stringify(this.productExtensions));
    },
    mounted() {
        if (this.productHeading === "event_cancellation") {
            this.getEventCancellationV2Extension();
        }
    },
    data() {
        return {
            extensions: [],
            isLoading: false,
        };
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles", "quickExtensions"]),
    },
    methods: {
        ...mapMutations("buyPolicy", ["setQuickExtensions"]),
        async getEventCancellationV2Extension() {
            this.isLoading = true;
            try {
                const response = await LivePricingService.livePricingCore({
                    product: "arch_contingency",
                    query: "add_ons",
                    productConfig: {
                        key1: this.questionBundles["EventDetails"]?.eventType,
                        limitType:
                            this.questionBundles["EventCancellationNew"]
                                ?.coverTypeV2,
                        country:
                            this.questionBundles["SelectLocation"]?.country,
                        city: this.questionBundles["SelectLocation"]?.city,
                        region: this.questionBundles["SelectLocation"]?.region,
                        productName: "event_cancellation",
                    },
                });
                if (response.data.body) {
                    this.extensions = this.extensions.filter((ext) => {
                        return response.data.body.indexOf(ext.key) > -1;
                    });
                    this.extensions.forEach((ext) => {
                        ext.additionalFields = ext.additionalFields.map(
                            (field) => {
                                if (field.mapWithQuoteData) {
                                    field.value =
                                        this.questionBundles[
                                            field.mapWithQuoteData.questionBundle
                                        ]?.[field.mapWithQuoteData.key];
                                }
                                return field;
                            }
                        );
                    });
                    let currentQuickExtensions = [
                        ...this.quickExtensions[this.productHeading].filter(
                            (ext) => {
                                return response.data.body.indexOf(ext.key) > -1;
                            }
                        ),
                    ];
                    this.setQuickExtensions({
                        ...this.quickExtensions,
                        [this.productHeading]: currentQuickExtensions,
                    });
                }
            } catch (err) {
                return err;
            } finally {
                this.isLoading = false;
            }
        },
        setExtensionSelected(index, value) {
            this.extensions[index] = {
                ...this.extensions[index],
                selected: value,
            };
        },
        parentHandleDeleteFunction(extensionIndex, config) {
            const targetKey = config.parentField.key;
            const extension = this.extensions[extensionIndex];
            const fieldIndex = extension.additionalFields.findIndex(
                (f) => f.key === targetKey
            );
            if (fieldIndex > -1) {
                const updatedExtension = JSON.parse(JSON.stringify(extension));
                const targetField =
                    updatedExtension.additionalFields[fieldIndex];
                targetField.value.splice(config.deleteIndex, 1);
                this.extensions[extensionIndex] = updatedExtension;
            }
        },
        parentHandleAddFunction(extensionIndex, config) {
            const targetKey = config.parentField.key;
            const extension = this.extensions[extensionIndex];
            const fieldIndex = extension.additionalFields.findIndex(
                (f) => f.key === targetKey
            );
            if (fieldIndex > -1) {
                const updatedExtension = JSON.parse(JSON.stringify(extension));
                const targetField =
                    updatedExtension.additionalFields[fieldIndex];

                targetField.value = [...targetField.value, config.data];
                this.extensions[extensionIndex] = updatedExtension;
            }
        },
        handleFieldInputFunction(
            extensionIndex,
            field,
            value,
            parentFieldConfig
        ) {
            const targetKey = parentFieldConfig
                ? parentFieldConfig.parentField.key
                : field.key;
            const extension = this.extensions[extensionIndex];
            const fieldIndex = extension.additionalFields.findIndex(
                (f) => f.key === targetKey
            );
            if (fieldIndex > -1) {
                const updatedExtension = JSON.parse(JSON.stringify(extension));
                const targetField =
                    updatedExtension.additionalFields[fieldIndex];

                if (parentFieldConfig) {
                    targetField.value[parentFieldConfig.parentIndex][
                        parentFieldConfig.childFieldIndex
                    ].value = value;
                } else {
                    targetField.value = value;
                }

                this.extensions[extensionIndex] = updatedExtension;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.quick-extensions {
    border-radius: 8px;
    h4 {
        margin: 0;
        padding: 20px;
    }
}
</style>
