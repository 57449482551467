<template>
    <div class="adjustment-container my-10px">
        <div
            class="d-flex align-items-center justify-content-between adjustment"
            @click="caretOpen = !caretOpen"
        >
            <p class="font-16 fw-600" v-html="logHeadingText"></p>
            <div class="d-flex align-items-center">
                <p class="font-14 text-activeColorV2">View Info</p>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="$colors.bevColors.activeColorV2"
                    :imageUrl="$imagePath.caretDown"
                    :imageAlt="'caret-down'"
                    :width="'12px'"
                    :height="'7px'"
                    class="cursor-pointer ml-10px"
                    :class="{ open: caretOpen }"
                ></bev-image>
            </div>
        </div>
        <slide-up-down :active="caretOpen" :duration="500">
            <component
                :is="activeAdjustmentComponent"
                v-bind="adjustmentComponentProps"
            />
        </slide-up-down>
    </div>
</template>

<script>
import AdjustmentDetails from "@/components/Views/Details/MTA/Adjustments/AdjustmentDetails";
import StatusUpdate from "@/components/Views/Details/MTA/Adjustments/StatusUpdate";
export default {
    props: {
        adjustment: Object,
        selectedOption: String,
    },
    components: {
        AdjustmentDetails,
        StatusUpdate,
    },
    computed: {
        activeAdjustmentComponent() {
            return this.selectedOption === "policyStatus"
                ? StatusUpdate
                : AdjustmentDetails;
        },
        adjustmentComponentProps() {
            if (this.selectedOption === "policyStatus") {
                return {
                    change: this.adjustment.changes[0],
                    reason: this.adjustment.reason,
                };
            }

            return {
                adjustmentChanges: this.adjustment.changes || [],
                reason: this.adjustment.reason,
                selectedOption: this.selectedOption,
                isReferredMTA: this.adjustment?.referred,
                isDeclinedMTA:
                    this.selectedOption === "policy" &&
                    this.adjustment.referredApproved === false &&
                    this.adjustment.brokerReviewed !== false,
                isMTAUnderReview:
                    this.selectedOption === "policy" &&
                    this.adjustment.referredApproved === true,
                isMTAReviewDeclined:
                    this.selectedOption === "policy" &&
                    this.adjustment.referredApproved === false &&
                    this.adjustment.brokerReviewed === false,
            };
        },
        logHeadingText() {
            switch (this.selectedOption) {
                case "policy":
                    return `Adjustments Made On <span class="text-activeColorV1">${this.formatDateValue(
                        this.adjustment.creationTime,
                        "date",
                        "dd MMMM yyyy HH:mm"
                    )}</span> by <span class="text-activeColorV1">${
                        this.adjustment.createdByUser
                    }`;
                case "endorsements":
                    return `Endorsement Updated On <span class="text-activeColorV1">${this.formatDateValue(
                        this.adjustment.creationTime,
                        "date",
                        "dd MMMM yyyy HH:mm"
                    )}</span> by <span class="text-activeColorV1">${
                        this.adjustment.createdByUser
                    }`;
                case "policyStatus":
                    return `Status Updated On <span class="text-activeColorV1">${this.formatDateValue(
                        this.adjustment.creationTime,
                        "date",
                        "dd MMMM yyyy HH:mm"
                    )}</span> as a result of an adjustment made by <span class="text-activeColorV1">${
                        this.adjustment.createdByUser
                    }`;
                default:
                    return "";
            }
        },
    },
    data() {
        return {
            caretOpen: false,
        };
    },
};
</script>

<style scoped>
.adjustment-container {
    background: var(--baseShade1);
    border-radius: 5px;
}
.adjustment {
    border-radius: 5px;
    background-color: var(--baseDark);
    padding: 20px;
    cursor: pointer;
}
.adjustment .svg-image {
    transition: 0.2s all ease-in-out;
}
.adjustment .svg-image.open {
    transform: translateY(-50%) rotate(180deg);
}
</style>
