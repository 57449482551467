<template>
    <div
        class="bev-card baseDark p-10px px-20px mt-20px text-center border-1 border-solid quote-detail-status sm-font-12 fw-bold"
        :class="statusClass"
    >
        {{ statusInfoText }}
        <br />
        <p v-if="statusReason" class="reason">Reason: {{ statusReason }}</p>
    </div>
</template>

<script>
/**
 * Status bar to show status of the quote/policy
 *
 * @param {String} statusFor quote or policy status
 * @param {String} statusClass css class for status
 * @param {String} statusText status text to show
 */

export default {
    props: {
        statusFor: String,
        statusClass: String,
        statusText: String,
        statusReason: String,
        customStatusText: String,
    },
    computed: {
        middleText() {
            switch (this.statusText) {
                case "Incomplete":
                case "In Broker Review":
                    return " ";
                default:
                    return " has been ";
            }
        },
        statusInfoText() {
            return (
                this.customStatusText ||
                this.statusFor + this.middleText + this.statusText
            );
        },
    },
};
</script>

<style scoped>
.reason {
    margin-top: 10px;
    font-weight: normal;
}
.border-solid {
    border-style: solid !important;
}
</style>
