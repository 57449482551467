<template>
    <div class="center-layout terms-page">
        <div class="center-content">
            <MergedLogo
                showPoweredBy
                :logoWidth="'100px'"
                :logoMaxWidth="'100px'"
                class="mx-auto mb-30px bev-logo w-max-content"
            />
            <TermsData />
            <div class="bg-black-pearl w-100">
                <bev-checkbox
                    class="py-20px px-40px w-auto"
                    v-model="acknowledge"
                    :extraClass="'large'"
                >
                    <template v-slot:label>
                        <p>
                            Yes, I understand and agree to the
                            {{ subDomainDetail.name }}
                            <a
                                class="cursor-pointer text-activeColorV1 font-18 fw-bold"
                                :href="subDomainDetail.termsUrl || '#'"
                                target="_blank"
                                >Terms of Use</a
                            >
                            and
                            <a
                                class="cursor-pointer text-activeColorV1 font-18 fw-bold"
                                :href="subDomainDetail.privacyUrl || '#'"
                                target="_blank"
                                >Privacy Policy</a
                            >.
                        </p>
                    </template>
                </bev-checkbox>
            </div>
            <actions
                class="mt-30px"
                :buttonLightProperties="{
                    text: 'I decline',
                    clickHandler: () => {},
                }"
                :buttonDarkProperties="{
                    text: 'I accept',
                    clickHandler: goToRegister,
                    loading: acceptingTerms,
                }"
                :buttonDarkDisabled="!acknowledge"
                :align="'center'"
            ></actions>
            <logout-link></logout-link>
        </div>
    </div>
</template>

<script>
import UserService from "@/services/UserService";
import { mapState, mapMutations } from "vuex";
import LogoutLink from "@/components/Common/LogoutLink";
import TermsData from "@/components/Views/Terms/TermsData";
import MergedLogo from "@/components/Common/MergedLogo.vue";
export default {
    components: {
        LogoutLink,
        TermsData,
        MergedLogo,
    },
    data() {
        return {
            acceptingTerms: false,
            acknowledge: false,
        };
    },
    computed: {
        ...mapState("auth", ["userId"]),
    },
    methods: {
        ...mapMutations("auth", ["setUser"]),
        async goToRegister() {
            this.acceptingTerms = true;
            let attributes = JSON.parse(JSON.stringify(this.userAttributes));
            attributes.terms_and_conditions_accepted = true;
            try {
                let response = await UserService.updateUserDetails(
                    this.userId,
                    {
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        username: this.user.username,
                        emailVerified: true,
                        enabled: true,
                        attributes: attributes,
                    }
                );
                this.setUser({
                    ...this.user,
                    attributes: response.data.attributes,
                });
                this.acceptingTerms = false;
                this.$router.push({ name: "Personal Details" });
            } catch (err) {
                this.acceptingTerms = false;
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        this.$messages.errorMessages.termsAcceptError
                );
            }
        },
    },
};
</script>
