<template>
    <input-wrapper
        :inputLabel="inputLabel"
        :haveError="haveError || inheritedHaveError"
        :errorMessage="errorMessage || inheritedErrorMessage"
        :fullWidth="fullWidthInput"
        :inputActive="inputActive"
    >
        <vue-tel-input
            v-model="phone"
            :autoDefaultCountry="false"
            :defaultCountry="defaultCountryCode"
            @validate="validateInput"
            @focus="inputActive = true"
            @blur="v = false"
            @keydown="handleKedown"
            @country-changed="handleCountryChange"
            :inputOptions="{
                placeholder: '',
            }"
            :dropdownOptions="{
                showDialCodeInList: false,
                showDialCodeInSelection: false,
                showFlags: true,
                showSearchBox: true,
            }"
            :class="{ active: inputActive }"
        >
            <template v-slot:arrow-icon>
                <bev-image
                    :addBackground="true"
                    :backgroundColor="'white'"
                    :imageUrl="$imagePath.caretDown"
                    :imageAlt="'caret-down'"
                    :width="'12px'"
                    :height="'7px'"
                    class="cursor-pointer tel-caret-icon"
                    :class="{ open: dropOpen }"
                >
                </bev-image>
            </template>
        </vue-tel-input>
    </input-wrapper>
</template>

<script>
/**
 * This is BEV custom input which will be used wherever there is telephone input
 *
 * @param {String} inputLabel for input label prop
 * @param {null} modelValue for input value prop
 * @param {Boolean} inheritedHaveError for showing error with the input
 * @param {String} inheritedErrorMessage error message to show if haveError is true
 * @param {Boolean} fullWidthInput for showing a full width input (used in InputWrapper)
 * * @param {String} selectedCountryCode for showing selected country based on country code
 * @param model for two way binding data to input
 **/
import InputWrapper from "@/components/Common/Input/InputWrapper";
export default {
    props: {
        inputLabel: String,
        modelValue: null,
        inheritedHaveError: {
            type: Boolean,
            default: false,
        },
        inheritedErrorMessage: String,
        isValid: Boolean,
        fullWidthInput: {
            type: Boolean,
            default: true,
        },
        selectedCountryCode: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            dropOpen: false,
            errorMessage: "",
            haveError: false,
            validationObject: null,
            phone: null,
            inputActive: false,
        };
    },
    emits: ["update:modelValue", "checkValid", "setCountryDetails"],
    mounted() {
        this.errorMessage = this.inheritedErrorMessage;
        this.phone = this.modelValue;
    },
    components: {
        InputWrapper,
    },
    computed: {
        defaultCountryCode() {
            return this.selectedCountryCode || "GB";
        },
    },
    methods: {
        validateInput(e) {
            if (this.phone) {
                if (!e.valid) {
                    this.haveError = true;
                    this.errorMessage = "Please enter a valid number";
                } else {
                    this.$emit(
                        "update:modelValue",
                        e.nationalNumber || e.number || e.formatted
                    );
                    this.haveError = false;
                    this.errorMessage = this.inheritedErrorMessage;
                }
            } else {
                this.haveError = false;
                this.errorMessage = this.inheritedErrorMessage;
            }
            this.$emit("checkValid", e.valid);
            this.inputActive = false;
        },
        handleCountryChange(c) {
            this.$emit("setCountryDetails", {
                countryCode: c.iso2,
                dialCode: c.dialCode,
            });
        },
        handleKedown(event) {
            const isAllowedKey =
                /^[0-9]$/.test(event.key) ||
                ["ArrowLeft", "ArrowRight", "Backspace", "Delete"].includes(
                    event.key
                );
            if (!isAllowedKey) {
                event.preventDefault();
            }
        },
    },
    watch: {
        inheritedErrorMessage: function (value) {
            this.errorMessage = value;
        },
        inheritedHaveError: function (value) {
            this.haveError = value;
        },
    },
};
</script>

<style>
.vue-tel-input {
    height: 54px;
    border: solid 0.5px white !important;
    border-radius: 6px !important;
    background: none;
}
.vue-tel-input:focus-within {
    box-shadow: none !important;
    border: solid 0.5px var(--activeColorV1) !important;
}
.vue-tel-input .vti__input {
    border: none !important;
    box-shadow: none !important;
    padding: 0;
}
.vue-tel-input .vti__input:focus {
    border: none !important;
}
.vue-tel-input .vti__dropdown.open,
.vue-tel-input .vti__dropdown:hover {
    background-color: transparent;
}

.vue-tel-input .vti__dropdown .vti__input.vti__search_box {
    background: var(--baseDark);
    transition: unset;
    margin: 16px 0;
    border-radius: 6px;
    height: 45px;
    width: 100%;
    padding: 0 10px;
}

.vue-tel-input .vti__dropdown-list {
    background-color: var(--baseShade1);
    padding: 0 20px;
    width: 300px;
    max-height: 400px;
    overflow: auto;
    border: none;
    border-radius: 6px;
    box-shadow: 0 1px 16px 0 rgb(0 0 0 / 16%);
}

.vue-tel-input .vti__dropdown-list .vti__dropdown-item {
    color: white;
    list-style: none;
    padding: 8px 0px;
    font-size: 14px;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.vue-tel-input .vti__dropdown-list .vti__dropdown-item strong {
    font-weight: normal;
}

.vue-tel-input .vti__dropdown-list.below {
    top: 55px;
}

.vue-tel-input .vti__dropdown .tel-caret-icon {
    transition: 0.2s all ease-in-out;
}

.vue-tel-input .vti__dropdown.open .tel-caret-icon {
    transform: translateY(-50%) rotate(180deg);
}

.vti__dropdown-list .vti__dropdown-item:hover,
.vti__dropdown-list .vti__dropdown-item.highlighted,
.vti__dropdown-list .vti__dropdown-item.highlighted:hover {
    color: var(--activeColorV1);
    background-color: transparent;
}
</style>
