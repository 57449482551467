<template>
    <div
        class="col-sm-12 col-md-12 py-20px sm-pb-10px mt-40px px-40px sm-px-10px details policy-info d-flex flex-column"
    >
        <div
            class="row align-items-center mx-0 mb-10px flex-1"
            v-for="(value, name) in quoteInfo"
            :key="name"
        >
            <div class="col-sm-6 col-md-6 col-lg-6">
                <p class="fw-600 sm-font-12">{{ name }}:</p>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <p class="sm-font-12">{{ value }}</p>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Detail related to policy like number, created on etc
 *
 * @param {Object} quoteInfo details for Quote or Policy
 */

export default {
    props: {
        quoteInfo: Object,
    },
};
</script>

<style scoped>
.policy-info {
    border: 1px solid var(--white);
    border-radius: 18px;
}
</style>
