<template>
    <div
        class="select-location-sidebar"
        :style="{
            backgroundColor: $colors.bevColorWithOpacity.baseDark(0.8),
        }"
    >
        <heading-block
            :heading="`Select Location`"
            :description="'Use the post-code finder and drop the pin at the insured location.'"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            :align="'left'"
        ></heading-block>

        <bev-search-input-drop
            :inputType="'text'"
            :inputClass="'have-bg-light'"
            :inputPlaceholder="'Search for location'"
            :results="geoSearchResults"
            :resultFormatter="formatMapResult"
            :resultClickHandler="getDetailFromPlaceId"
            :keyupListener="getLocations"
            :borderLight="true"
            class="w-100 my-20px"
        ></bev-search-input-drop>

        <p class="d-none sm-d-block link font-10" @click="toggleForm">
            Toggle Form
        </p>

        <div class="inputs w-100 position-relative pt-20px">
            <div
                class="d-flex align-items-center position-absolute top-0 end-0 mt-10px"
                v-if="reverseGeocodingLoading"
            >
                <spinner
                    :animation-duration="1000"
                    :size="15"
                    color="white"
                    :spinnerColor="'white'"
                    :width="'15px'"
                    :height="'15px'"
                />
                <p class="m-0 ml-5px font-12">Loading...</p>
            </div>
            <bev-form
                :submitData="submitData.bind(this, true, false, true)"
                :showSingleButton="isNormalUser ? true : false"
                :submitButton="{
                    text: 'Next',
                    loading: validatingCountry,
                    buttonType: 'submit',
                    disabled: buttonDisabled,
                }"
                :resetButton="{
                    text: 'Back',
                    loading: backValidatingCountry,
                    clickHandler: submitData.bind(this, false, true, false),
                    buttonType: 'button',
                }"
            >
                <template v-slot:input-fields>
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Venue Name*'"
                        :haveError="
                            v$.venueName.$error &&
                            v$.venueName.required.$invalid
                        "
                        :inputClass="'buy-policy-venue'"
                        v-model="v$.venueName.$model"
                        class="w-100"
                    ></bev-input>
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Address Line 1'"
                        :inputClass="'buy-policy-addressLine1'"
                        v-model="addressLine1"
                        class="w-100 mt-20px"
                    ></bev-input>
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'City*'"
                        :haveError="v$.city.$error && v$.city.required.$invalid"
                        :errorMessage="$messages.errorMessages.cityEmptyError"
                        :inputClass="'buy-policy-city'"
                        v-model="v$.city.$model"
                        class="w-100 mt-20px"
                    ></bev-input>
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Country*'"
                        :haveError="
                            v$.country.$error && v$.country.required.$invalid
                        "
                        :errorMessage="
                            $messages.errorMessages.fieldCannotEmptyError(
                                'Country'
                            )
                        "
                        :inputClass="'buy-policy-country'"
                        v-model="v$.country.$model"
                        class="w-100 mt-20px"
                    ></bev-input>
                    <bev-input
                        :inputType="'text'"
                        :inputLabel="'Zipcode/Postcode'"
                        :inputClass="'buy-policy-zipcode'"
                        class="w-100 mt-20px"
                        v-model="zipcode"
                    ></bev-input>
                    <p class="mt-10px text-danger" v-if="cellIdError">
                        {{ $messages.errorMessages.pinLocationError }}
                    </p>
                </template>
            </bev-form>
            <SaveAsDraftButton class="mb-20px" :dataSaveHandler="submitData" />
        </div>
        <Teleport to="#maps" v-if="isMapsTargetAvailable">
            <CoverSummaryButton
                class="sm-position-relative sm-mt-10px position-absolute top-0 end-0 z-index-1 opacity-low sm-pt-0 pt-20px"
                :clickHandler="submitData.bind(this, false, false, true)"
            />
        </Teleport>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import geohash from "ngeohash";
import GeocodingService from "@/services/GeocodingService";
import ReferenceDataService from "@/services/ReferenceDataService";
import axios from "axios";
import $ from "umbrellajs";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props: {
        selectedLatlng: Object,
        reverseGeocodingLoading: {
            type: Boolean,
            default: false,
        },
        setReverseGeocodingLoader: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
    },
    components: {
        CoverSummaryButton,
        SaveAsDraftButton,
    },
    data() {
        return {
            venueName: "",
            addressLine1: "",
            city: "",
            country: "",
            zipcode: "",
            geoSearchResults: [],
            selectedCoordinates: null,
            currentCellId: null,
            cellIdError: false,
            CancelToken: axios.CancelToken,
            cancel: null,
            isMapsTargetAvailable: false,
            validatingCountry: false,
            backValidatingCountry: false,
            region: "",
        };
    },
    mounted() {
        this.isMapsTargetAvailable = !!document.getElementById("maps");
        this.setInitialData();
    },
    validations() {
        return {
            venueName: {
                required,
            },
            city: {
                required,
            },
            country: {
                required,
            },
        };
    },
    computed: {
        ...mapState("buyPolicy", [
            "productsJSON",
            "questionBundles",
            "cellId",
            "inValidatedData",
            "currentStepIndex",
        ]),
        ...mapGetters("auth", ["isNormalUser"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        buttonDisabled() {
            return (
                this.venueName === "" ||
                this.country === "" ||
                this.city === "" ||
                (this.parametricWeatherEnabled && this.currentCellId === "")
            );
        },
        parametricWeatherEnabled() {
            return this.productsJSON.find(
                (p) => p.name === "parametric_weather"
            )?.isEnabled;
        },
    },
    emits: ["panmap"],
    methods: {
        ...mapMutations("buyPolicy", [
            "setBuyPolicyStep",
            "setQuestionBundle",
            "setCellId",
            "addIsInvalidData",
        ]),
        ...mapActions("buyPolicy", [
            "takeToNextStep",
            "takeToPreviousStep",
            "updateDataBundleWithValidations",
        ]),
        toggleForm() {
            $(".select-location-sidebar").toggleClass("closed");
        },
        setInitialData() {
            this.addressLine1 =
                this.questionBundles["SelectLocation"]?.addressLine1 ?? "";
            this.venueName =
                this.questionBundles["SelectLocation"]?.venueName ?? "";
            this.city = this.questionBundles["SelectLocation"]?.city ?? "";
            this.country =
                this.questionBundles["SelectLocation"]?.country ?? "";
            this.zipcode =
                this.questionBundles["SelectLocation"]?.zipcode ?? "";
            this.currentCellId = this.cellId || null;
            this.selectedCoordinates =
                this.questionBundles["SelectLocation"]?.coordinates ?? null;
            this.region = this.questionBundles["SelectLocation"]?.region ?? "";
        },
        async getLocations(query) {
            return await GeocodingService.getLocationSuggestions(query);
        },
        formatMapResult(data) {
            return {
                label: data.description,
                data: data,
            };
        },
        async getDetailFromPlaceId(response) {
            this.setReverseGeocodingLoader(true);
            try {
                let res = await GeocodingService.searchLocationByPlaceId(
                    response.data.placeId
                );
                if (res.data.address && res.data.address.country) {
                    let isValidCountry = await this.validateCountry(
                        res.data.address.country
                    );
                    if (isValidCountry) {
                        this.searchLongitudeResponseHandler(res.data.address);
                        this.$emit("panmap", res.data);
                    } else {
                        this.displayCountrySupportError();
                    }
                }
                this.setReverseGeocodingLoader(false);
            } catch (error) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.getLocationError
                );
                this.setReverseGeocodingLoader(false);
            }
        },
        panMapToLocation(location) {
            this.$emit("panmap", location);
        },
        async submitData(forNext, forBack, notSaveForDraft) {
            this.setCellId(this.currentCellId);
            this.updateDataBundleWithValidations({
                bundleName: "SelectLocation",
                bundleData: {
                    addressLine1: this.addressLine1,
                    venueName: this.venueName,
                    country: this.country,
                    city: this.city,
                    zipcode: this.zipcode,
                    coordinates: this.selectedCoordinates,
                    region: this.region,
                },
                self: this,
            });
            if (forBack) {
                if (!this.inValidatedData.includes("country")) {
                    this.backValidatingCountry = true;
                    let isValidCountry = await this.validateCountry(
                        this.country
                    );
                    this.backValidatingCountry = false;
                    if (!isValidCountry) {
                        this.addIsInvalidData("country");
                    }
                }
                if (this.currentStepIndex > 0) {
                    this.takeToPreviousStep();
                } else {
                    this.$router.push("/dashboard");
                }
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.parametricWeatherEnabled && !this.currentCellId) {
                        this.cellIdError = true;
                    } else {
                        this.validatingCountry = true;
                        let isValidCountry = await this.validateCountry(
                            this.country
                        );
                        this.validatingCountry = false;
                        if (!isValidCountry) {
                            this.displayCountrySupportError();
                            return;
                        }
                        if (this.checkFlowComplete && !forNext) {
                            this.setBuyPolicyStep({
                                step: "CoverSummary",
                                back: false,
                            });
                        } else {
                            this.takeToNextStep();
                        }
                    }
                }
            }
        },
        getDataOnMapClick() {
            if (
                this.selectedLatlng &&
                this.selectedLatlng.lat &&
                this.selectedLatlng.lng
            ) {
                this.currentCellId = geohash.encode(
                    this.selectedLatlng.lat,
                    this.selectedLatlng.lng,
                    6
                );
                this.selectedCoordinates = {
                    lat: Number(this.selectedLatlng.lat.toFixed(5)),
                    lng: Number(this.selectedLatlng.lng.toFixed(5)),
                };
            }
            if (this.selectedLatlng && !this.selectedLatlng.dataFilled) {
                this.searchLongitude(this.selectedLatlng);
            }
        },
        searchLongitudeResponseHandler(data) {
            this.country = data.country || "";
            this.venueName =
                data.venueName || data.placeName || data.neighborhood || "";
            this.addressLine1 = data.address || "";
            this.zipcode = data.postalCode || "";
            this.city = data.city || "";
            this.region = data.region || "";
        },
        async searchLongitude(latlng) {
            try {
                let res = await GeocodingService.searchLocationByLatLng(
                    latlng.lat,
                    latlng.lng
                );
                if (res.data.address && res.data.address.country) {
                    let isValidCountry = await this.validateCountry(
                        res.data.address.country
                    );
                    if (isValidCountry) {
                        this.searchLongitudeResponseHandler(res.data.address);
                    } else {
                        this.displayCountrySupportError();
                    }
                } else {
                    this.displayCountrySupportError();
                }
                this.setReverseGeocodingLoader(false);
            } catch (err) {
                this.displayErrorMessage(
                    this.$messages.errorMessages.getLocationError
                );
                this.setReverseGeocodingLoader(false);
            }
        },
        displayCountrySupportError() {
            this.displayErrorMessage(
                this.$messages.errorMessages.countrySupportError
            );
        },
        async validateCountry(country) {
            try {
                let reqBody = {
                    metaDataFilters: [
                        {
                            key: "country",
                            value: country,
                            mandatory: true,
                            fullSearchValOn: true,
                        },
                    ],
                };
                let res = await ReferenceDataService.validateCountry(reqBody);
                return res.data.content.length > 0;
            } catch (error) {
                return false;
            }
        },
    },
    watch: {
        selectedLatlng: function () {
            this.getDataOnMapClick();
        },
    },
};
</script>
