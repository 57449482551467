// Sub Domain wise data map
export default {
    birdseyeviewtechnologies: {
        subDomains: {
            local: {
                name: "BirdsEyeView Local",
                image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "nonproddev-sso",
                allowPayment: false,
                brokerId: "arch_broker",
                underwriterId: "arch",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprod-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            dev: {
                name: "BirdsEyeView",
                image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "dev-sso",
                allowPayment: false,
                brokerId: "dev_broker",
                underwriterId: "arch",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprod-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            nonproddev: {
                name: "BirdsEyeView",
                image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "nonproddev-sso",
                allowPayment: false,
                brokerId: "dev_broker",
                underwriterId: "arch",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprod-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            staging: {
                name: "BirdsEyeView",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "staging-sso",
                allowPayment: true,
                brokerId: "staging_broker",
                underwriterId: "arch",
                orgType: "underwriter",
                clientEmail: "admin@birdseyeviewtechnologies.com",
                termsUrl: "https://www.birdseyeview.ai/terms-and-conditions",
                privacyUrl: "https://www.birdseyeview.ai/privacy-policy",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            app: {
                name: "BirdsEyeView",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
                authDomain: "sso",
                allowPayment: true,
                brokerId: "live_broker",
                underwriterId: "arch",
                orgType: "underwriter",
                clientEmail: "ArchIQ@archinsurance.com",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            howden: {
                authDomain: "howden-sso",
                name: "Howden",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden.png",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden_favicon.png",
                allowPayment: true,
                brokerId: "howden",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                underwriterId: "arch",
                hideNormalBuy: true,
                orgType: "broker",
                clientEmail: "admin@birdseyeviewtechnologies.com",
            },
            howdens: {
                authDomain: "howdens-sso",
                name: "Howden",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden.png",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden_favicon.png",
                allowPayment: true,
                brokerId: "howden",
                renderDirectBuy: true,
                renderDirectBuyLink: true,
                underwriterId: "arch",
                hideNormalBuy: true,
                orgType: "broker",
                clientEmail: "admin@birdseyeviewtechnologies.com",
            },
            arch: {
                authDomain: "arch-sso",
                name: "ArchIQ",
                image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                hideProductDashboard: true,
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                themeColors: {
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                },
                dashboardBannerImages: [
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
            prod: {
                authDomain: "prod-sso",
                name: "ArchIQ",
                image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                hideProductDashboard: true,
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                themeColors: {
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                },
                dashboardBannerImages: [
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
            },
            archiq: {
                authDomain: "archiq-sso",
                name: "ArchIQ",
                image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
                allowPayment: true,
                brokerId: "arch_default",
                favicon:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
                underwriterId: "arch",
                orgType: "underwriter",
                welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
                getQuoteImage: {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                    width: "150px",
                },
                additionalLogo:
                    "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                hideProductDashboard: true,
                themeColors: {
                    activeColorV1: "#5bc2e7",
                    baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                    activeColorV2: "#449aa4",
                    baseLight: "#00274a",
                    generatedStatus: "#5bc2e7",
                    incompleteStatus: "#ffa300",
                    referredStatus: "#009ca6",
                    underwriterApprovedStatus: "#99c221",
                    underwriterDeclinedStatus: "#ba0c2f",
                    brokerReviewStatus: "#009ca6",
                    brokerReviewApprovedStatus: "#99c221",
                    brokerReviewDeclinedStatus: "#ba0c2f",
                    rejectedStatus: "#ba0c2f",
                    expiredStatus: "#5c656d",
                    boundStatus: "#5bc2e7",
                    cancelledStatus: "#ba0c2f",
                    generatedStatusText: "#000000",
                    incompleteStatusText: "#000000",
                    referredStatusText: "#000000",
                    underwriterApprovedStatusText: "#000000",
                    underwriterDeclinedStatusText: "#ffffff",
                    brokerReviewStatusText: "#000000",
                    brokerReviewApprovedStatusText: "#000000",
                    brokerReviewDeclinedStatusText: "#ffffff",
                    rejectedStatusText: "#ffffff",
                    expiredStatusText: "#ffffff",
                    boundStatusText: "#000000",
                    cancelledStatusText: "#ffffff",
                    inputInfoIcon: "#449aa4",
                    footerColor: "#000000",
                    linkColor: "#67e8ff",
                },
                dashboardBannerImages: [
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                        styles: {
                            bottom: "0px",
                            width: "100%",
                            left: "20px",
                            height: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                        styles: {
                            top: "40px",
                            right: "40px",
                            width: "100px",
                        },
                    },
                    {
                        url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                        styles: {
                            left: "40px",
                            top: "40px",
                            width: "180px",
                            maxWidth: "50%",
                        },
                    },
                ],
                sidebarBgImage: {
                    backgroundImage:
                        "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                    backgroundPosition: "0px 68%",
                    backgroundSize: "115%",
                },
                clientEmail: "ArchIQ@archinsurance.com",
                termsUrl:
                    "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
                privacyUrl: "https://www.archgroup.com/privacy-policy/",
                waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
            },
        },
        whiteLabellingData: {
            authDomain: "staging-sso",
            name: "BirdsEyeView",
            image: "https://dev-whitelabelling-documents.s3.eu-west-2.amazonaws.com/birdseyeview.png",
            allowPayment: false,
            brokerId: "dev_broker",
            underwriterId: "arch",
            orgType: "underwriter",
        },
        props: {},
    },
    howden: {
        whiteLabellingData: {
            authDomain: "howden-sso",
            name: "Howden",
            image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden.png",
            favicon:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/howden_favicon.png",
            allowPayment: true,
            brokerId: "howden",
            underwriterId: "arch",
            orgType: "broker",
            clientEmail: "admin@birdseyeviewtechnologies.com",
        },
        props: {
            renderDirectBuy: true,
            renderDirectBuyLink: true,
            hideNormalBuy: true,
        },
    },
    "event-insure": {
        whiteLabellingData: {
            authDomain: "sso",
            name: "EventInsure",
            image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/event_insure.png",
            allowPayment: true,
            brokerId: "howden",
            favicon:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/event_insure_favicon.png",
            underwriterId: "arch",
            orgType: "broker",
            clientEmail: "admin@birdseyeviewtechnologies.com",
        },
        props: {
            renderDirectBuy: true,
            hideNormalBuy: true,
        },
    },
    arch: {
        whiteLabellingData: {
            authDomain: "arch-sso",
            name: "ArchIQ",
            image: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
            allowPayment: true,
            brokerId: "arch_default",
            favicon:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
            underwriterId: "arch",
            orgType: "underwriter",
            welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
            getQuoteImage: {
                url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                width: "150px",
            },
            hideProductDashboard: true,
            additionalLogo:
                "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
            themeColors: {
                activeColorV1: "#5bc2e7",
                baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                activeColorV2: "#449aa4",
                baseLight: "#00274a",
                generatedStatus: "#5bc2e7",
                incompleteStatus: "#ffa300",
                referredStatus: "#009ca6",
                underwriterApprovedStatus: "#99c221",
                underwriterDeclinedStatus: "#ba0c2f",
                brokerReviewStatus: "#009ca6",
                brokerReviewApprovedStatus: "#99c221",
                brokerReviewDeclinedStatus: "#ba0c2f",
                rejectedStatus: "#ba0c2f",
                expiredStatus: "#5c656d",
                boundStatus: "#5bc2e7",
                cancelledStatus: "#ba0c2f",
                generatedStatusText: "#000000",
                incompleteStatusText: "#000000",
                referredStatusText: "#000000",
                underwriterApprovedStatusText: "#000000",
                underwriterDeclinedStatusText: "#ffffff",
                brokerReviewStatusText: "#000000",
                brokerReviewApprovedStatusText: "#000000",
                brokerReviewDeclinedStatusText: "#ffffff",
                rejectedStatusText: "#ffffff",
                expiredStatusText: "#ffffff",
                boundStatusText: "#000000",
                cancelledStatusText: "#ffffff",
                inputInfoIcon: "#449aa4",
                footerColor: "#000000",
                linkColor: "#67e8ff",
            },
            dashboardBannerImages: [
                {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                    styles: {
                        bottom: "0px",
                        width: "100%",
                        left: "20px",
                        height: "100px",
                    },
                },
                {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                    styles: {
                        top: "40px",
                        right: "40px",
                        width: "100px",
                    },
                },
                {
                    url: "https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                    styles: {
                        left: "40px",
                        top: "40px",
                        width: "180px",
                        maxWidth: "50%",
                    },
                },
            ],
            sidebarBgImage: {
                backgroundImage:
                    "url('https://staging-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                backgroundPosition: "0px 68%",
                backgroundSize: "115%",
            },
            clientEmail: "ArchIQ@archinsurance.com",
            termsUrl:
                "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
            privacyUrl: "https://www.archgroup.com/privacy-policy/",
            waUrl: "https://nonprodstage-weatheranalytix.birdseyeviewtechnologies.com/",
        },
    },
    prod: {
        whiteLabellingData: {
            authDomain: "prod-sso",
            name: "ArchIQ",
            image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
            allowPayment: true,
            brokerId: "arch_default",
            favicon:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
            underwriterId: "arch",
            orgType: "underwriter",
            hideProductDashboard: true,
            welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
            getQuoteImage: {
                url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                width: "150px",
            },
            additionalLogo:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
            themeColors: {
                activeColorV1: "#5bc2e7",
                baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                activeColorV2: "#449aa4",
                baseLight: "#00274a",
                generatedStatus: "#5bc2e7",
                incompleteStatus: "#ffa300",
                referredStatus: "#009ca6",
                underwriterApprovedStatus: "#99c221",
                underwriterDeclinedStatus: "#ba0c2f",
                brokerReviewStatus: "#009ca6",
                brokerReviewApprovedStatus: "#99c221",
                brokerReviewDeclinedStatus: "#ba0c2f",
                rejectedStatus: "#ba0c2f",
                expiredStatus: "#5c656d",
                boundStatus: "#5bc2e7",
                cancelledStatus: "#ba0c2f",
                generatedStatusText: "#000000",
                incompleteStatusText: "#000000",
                referredStatusText: "#000000",
                underwriterApprovedStatusText: "#000000",
                underwriterDeclinedStatusText: "#ffffff",
                brokerReviewStatusText: "#000000",
                brokerReviewApprovedStatusText: "#000000",
                brokerReviewDeclinedStatusText: "#ffffff",
                rejectedStatusText: "#ffffff",
                expiredStatusText: "#ffffff",
                boundStatusText: "#000000",
                cancelledStatusText: "#ffffff",
                inputInfoIcon: "#449aa4",
                footerColor: "#000000",
                linkColor: "#67e8ff",
            },
            dashboardBannerImages: [
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                    styles: {
                        bottom: "0px",
                        width: "100%",
                        left: "20px",
                        height: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                    styles: {
                        top: "40px",
                        right: "40px",
                        width: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                    styles: {
                        left: "40px",
                        top: "40px",
                        width: "180px",
                        maxWidth: "50%",
                    },
                },
            ],
            sidebarBgImage: {
                backgroundImage:
                    "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                backgroundPosition: "0px 68%",
                backgroundSize: "115%",
            },
            clientEmail: "ArchIQ@archinsurance.com",
            termsUrl:
                "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
            privacyUrl: "https://www.archgroup.com/privacy-policy/",
        },
    },
    archiq: {
        whiteLabellingData: {
            authDomain: "archiq-sso",
            name: "ArchIQ",
            image: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance-v2.png",
            allowPayment: true,
            brokerId: "arch_default",
            favicon:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance_favicon.png",
            underwriterId: "arch",
            orgType: "underwriter",
            welcomeMessage: "Welcome to Arch <b>IQ<sup>SM</sup></b>",
            hideProductDashboard: true,
            getQuoteImage: {
                url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/get-a-quote-icon.png",
                width: "150px",
            },
            additionalLogo:
                "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
            themeColors: {
                activeColorV1: "#5bc2e7",
                baseBg: "linear-gradient(180deg, #0057B8 0%, #EFEFEF 100%)",
                activeColorV2: "#449aa4",
                baseLight: "#00274a",
                generatedStatus: "#5bc2e7",
                incompleteStatus: "#ffa300",
                referredStatus: "#009ca6",
                underwriterApprovedStatus: "#99c221",
                underwriterDeclinedStatus: "#ba0c2f",
                brokerReviewStatus: "#009ca6",
                brokerReviewApprovedStatus: "#99c221",
                brokerReviewDeclinedStatus: "#ba0c2f",
                rejectedStatus: "#ba0c2f",
                expiredStatus: "#5c656d",
                boundStatus: "#5bc2e7",
                cancelledStatus: "#ba0c2f",
                generatedStatusText: "#000000",
                incompleteStatusText: "#000000",
                referredStatusText: "#000000",
                underwriterApprovedStatusText: "#000000",
                underwriterDeclinedStatusText: "#ffffff",
                brokerReviewStatusText: "#000000",
                brokerReviewApprovedStatusText: "#000000",
                brokerReviewDeclinedStatusText: "#ffffff",
                rejectedStatusText: "#ffffff",
                expiredStatusText: "#ffffff",
                boundStatusText: "#000000",
                cancelledStatusText: "#ffffff",
                inputInfoIcon: "#449aa4",
                footerColor: "#000000",
                linkColor: "#67e8ff",
            },
            dashboardBannerImages: [
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/banner-ribbon.png",
                    styles: {
                        bottom: "0px",
                        width: "100%",
                        left: "20px",
                        height: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch_insurance.png",
                    styles: {
                        top: "40px",
                        right: "40px",
                        width: "100px",
                    },
                },
                {
                    url: "https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/persuingbettertogether.png",
                    styles: {
                        left: "40px",
                        top: "40px",
                        width: "180px",
                        maxWidth: "50%",
                    },
                },
            ],
            sidebarBgImage: {
                backgroundImage:
                    "url('https://prod-whitelabelling-documents.s3.eu-west-2.amazonaws.com/arch-sidebar-bg.svg')",
                backgroundPosition: "0px 68%",
                backgroundSize: "115%",
            },
            clientEmail: "ArchIQ@archinsurance.com",
            termsUrl:
                "https://bev-arch-public.s3.eu-west-2.amazonaws.com/Arch+IQ+Terms+of+Use+2024.pdf",
            privacyUrl: "https://www.archgroup.com/privacy-policy/",
        },
    },
};
