<template>
    <div class="product-section">
        <div
            class="row product-heading font-18 sm-font-14"
            @click="caretOpen = !caretOpen"
        >
            <div class="col-sm-10 col-md-10 col-lg-11">
                {{ $policyValues.productLabels[productHeading] }}
            </div>
            <div class="col-sm-2 col-md-2 col-lg-1">
                <div
                    class="d-flex align-items-center justify-content-end h-100"
                >
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="
                            caretOpen
                                ? $colors.bevColors.activeColorV1
                                : 'white'
                        "
                        :imageUrl="$imagePath.caretDown"
                        :imageAlt="'caret-down'"
                        :width="'12px'"
                        :height="'7px'"
                        class="cursor-pointer ml-10px"
                        :class="{ open: caretOpen }"
                    ></bev-image>
                </div>
            </div>
        </div>
        <slide-up-down :active="caretOpen" :duration="1000">
            <div class="product-info">
                <div
                    class="product-info-item mb-15px"
                    v-for="(value, key) in formattedSummary"
                    :key="key"
                >
                    <div
                        class="row m-0 align-items-center"
                        v-if="renderNormalField(key)"
                    >
                        <div
                            class="col-sm-6 col-md-6 col-lg-6 info-key"
                            :class="[
                                key === 'grossPremium'
                                    ? 'text-activeColorV1 font-16 sm-font-14 fw-600'
                                    : isSubProductPremiumField(key)
                                    ? 'text-activeColorV2 font-14 sm-font-12'
                                    : 'font-14 sm-font-12',
                            ]"
                        >
                            <div class="d-flex align-items-center">
                                <label
                                    :class="
                                        checkIfExistInReferred(key) &&
                                        'text-yellow'
                                    "
                                    >{{ labelHandler(key) }}
                                    <span
                                        class="font-12 text-activeColorV2 fst-italic"
                                        >{{
                                            checkIfExistInReferred(key)
                                                ? "*referred"
                                                : ""
                                        }}</span
                                    ></label
                                >
                            </div>
                        </div>
                        <div
                            class="col-sm-6 col-md-6 col-lg-6 info-value text-right"
                            :class="[
                                key === 'grossPremium'
                                    ? 'text-activeColorV1 font-16 sm-font-14 fw-600'
                                    : isSubProductPremiumField(key)
                                    ? 'text-activeColorV2 font-14 sm-font-12'
                                    : 'font-14 sm-font-12',
                            ]"
                        >
                            <div
                                v-if="
                                    isReferred &&
                                    isEntityStatusReferred &&
                                    key === 'grossPremium'
                                "
                                class="d-flex align-items-center justify-content-end"
                            >
                                <p
                                    v-if="
                                        productInfo?.recommendedPremiumValues?.[
                                            key
                                        ]
                                    "
                                    class="fst-italic font-14 mr-10px"
                                >
                                    <span
                                        v-if="isUnderwriter"
                                        class="text-recommendedValue"
                                    >
                                        (Recommended:
                                        {{ getCurrencyCode() }}
                                        {{
                                            productInfo
                                                ?.recommendedPremiumValues?.[
                                                key
                                            ]
                                        }})
                                    </span>
                                    <span v-else>*Referred</span>
                                </p>
                                <bev-currency-input
                                    :inputLabel="''"
                                    :inputClass="'grossPremium'"
                                    :modelValue="formattedSummary[key]"
                                    @update:modelValue="
                                        (e) => inputBlur(e, key, 'number')
                                    "
                                    :fullWidthInput="false"
                                    :currencySymbol="getCurrencyCode()"
                                    :disabled="
                                        productInfo.premiumBySubProduct &&
                                        !isObjectEmpty(
                                            productInfo.premiumBySubProduct
                                        )
                                    "
                                    v-if="isUnderwriter"
                                ></bev-currency-input>
                            </div>
                            <div
                                v-else-if="
                                    isSubProductPremiumField(key) &&
                                    isReferred &&
                                    isEntityStatusReferred
                                "
                                class="d-flex align-items-center justify-content-end"
                            >
                                <p
                                    v-if="
                                        productInfo?.recommendedPremiumValues
                                            ?.premiumBySubProduct?.[key]
                                    "
                                    class="fst-italic font-14 mr-10px"
                                >
                                    <span
                                        v-if="isUnderwriter"
                                        class="text-recommendedValue"
                                    >
                                        (Recommended:
                                        {{ getCurrencyCode() }}
                                        {{
                                            productInfo
                                                ?.recommendedPremiumValues
                                                ?.premiumBySubProduct?.[key]
                                                ?.grossPremium || null
                                        }})
                                    </span>
                                    <span v-else>*Referred</span>
                                </p>
                                <bev-currency-input
                                    :inputLabel="''"
                                    :inputClass="'grossPremium'"
                                    :modelValue="formattedSummary[key]"
                                    @update:modelValue="
                                        (e) => inputBlur(e, key, 'number', true)
                                    "
                                    :fullWidthInput="false"
                                    :currencySymbol="getCurrencyCode()"
                                    v-if="isUnderwriter"
                                ></bev-currency-input>
                            </div>
                            <span
                                v-else
                                :class="
                                    checkIfExistInReferred(key) && 'text-yellow'
                                "
                            >
                                {{ value }}</span
                            >
                        </div>
                    </div>
                </div>
                <div
                    class="row m-0"
                    v-if="
                        productInfo.quickExtensions &&
                        productInfo.quickExtensions.length
                    "
                >
                    <div class="col-sm-12 py-10px">
                        <QuickExtensions
                            :productExtensions="productInfo.quickExtensions"
                            :productHeading="productHeading"
                            :checkIfExistInReferred="checkIfExistInReferred"
                            :isReferred="isReferred"
                            :isEntityStatusReferred="isEntityStatusReferred"
                            @extensionPremiumUpdated="
                                (data) =>
                                    $emit('extensionPremiumUpdated', {
                                        ...data,
                                        product: productHeading,
                                    })
                            "
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="productInfo.addOns && !isObjectEmpty(productInfo.addOns)"
                class="pl-30px"
            >
                <div v-for="(product, key) in productInfo.addOns" :key="key">
                    <DetailProductSection
                        :details="details"
                        :productHeading="key"
                        :productInfo="product"
                        :isReferred="isReferred"
                        :quoteStatus="quoteStatus"
                        :addonFor="productHeading"
                        :parentProduct="productInfo"
                        :updateValue="updateValue"
                        :isEntityStatusReferred="isEntityStatusReferred"
                    />
                </div>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
/**
 * Single Product section used in CoverDetails
 *
 * @param {String} productHeading heading of the product
 * @param {Object} productInfo details of the product
 * @param {Boolean} isReferred is the detail field is referred
 * @param {String} quoteStatus status of the quote
 * @param {Function} updateValue function to update detail value
 * @param {String} addonFor product for which current product is addon for
 */

import { mapGetters, mapState } from "vuex";
import QuickExtensions from "./QuickExtensions/Main.vue";
import debounce from "lodash.debounce";
export default {
    name: "DetailProductSection",
    props: {
        details: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parentProduct: Object,
        productHeading: {
            type: String,
            default: "",
        },
        productInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isReferred: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        quoteStatus: {
            type: String,
            default: () => {
                return "";
            },
        },
        updateValue: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        addonFor: {
            type: String,
            default: () => {
                return "";
            },
        },
        isEntityStatusReferred: {
            type: Boolean,
            default: false,
        },
        commonFields: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    components: { QuickExtensions },
    computed: {
        ...mapGetters("auth", ["isUnderwriter", "isNormalUser"]),
        ...mapState("buyPolicy", ["productsJSON"]),
        ...mapState("auth", ["roles"]),
        editableAttributeData() {
            return this.editableAttributes.reduce((a, b) => {
                return {
                    ...a,
                    [b.key]: {
                        ...b,
                    },
                };
            }, {});
        },
        formattedSummary() {
            let premiumBySubProduct = null;
            if (
                this.productInfo.premiumBySubProduct &&
                !this.isObjectEmpty(this.productInfo.premiumBySubProduct)
            ) {
                premiumBySubProduct = {};
                Object.keys(this.productInfo.premiumBySubProduct).forEach(
                    (k) => {
                        premiumBySubProduct[k] = JSON.parse(
                            JSON.stringify(
                                this.productInfo.premiumBySubProduct[k]
                            )
                        ).grossPremium;
                    }
                );
            }
            return {
                ...this.getFormattedProductValue(
                    this.productHeading,
                    this.productInfo,
                    this.commonFields,
                    this.parentProduct,
                    true
                ),
                grossPremium: this.isReferred
                    ? this.productInfo.grossPremium
                    : this.thousandMask(
                          this.productInfo.grossPremium,
                          "currency"
                      ),
                ...(this.isReferred &&
                    premiumBySubProduct && {
                        ...premiumBySubProduct,
                    }),
            };
        },
    },
    mounted() {
        // Get and set editableAttributes
        this.setEditableAttributes();
    },
    data() {
        return {
            caretOpen: true,
            editableAttributes: [],
        };
    },
    methods: {
        renderNormalField(key) {
            return (
                this.isNormalField(key) &&
                (key !== "grossPremium" ||
                    (key === "grossPremium" && !this.isNormalUser))
            );
        },
        checkIfExistInReferred(key) {
            if (!this.isReferred) {
                return false;
            }
            if (this.productInfo.referredFields) {
                return this.productInfo.referredFields.indexOf(key) > -1;
            }
            if (this.details.referredFields?.length) {
                return this.details.referredFields.indexOf(key) > -1;
            }
        },
        showEditableField(key) {
            return (
                this.editableAttributeData[key]?.editableBy.filter((role) =>
                    this.roles.includes(role)
                ).length > 0
            );
        },
        labelHandler(key) {
            let labelValue = this.productLabelHandler(key, "label");
            if (key === "grossPremium") {
                return (
                    this.$policyValues.productLabels[this.productHeading] +
                    " - Gross Premium"
                );
            }
            return labelValue === key
                ? this.editableAttributeData[key]?.label || key
                : labelValue;
        },
        // Editable attribute value show handler
        editableValueHandler(key, value) {
            if (
                this.editableAttributeData[key].valueType === "currency" ||
                this.editableAttributeData[key].valueType === "whole_number"
            ) {
                return this.thousandMask(
                    value,
                    this.editableAttributeData[key].valueType
                );
            } else {
                return value;
            }
        },
        // Check if field/attribute is not editable
        isNormalField(key) {
            return (
                this.editableAttributes.findIndex((attribute) => {
                    return attribute.key === key;
                }) < 0
            );
        },
        // Set editable attribute on mounted
        setEditableAttributes() {
            let productJSON = this.productsJSON.find((product) => {
                return this.productHeading === product.name;
            });
            if (
                productJSON &&
                productJSON.editableAttributes &&
                productJSON.editableAttributes.length > 0
            ) {
                this.editableAttributes = productJSON.editableAttributes;
            }
        },
        // Get editable attributes for a product
        getEditableAttributes() {
            return this.editableAttributes.reduce((a, b) => {
                return {
                    ...a,
                    ...(this.showEditableField(b.key) && {
                        [b.key]: this.productInfo[b.key],
                    }),
                };
            }, {});
        },
        // Method to set single field value (blur handler)
        inputBlur: debounce(async function (
            value,
            key,
            valueType,
            isSubProduct = false
        ) {
            let keyToRefer = key;
            if (isSubProduct) {
                keyToRefer =
                    this.$policyValues.subProductPremiumField[key]?.actualKey ||
                    key;
            }
            this.updateValue(
                value,
                keyToRefer,
                this.productHeading,
                valueType,
                this.addonFor,
                isSubProduct
            );
        },
        300),
        isSubProductPremiumField(key) {
            return this.$policyValues.subProductPremiumField[key];
        },
    },
};
</script>

<style scoped lang="scss">
.product-heading {
    word-spacing: 1px;
    color: var(--activeColorV3);
    background: var(--baseDark);
    padding: 10px;
    cursor: pointer;
    margin: 2px 0;
}
.product-info {
    .product-info-item {
        border-bottom: 0.5px solid var(--baseShade2);
        padding: 10px;
        margin: 0;
        .info-key {
            font-weight: 200;
            line-height: 26px;
            padding-left: 15px;
        }
        .info-value {
            font-weight: 600;
            padding-right: 15px;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
.svg-image:hover {
    background: var(--activeColorV1) !important;
}
.addon-premium {
    padding: 10px;
    border-top: 0.5px solid var(--baseShade2);
}
.product-heading .svg-image {
    transition: 0.2s all ease-in-out;
}
.product-heading .svg-image.open {
    transform: translateY(-50%) rotate(180deg);
    margin-top: 7px;
}
.premium {
    border-top: 0.5px solid white;
    padding-top: 10px;
    color: var(--activeColorV2);
}
</style>
