<template>
    <div class="bev-container">
        <div
            class="pb-30px pt-50px px-20px w-100 h-100 px-4% overflow-y-auto"
            v-if="!noProductAdded && !calculatingPremium && !premiumError"
        >
            <heading-block
                :align="'center'"
                :heading="'Cover Summary'"
                :description="'Check the cover selected, make edits, and add products to your cover.'"
                :headingSizeClass="'font-22 sm-font-16'"
                :descriptionSizeClass="'font-16 sm-font-12'"
            ></heading-block>
            <div class="summary-container mx-auto mt-30px">
                <div
                    class="d-flex align-items-center justify-content-between location-detail bg-baseDark"
                    v-if="locationDetails"
                >
                    <div class="d-flex align-items-center py-10px px-25px">
                        <div
                            class="font-18 sm-font-14 text-activeColorV3"
                            :class="
                                checkIfExistInReferred('locationDetails') &&
                                'text-yellow'
                            "
                        >
                            Location
                        </div>
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="$colors.bevColors.activeColorV1"
                            :imageUrl="$imagePath.edit"
                            :clickHandler="redirectToLocationEdit"
                            :imageAlt="'edit-icon'"
                            width="20px"
                            height="20px"
                            class="ml-10px"
                        ></bev-image>
                        <span class="font-12 text-activeColorV2 fst-italic">{{
                            checkIfExistInReferred("locationDetails")
                                ? "*referred"
                                : ""
                        }}</span>
                    </div>
                    <div
                        class="font-15 sm-font-12 py-10px px-25px fw-600"
                        :class="
                            checkIfExistInReferred('locationDetails') &&
                            'text-yellow'
                        "
                    >
                        {{ locationDetails }}
                    </div>
                </div>
                <div
                    class="d-flex align-items-center justify-content-between location-detail bg-baseDark"
                >
                    <div class="d-flex align-items-center py-10px px-25px">
                        <div class="font-18 sm-font-14 text-activeColorV3">
                            Insured
                        </div>
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="$colors.bevColors.activeColorV1"
                            :imageUrl="$imagePath.edit"
                            :clickHandler="redirectToClientEdit"
                            :imageAlt="'edit-icon'"
                            width="20px"
                            height="20px"
                            class="ml-10px"
                        ></bev-image>
                    </div>
                    <div class="font-15 sm-font-12 py-10px px-25px fw-600">
                        {{ clientName }}
                    </div>
                </div>
                <ProductSection
                    :formatProductSummary="formatProductSummary"
                    :findInProducts="findInProducts"
                    :recalculatingPremiumLoading="recalculatingPremiumLoading"
                    :isReferred="isReferred"
                    :checkIfExistInReferred="checkIfExistInReferred"
                    class="mb-10px"
                />
                <div
                    class="d-flex align-items-center justify-content-between font-18 sm-font-16 fw-600 border-top pt-15px pb-10px text-activeColorV3"
                >
                    <p>
                        {{ productLabelHandler("totalGrossPremium", "label") }}
                    </p>
                    <MonetaryFieldWithLoader
                        :loading="recalculatingPremiumLoading"
                        :value="premiumData.grossPremium"
                        :showReferredText="isReferred"
                    />
                </div>
                <div
                    class="d-flex align-items-center justify-content-between font-18 sm-font-16 fw-600 py-10px"
                >
                    <p>
                        {{ productLabelHandler("ipt", "label") }}
                    </p>
                    <MonetaryFieldWithLoader
                        :loading="recalculatingPremiumLoading"
                        :value="premiumData.ipt"
                        :showReferredText="isReferred"
                    />
                </div>
                <div
                    class="d-flex align-items-end justify-content-between font-18 sm-font-16 fw-600 pt-10px pb-15px text-activeColorV1"
                >
                    <div class="d-flex align-items-end">
                        <p class="mr-20px">
                            {{ productLabelHandler("commission", "label") }}
                        </p>
                        <bev-range-slider
                            :label="''"
                            v-model="brokerageValue"
                            :tooltipValueHandler="brokerageTooltipHandler"
                            :min="0"
                            :max="maximumBrokerage"
                            :interval="0.5"
                            :unit="'%'"
                            :sliderClass="'brokerage-slider'"
                            :disabled="recalculatingPremiumLoading"
                            class="commision-slider"
                            v-if="isBroker || isUnderwriter"
                        ></bev-range-slider>
                    </div>
                    <MonetaryFieldWithLoader
                        :loading="recalculatingPremiumLoading"
                        :value="premiumData.commission"
                        :showReferredText="isReferred"
                    />
                </div>
                <div
                    class="d-flex align-items-center justify-content-between font-20 sm-font-14 fw-600 py-10px border-top text-activeColorV2"
                >
                    <p>
                        {{ productLabelHandler("premium", "label") }}
                    </p>
                    <MonetaryFieldWithLoader
                        :loading="recalculatingPremiumLoading"
                        :value="premiumData.totalPremium"
                        :showReferredText="isReferred"
                    />
                </div>
            </div>
            <basic-textarea
                v-if="isEditingOld && editingFor === 'policy'"
                class="amend-reason-container mt-20px"
                :inputLabel="'Please provide a reason for Mid Term Adjustment'"
                :inputClass="'amend-reason'"
                :haveError="
                    v$.auditReason.$error && v$.auditReason.required.$invalid
                "
                :errorMessage="
                    $messages.errorMessages.fieldCannotEmptyError('Reason')
                "
                v-model="v$.auditReason.$model"
            ></basic-textarea>
            <div
                class="d-flex justify-content-center mt-30px"
                v-if="!errorInPremiums && !recalculatingPremiumLoading"
            >
                <bev-button
                    v-if="isEditingOld && editingFor === 'policy'"
                    themeType="light"
                    :clickHandler="cancelPolicyUpdate"
                    buttonType="button"
                    class="medium mx-10px"
                >
                    Cancel
                </bev-button>
                <SaveAsDraftButton
                    buttonThemeType="light"
                    buttonSize="medium"
                    :dataSaveHandler="confirmPolicyData"
                />
                <bev-button
                    v-if="!showGenerateQuoteButton"
                    :themeType="'dark'"
                    :clickHandler="confirmPolicyData.bind(this, true)"
                    :buttonType="'button'"
                    class="medium mx-10px"
                >
                    Next
                </bev-button>
                <bev-button
                    v-if="showGenerateQuoteButton"
                    :themeType="'dark'"
                    :clickHandler="addQuoteHandler"
                    :buttonType="'button'"
                    :loading="policyAddLoading"
                    class="medium mx-10px"
                >
                    {{ saveQuoteButtonText }}
                </bev-button>
            </div>
            <Suggestions
                v-if="
                    productMapComplete &&
                    !errorInPremiums &&
                    !recalculatingPremiumLoading
                "
            />
            <p v-if="errorInPremiums" class="text-yellow text-center my-20px">
                Unable to generate a quote with the provided information. Please
                review for any missing details or consider modifying certain
                information.
            </p>
        </div>
        <div
            class="position-relative h-100 w-100"
            v-if="noProductAdded || calculatingPremium || premiumError"
        >
            <div class="center-positioned text-center">
                <p
                    class="mt-20px font-22"
                    :style="{ color: $colors.bevColorWithOpacity.white(0.14) }"
                    v-if="noProductAdded"
                >
                    No Products Added
                </p>
                <div class="text-center w-100" v-if="premiumError">
                    <bev-image
                        class="m-auto"
                        :addBackground="true"
                        :backgroundColor="$colors.bevColors.danger"
                        :imageUrl="$imagePath.alert"
                        :imageAlt="'error-icon'"
                        :width="'50px'"
                        :height="'50px'"
                    ></bev-image>
                    <p class="mt-20px fw-bold font-26 sm-font-20 text-danger">
                        Failed to calculate premium
                    </p>
                    <bev-button
                        :themeType="'light'"
                        :clickHandler="
                            formatProductSummary.bind(this, true, true)
                        "
                        :loading="false"
                        :disabled="false"
                        :buttonType="'button'"
                        class="small mt-10px"
                    >
                        Retry
                    </bev-button>
                </div>
                <spinner
                    v-if="calculatingPremium"
                    :spinnerColor="$colors.bevColors.veryLightSecondary"
                    :width="'30px'"
                    :height="'30px'"
                ></spinner>
            </div>
        </div>
        <LoginDialog
            :showLoginDialog="showLoginDialog"
            :toggleLoginDialog="toggleLoginDialog"
            :loginDialogHandler="loginDialogHandler"
        />
    </div>
</template>

<script>
/**
 * Cover Summary Component for the BuyPolicy Flow
 */
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LivePricing from "@/services/LivePricing";
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ProductSection from "@/components/Views/BuyPolicy/CoverSummary/ProductSection/Main";
import Suggestions from "@/components/Views/BuyPolicy/CoverSummary/Suggestions/Main";
import BevRangeSlider from "@/components/Common/RangeSlider";
import LoginDialog from "@/components/Views/BuyPolicy/CoverSummary/LoginDialog";
import BasicTextarea from "@/components/Common/Input/BasicTextarea";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import MonetaryFieldWithLoader from "@/components/Common/MonetaryFieldWithLoader";
import debounce from "lodash.debounce";
import PolicyService from "@/services/PolicyService";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        ProductSection,
        Suggestions,
        BevRangeSlider,
        LoginDialog,
        BasicTextarea,
        SaveAsDraftButton,
        MonetaryFieldWithLoader,
    },
    validations() {
        return {
            auditReason: {
                required:
                    this.isEditingOld && this.editingFor === "policy"
                        ? required
                        : false,
            },
        };
    },
    computed: {
        ...mapState("buyPolicy", [
            "cellId",
            "brokerage",
            "policyProduct",
            "products",
            "productDataMap",
            "questionBundles",
            "policyProductAddon",
            "productsJSON",
            "isEditingOld",
            "referredFields",
            "isDirectBuyFlow",
            "currentStepIndex",
            "productsFlow",
            "editingFor",
            "policyUser",
            "addedQuote",
            "quickExtensions",
            "quickExtensionsPremium",
            "quickExtensionsPremiumLoading",
            "policyPremiumData",
            "savedAsDraftId",
        ]),
        ...mapState("auth", ["isAuthenticated"]),
        ...mapGetters("auth", [
            "isBroker",
            "isUnderwriter",
            "isAdmin",
            "userAttributes",
        ]),
        maximumBrokerage() {
            try {
                const defaultMaxCommission =
                    Number(this.userAttributes?.maximumCommission) || 25;
                if (
                    this.isUnderwriter &&
                    this.isEditingOld &&
                    this.addedQuote?.maximumCommission
                ) {
                    return Math.max(
                        this.addedQuote.maximumCommission,
                        defaultMaxCommission
                    );
                }

                return defaultMaxCommission;
            } catch (e) {
                return 25;
            }
        },
        saveQuoteButtonText() {
            if (this.isReferred) {
                return "Submit for Approval";
            }
            if (this.editingFor === "quote") {
                if (
                    this.addedQuote.quoteStatus ===
                    this.$policyValues.quoteStatus.GENERATED.value
                ) {
                    return "Save Quote";
                }
            }
            return "Generate Quote";
        },
        showGenerateQuoteButton() {
            if (this.editingFor) {
                return this.editingFor === "quote";
            }
            return true;
        },
        premiumData() {
            if (!this.isObjectEmpty(this.productDataMap)) {
                return Object.keys(this.productDataMap).reduce(
                    (acc, curr) => {
                        let currProductData = this.productDataMap[curr];
                        if (this.isReferred) {
                            currProductData =
                                this.productDataMap[curr]
                                    .recommendedPremiumValues;
                        }
                        acc = {
                            commission:
                                acc.commission +
                                (currProductData.brokerageAbs || 0),
                            grossPremium:
                                acc.grossPremium +
                                (currProductData.grossPremiumWithAddon ||
                                    currProductData.grossPremium ||
                                    0),
                            ipt: acc.ipt + (currProductData.ipt || 0),
                            totalPremium:
                                acc.totalPremium +
                                (currProductData.grossPremiumPlusIpt || 0),
                        };
                        return acc;
                    },
                    {
                        commission: 0,
                        grossPremium: 0,
                        ipt: 0,
                        totalPremium: 0,
                    }
                );
            }
            return {
                commission: 0,
                grossPremium: 0,
                ipt: 0,
                totalPremium: 0,
            };
        },
        locationDetails() {
            // Getter to compute location string to be shown in Cover Summary
            let locationData = this.questionBundles["SelectLocation"];
            if (locationData.venueName) {
                return `${locationData.venueName}, ${
                    locationData.addressLine1
                        ? locationData.addressLine1 + ", "
                        : ""
                }${locationData.city}, ${locationData.country} ${
                    locationData.zipcode ? ", " + locationData.zipcode : ""
                }`;
            } else {
                return false;
            }
        },
        clientName() {
            if (this.policyUser) {
                return (
                    this.policyUser.firstName + " " + this.policyUser.lastName
                );
            } else {
                return "----";
            }
        },
        isReferred() {
            return this.referredFields && this.referredFields.length > 0;
        },
        mtaReferralQuickExtensionData() {
            return Object.keys(this.quickExtensions)?.reduce(
                (extensions, product) => {
                    return {
                        ...extensions,
                        [product]: this.quickExtensions[product]?.map(
                            (ext) => ext.key
                        ),
                    };
                },
                {}
            );
        },
    },
    created() {
        this.recalculatingPremiumLoading = true;
        // Check if brokerage present in BuyPolicy store else set to maximumBrokerage
        this.brokerageValue = this.brokerage || this.maximumBrokerage;
        // Format product data summary to be shown
        this.formatProductSummary(true, true);
    },
    data() {
        return {
            noProductAdded: false,
            brokerageValue: null,
            productMapComplete: false,
            calculatingPremium: false,
            premiumError: false,
            showLoginDialog: false,
            auditReason: "",
            errorInPremiums: false,
            recalculatingPremiumLoading: false,
            policyAddLoading: false,
            endorsements: [],
        };
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setBuyPolicyStep",
            "setProductDataMap",
            "setPolicyProductAddon",
            "setPolicyPremiumData",
            "setReferredFields",
            "setBrokerage",
            "setDirectBuyComplete",
            "setCurrentStepIndex",
            "setAuditReason",
            "setNetPremiumByRiskCode",
            "setSummarySnapshot",
            "addQuickExtensionsPremiumLoading",
            "resetQuickExtensionsPremiumLoading",
            "setQuickExtensionsPremium",
            "setRecommendedPremium",
            "setEndorsements",
        ]),
        ...mapActions("buyPolicy", ["addQuote", "takeToStep"]),
        redirectToLocationEdit() {
            let componentIndex = this.productsFlow.findIndex((product) => {
                return product.name === "SelectLocation";
            });
            if (componentIndex > -1) {
                this.takeToStep(componentIndex);
            }
        },
        redirectToClientEdit() {
            let componentIndex = this.productsFlow.findIndex((product) => {
                return product.name === "SelectClient";
            });
            if (componentIndex > -1) {
                this.takeToStep(componentIndex);
            }
        },
        toggleLoginDialog() {
            this.showLoginDialog = !this.showLoginDialog;
        },
        findInProducts(product) {
            return this.products.indexOf(product) > -1;
        },
        brokerageTooltipHandler(value) {
            return value + "%";
        },
        async formatProductSummary(makePremiumCall, refreshAllProduct) {
            // Main Handler to initialize format product summary
            this.productMapComplete = false;
            if (refreshAllProduct) {
                // If need to refresh every product data
                this.refreshAllProductData();
            } else {
                // If need to on policyProduct (in BuyPolicy store) data
                this.formatProductDataMapHandler();
            }
            if (makePremiumCall) {
                // make premium call for the products
                this.calculatingPremium = true;
                this.premiumError = false;
                await this.calculateAllProductPremium();
                this.calculatingPremium = false;
            }
            this.$nextTick(() => {
                this.productMapComplete = true;
            });
            this.setSummarySnapshot();
        },
        refreshAllProductData() {
            // Handler to update all product data in productDataMap
            for (const key in this.$policyValues.productLabels) {
                if (this.products.indexOf(key) > -1) {
                    this.formatProductDataMap(key);
                }
            }
            // Set policyProductAddon to null after updating the productDataMap
            this.setPolicyProductAddon(null);
        },
        formatProductDataMap(product) {
            // handler to update single productDataMap
            // get data bundles for the product from productDataBundle constant
            let productDataBundle =
                this.$policyValues.productDataBundle[product];
            // get existing data bundle for the product from the productDataMaps
            let currentProductMap = this.productDataMap[product] || {};
            productDataBundle.forEach((bundle) => {
                // For every product bundle for the product update particular product's data map
                currentProductMap = {
                    ...currentProductMap,
                    ...(this.policyProduct === product &&
                        this.policyProductAddon && {
                            addonFor: this.policyProductAddon,
                        }),
                    ...(bundle === "SelectPeril"
                        ? { peril: this.questionBundles[bundle] }
                        : this.questionBundles[bundle]),
                };
            });
            // Set editable attribute value if any
            let editableAttributes = this.getEditableAttributes(product);
            editableAttributes.forEach((attribute) => {
                currentProductMap[attribute.key] =
                    this.setEditableAttributeDefault(
                        attribute,
                        currentProductMap
                    );
            });
            // Set productDataMap in BuyPolicy store
            this.setProductDataMap({
                ...this.productDataMap,
                [product]: currentProductMap,
            });
        },
        formatProductDataMapHandler() {
            // Set productData for policyProduct
            if (this.policyProduct) {
                this.formatProductDataMap(this.policyProduct);
            }
            this.setPolicyProductAddon(null);
        },
        loginDialogHandler() {
            this.setBuyPolicyStep({ step: "Confirmation", back: false });
            this.$router.push({
                path: "/buy-policy",
                query: { type: "direct" },
            });
        },
        cancelPolicyUpdate() {
            this.$router.push({ path: `/policies/${this.addedQuote.id}` });
        },
        confirmPolicyData(notSaveForDraft) {
            // Set policyPremiumData in store and get to Confirmation page
            this.setPolicyPremiumData({
                totalPremium: this.premiumData.totalPremium,
                grossPremium: this.premiumData.grossPremium,
                ipt: this.premiumData.ipt,
            });
            this.setBrokerage(this.brokerageValue);
            if (notSaveForDraft) {
                this.v$.$touch();
                if (!this.v$.$invalid) {
                    this.setAuditReason(this.auditReason);
                    this.setDirectBuyComplete(true);
                    if (this.isDirectBuyFlow) {
                        this.setCurrentStepIndex(this.currentStepIndex + 1);
                        if (!this.isAuthenticated) {
                            this.toggleLoginDialog();
                        } else {
                            this.loginDialogHandler();
                        }
                    }
                    this.setBuyPolicyStep({
                        step: "Confirmation",
                        back: false,
                    });
                }
            }
        },
        checkIfExistInReferred(key) {
            return (
                this.referredFields.findIndex((p) => p.referredField === key) >=
                0
            );
        },
        async getPolicyMTAReferrals() {
            try {
                const data = {
                    isMta: true,
                    policyLevel: {
                        policyId: this.addedQuote?.id,
                        quickExtensions: this.mtaReferralQuickExtensionData,
                        eventStartDate:
                            this.questionBundles["EventDetails"]
                                ?.eventStartDate || "",
                        eventEndDate:
                            this.questionBundles["EventDetails"]
                                ?.eventEndDate || "",
                        country:
                            this.questionBundles["SelectLocation"]?.country ||
                            "",
                    },
                };

                const res = await PolicyService.getPolicyMTAReferrals(data);
                const referredFields = res.data.referredFields;

                if (referredFields && !this.isObjectEmpty(referredFields)) {
                    Object.keys(referredFields).forEach((key) => {
                        this.setReferredFields([
                            ...this.referredFields,
                            ...this.formatReferredFields(
                                key !== "common_fields" ? key : "",
                                referredFields[key]
                            ),
                        ]);
                    });
                }
            } catch (error) {
                return error;
            }
        },
        async calculateAllProductPremium() {
            // Get premium of all added products and addons
            let apiCalls = [];
            this.errorInPremiums = false;
            this.recalculatingPremiumLoading = true;
            this.setReferredFields([]);
            this.products.forEach((product) => {
                apiCalls.push(this.productPremiumRequests(product));
            });

            try {
                if (this.editingFor === "policy") {
                    await this.getPolicyMTAReferrals();
                }
                let results = await axios.all(apiCalls);
                this.endorsements = [];
                results.forEach((result) => {
                    let resultData = JSON.parse(result.config.data);
                    let productName =
                        resultData?.productConfig?.productName ||
                        resultData.product;
                    this.productPremiumResponseHandler(
                        productName,
                        result.data.body
                    );
                });
                this.setEndorsements([...this.endorsements]);
                this.recalculatingPremiumLoading = false;
            } catch (err) {
                this.premiumError = true;
                this.recalculatingPremiumLoading = false;
            }
        },
        formatReferredFields(product, referredFields) {
            return referredFields.map((referredField) => {
                return { product, referredField };
            });
        },
        setPremiumInExtensions(responseData, product) {
            if (
                responseData.addOns &&
                !this.isObjectEmpty(responseData.addOns)
            ) {
                let currentQuickExtensionsPremiums = {};
                if (this.quickExtensionsPremium[product]) {
                    currentQuickExtensionsPremiums = {
                        ...this.quickExtensionsPremium?.[product],
                    };
                }
                Object.keys(responseData.addOns).map((addon) => {
                    currentQuickExtensionsPremiums[addon] = {
                        ...currentQuickExtensionsPremiums[addon],
                        ...(this.isReferred
                            ? {
                                  grossPremium:
                                      currentQuickExtensionsPremiums[addon]
                                          ?.oldData?.grossPremium,
                              }
                            : {
                                  grossPremium:
                                      responseData.addOns[addon].grossPremium,
                              }),
                        recommendedGrossPremium:
                            responseData.addOns[addon].grossPremium,
                    };
                });
                this.setQuickExtensionsPremium({
                    ...this.quickExtensionsPremium,
                    [product]: currentQuickExtensionsPremiums,
                });
            }
        },
        setPremiumInProduct(response, product) {
            let responseData =
                response === "NO QUOTE"
                    ? {
                          baseProduct: {
                              grossPremium: null,
                          },
                          premiumByRiskCode: null,
                          referredFields: [],
                          totals: null,
                          addOns: {},
                      }
                    : response;
            this.setProductDataMap({
                ...this.productDataMap,
                [product]: {
                    ...this.productDataMap[product],
                    ...(this.isReferred
                        ? this.getReferredPremiumsObject(product, responseData)
                        : {
                              grossPremium:
                                  responseData?.baseProduct?.grossPremium ||
                                  null,
                              // riskCode: responseData.riskCode || "",
                              referredFields: responseData.referredFields || [],
                              grossPremiumWithAddon:
                                  responseData.addOns &&
                                  !this.isObjectEmpty(responseData.addOns)
                                      ? responseData?.totals?.grossPremium
                                      : null,
                              ipt: responseData?.totals?.iptAbs || 0,
                              brokerageAbs:
                                  responseData?.totals?.brokerageAbs || 0,
                              grossPremiumPlusIpt:
                                  responseData?.totals?.grossPremiumPlusIpt ||
                                  0,
                              premiumBySubProduct:
                                  responseData?.premiumBySubProduct
                                      ? {
                                            ...responseData.premiumBySubProduct,
                                        }
                                      : {},
                          }),
                    recommendedPremiumValues: {
                        grossPremium:
                            responseData?.baseProduct?.grossPremium || null,
                        // riskCode: responseData.riskCode || "",
                        referredFields: responseData.referredFields || [],
                        grossPremiumWithAddon:
                            responseData.addOns &&
                            !this.isObjectEmpty(responseData.addOns)
                                ? responseData?.totals?.grossPremium
                                : null,
                        ipt: responseData?.totals?.iptAbs || 0,
                        brokerageAbs: responseData?.totals?.brokerageAbs || 0,
                        grossPremiumPlusIpt:
                            responseData?.totals?.grossPremiumPlusIpt || 0,
                        premiumBySubProduct: responseData?.premiumBySubProduct
                            ? {
                                  ...responseData.premiumBySubProduct,
                              }
                            : {},
                    },
                },
            });
            this.setPremiumInExtensions(responseData, product);
        },
        getReferredPremiumsObject(product, responseData) {
            if (
                product === "event_liability" &&
                responseData?.premiumBySubProduct &&
                !this.questionBundles["EventLiability"]
                    ?.employersLiabilityEnabled
            ) {
                return {
                    grossPremium:
                        this.productDataMap[product]?.oldData
                            ?.premiumBySubProduct?.publicLiability
                            ?.grossPremium || null,
                    // riskCode: responseData.riskCode || "",
                    referredFields: responseData.referredFields || [],
                    grossPremiumWithAddon:
                        this.productDataMap[product]?.oldData
                            ?.grossPremiumWithAddon || null,
                    ipt:
                        this.productDataMap[product]?.oldData
                            ?.premiumBySubProduct?.publicLiability?.ipt || 0,
                    brokerageAbs:
                        this.productDataMap[product]?.oldData
                            ?.premiumBySubProduct?.publicLiability
                            ?.brokerageAbs || 0,
                    grossPremiumPlusIpt:
                        this.productDataMap[product]?.oldData
                            ?.premiumBySubProduct?.publicLiability
                            ?.grossPremiumPlusIpt || 0,
                    premiumBySubProduct: this.productDataMap[product]?.oldData
                        ?.premiumBySubProduct?.publicLiability
                        ? {
                              publicLiability: {
                                  ...this.productDataMap[product]?.oldData
                                      .premiumBySubProduct?.publicLiability,
                              },
                          }
                        : {},
                };
            }
            return {
                grossPremium:
                    this.productDataMap[product]?.oldData?.grossPremium || null,
                // riskCode: responseData.riskCode || "",
                referredFields: responseData.referredFields || [],
                grossPremiumWithAddon:
                    this.productDataMap[product]?.oldData
                        ?.grossPremiumWithAddon || null,
                ipt: this.productDataMap[product]?.oldData?.ipt || 0,
                brokerageAbs:
                    this.productDataMap[product]?.oldData?.brokerageAbs || 0,
                grossPremiumPlusIpt:
                    this.productDataMap[product]?.oldData
                        ?.grossPremiumPlusIpt || 0,
                premiumBySubProduct: this.productDataMap[product]?.oldData
                    ?.premiumBySubProduct
                    ? {
                          ...this.productDataMap[product].oldData
                              .premiumBySubProduct,
                      }
                    : {},
            };
        },
        productPremiumResponseHandler(product, response) {
            if (response === "NO QUOTE") {
                this.errorInPremiums = true;
                this.setPremiumInProduct(response, product);
                return;
            }
            if (response.referredFields) {
                this.setReferredFields([
                    ...this.referredFields,
                    ...this.formatReferredFields(
                        product,
                        response.referredFields
                    ),
                ]);
            }
            if (response.endorsements) {
                this.endorsements = [
                    ...this.endorsements,
                    ...Object.values(response.endorsements),
                ];
            }
            if (response.netPremiumByRiskCode) {
                this.setNetPremiumByRiskCode(response.netPremiumByRiskCode);
            }
            this.setPremiumInProduct(response, product);
        },
        productQuickExtensionPremiumData(product) {
            let earthquakeData = [
                {
                    name: "earthquake",
                    config: {
                        country: this.questionBundles["SelectLocation"].country,
                        city: this.questionBundles["SelectLocation"].city,
                        region: this.questionBundles["SelectLocation"].region,
                    },
                },
            ];

            const currentProductExtensions = JSON.parse(
                JSON.stringify(this.quickExtensions[product] || [])
            );

            currentProductExtensions.forEach((ext) => {
                ext.additionalFields = ext.additionalFields.map((field) => {
                    if (field.mapWithQuoteData) {
                        field.value =
                            this.questionBundles[
                                field.mapWithQuoteData.questionBundle
                            ]?.[field.mapWithQuoteData.key];
                    }
                    return field;
                });
            });

            if (currentProductExtensions?.length) {
                return [
                    ...currentProductExtensions.map((ext) => {
                        return {
                            name: ext.key,
                            config: ext.additionalFields.reduce((acc, curr) => {
                                if (
                                    curr.type === "number" ||
                                    curr.type === "currency"
                                ) {
                                    acc[curr.key] = Number(curr.value);
                                } else if (curr.type === "list") {
                                    const listData = curr.value.reduce(
                                        (a, c) => {
                                            c.forEach((item) => {
                                                if (!a[item.key]) {
                                                    a[item.key] = [];
                                                }
                                                a[item.key].push(item.value);
                                            });
                                            return a;
                                        },
                                        {}
                                    );
                                    acc = {
                                        ...acc,
                                        ...listData,
                                    };
                                } else {
                                    acc[curr.key] = curr.value;
                                }
                                return acc;
                            }, {}),
                        };
                    }),
                    ...earthquakeData,
                ];
            }
            return earthquakeData;
        },
        async quickExtensionOnChangeHandler(newVal, oldVal) {
            let changedProduct = [];
            Object.keys(newVal).forEach((val) => {
                if (!oldVal[val]) {
                    changedProduct.push(val);
                } else {
                    if (
                        JSON.stringify(newVal[val]) !==
                        JSON.stringify(oldVal[val])
                    ) {
                        changedProduct.push(val);
                    }
                }
            });
            let apiCalls = [];
            this.errorInPremiums = false;
            this.recalculatingPremiumLoading = true;
            changedProduct.forEach((product) => {
                this.setReferredFields(
                    this.referredFields.filter(
                        (r) => !r.product || r.product !== product
                    )
                );
                this.addQuickExtensionsPremiumLoading(product);
                apiCalls.push(this.productPremiumRequests(product));
            });
            try {
                if (this.editingFor === "policy") {
                    await this.getPolicyMTAReferrals();
                }
                let results = await axios.all(apiCalls);
                results.forEach((result) => {
                    let resultData = JSON.parse(result.config.data);
                    // key name in pricing for terrorism will be changed in future
                    this.productPremiumResponseHandler(
                        resultData?.productConfig?.productName ||
                            resultData.product,
                        result.data.body
                    );
                });
                this.resetQuickExtensionsPremiumLoading();
                this.recalculatingPremiumLoading = false;
            } catch (err) {
                this.resetQuickExtensionsPremiumLoading();
                this.recalculatingPremiumLoading = false;
                return err;
            }
        },
        productPremiumRequests(product) {
            let quickExtensionData =
                this.productQuickExtensionPremiumData(product);
            switch (product) {
                case "event_cancellation":
                    return LivePricing.livePricingCore({
                        product: "arch_contingency",
                        query: "rating",
                        productConfig: {
                            addOns: quickExtensionData || [],
                            brokeragePct: this.brokerageValue,
                            brokerageMax: this.maximumBrokerage,
                            eventDetails: {
                                eventStartDate:
                                    this.questionBundles["EventDetails"]
                                        ?.eventStartDate,
                                eventEndDate:
                                    this.questionBundles["EventDetails"]
                                        ?.eventEndDate,
                                eventType:
                                    this.questionBundles["EventDetails"]
                                        ?.eventType,
                                eventSetting:
                                    this.questionBundles["EventDetails"]
                                        ?.eventSetting,
                            },
                            specifics: {
                                limit: this.questionBundles[
                                    "EventCancellationNew"
                                ]?.insuredLimitValueV2,
                                limitType:
                                    this.questionBundles["EventCancellationNew"]
                                        ?.coverTypeV2,
                                ...(this.questionBundles["EventCancellationNew"]
                                    ?.eventHeldBeforeTimesV2 && {
                                    eventHeldBeforeTimesV2:
                                        this.questionBundles[
                                            "EventCancellationNew"
                                        ]?.eventHeldBeforeTimesV2,
                                }),
                                ...(this.questionBundles["EventCancellationNew"]
                                    ?.lossCountAfterCovidV2 && {
                                    lossCountAfterCovidV2:
                                        this.questionBundles[
                                            "EventCancellationNew"
                                        ]?.lossCountAfterCovidV2,
                                }),
                                ...(this.questionBundles["EventCancellationNew"]
                                    ?.proceedInSustainWindSpeedV2 && {
                                    proceedInSustainWindSpeedV2:
                                        this.questionBundles[
                                            "EventCancellationNew"
                                        ]?.proceedInSustainWindSpeedV2,
                                }),
                                eventHeldBeforeV2:
                                    this.questionBundles["EventCancellationNew"]
                                        ?.eventHeldBeforeV2,
                                insuredTotalExpensesV2:
                                    this.questionBundles["EventCancellationNew"]
                                        ?.insuredTotalExpensesV2,
                                ...(this.questionBundles["EventCancellationNew"]
                                    ?.insuredTotalRevenueV2 && {
                                    insuredTotalRevenueV2:
                                        this.questionBundles[
                                            "EventCancellationNew"
                                        ]?.insuredTotalRevenueV2,
                                }),
                                ...(this.questionBundles["EventCancellationNew"]
                                    ?.affectedByWeatherV2 && {
                                    affectedByWeatherV2:
                                        this.questionBundles[
                                            "EventCancellationNew"
                                        ]?.affectedByWeatherV2,
                                }),
                                insureLimitV2:
                                    this.questionBundles["EventCancellationNew"]
                                        ?.insureLimitV2,
                            },
                            location: {
                                country:
                                    this.questionBundles["SelectLocation"]
                                        .country,
                                city: this.questionBundles["SelectLocation"]
                                    .city,
                                region: this.questionBundles["SelectLocation"]
                                    .region,
                            },
                            productName: "event_cancellation",
                        },
                    });
                case "event_liability":
                    return LivePricing.livePricingCore({
                        product: "arch_contingency",
                        query: "rating",
                        productConfig: {
                            productName: "event_liability",
                            eventDetails: {
                                eventStartDate:
                                    this.questionBundles["EventDetails"]
                                        ?.eventStartDate,
                                eventEndDate:
                                    this.questionBundles["EventDetails"]
                                        ?.eventEndDate,
                                eventType:
                                    this.questionBundles["EventDetails"]
                                        ?.eventType,
                                eventSetting:
                                    this.questionBundles["EventDetails"]
                                        ?.eventSetting,
                            },
                            addOns: quickExtensionData || [],
                            brokeragePct: this.brokerageValue,
                            brokerageMax: this.maximumBrokerage,
                            specifics: {
                                noOfAttendees:
                                    this.questionBundles["EventLiability"]
                                        ?.noOfAttendees,
                                insureParticipants:
                                    this.questionBundles["EventLiability"]
                                        ?.insureParticipants,
                                limitOfIndemnity:
                                    this.questionBundles["EventLiability"]
                                        ?.limitOfIndemnity,
                                eventCarriedOutPrior:
                                    this.questionBundles["EventLiability"]
                                        ?.eventCarriedOutPrior,
                                employersLiabilityEnabled:
                                    this.questionBundles["EventLiability"]
                                        ?.employersLiabilityEnabled,
                                noOfEmployeesEL:
                                    this.questionBundles["EventLiability"]
                                        ?.noOfEmployeesEL,
                                clericalEmployeeSplitEL:
                                    this.questionBundles["EventLiability"]
                                        ?.clericalEmployeeSplitEL,
                                manualLightEmployeeSplitEL:
                                    this.questionBundles["EventLiability"]
                                        ?.manualLightEmployeeSplitEL,
                                manualHeavyEmployeeSplitEL:
                                    this.questionBundles["EventLiability"]
                                        ?.manualHeavyEmployeeSplitEL,
                                manualHeavyEmployeeSplitDescriptionEL:
                                    this.questionBundles["EventLiability"]
                                        ?.manualHeavyEmployeeSplitDescriptionEL,
                                noHazardousActivityEL:
                                    this.questionBundles["EventLiability"]
                                        ?.noHazardousActivityEL,
                                employeeUnderUKLawEL:
                                    this.questionBundles["EventLiability"]
                                        ?.employeeUnderUKLawEL,
                                ernEL: this.questionBundles["EventLiability"]
                                    ?.ernEL,
                            },
                            location: {
                                country:
                                    this.questionBundles["SelectLocation"]
                                        .country,
                                city: this.questionBundles["SelectLocation"]
                                    .city,
                                region: this.questionBundles["SelectLocation"]
                                    .region,
                            },
                        },
                    });
            }
        },
        brokerageChangeHandler: debounce(function () {
            this.calculateAllProductPremium();
        }, 300),
        addQuoteHandler() {
            this.policyAddLoading = true;
            this.setPolicyPremiumData({
                totalPremium: this.premiumData.totalPremium,
                grossPremium: this.premiumData.grossPremium,
                ipt: this.premiumData.ipt,
            });
            this.setBrokerage(this.brokerageValue);
            this.addQuote({
                productsData: this.formatProductDataForRequest(
                    this.productDataMap,
                    this.quickExtensions,
                    this.quickExtensionsPremium
                ),
                paymentStatus: "INVOICED",
                ...(this.isEditingOld && { id: this.addedQuote.id }),
                ...(this.savedAsDraftId &&
                    !this.isEditingOld && { id: this.savedAsDraftId }),
            }).then((res) => {
                this.policyAddLoading = false;
                this.setRecommendedPremium(this.policyPremiumData.totalPremium);
                if (res.error) {
                    this.displayErrorMessage(
                        res?.data?.response?.data?.message ||
                            "Failed to save quote"
                    );
                } else {
                    let isReferred =
                        res.data.quoteStatus ===
                        this.$policyValues.quoteStatus.REFERRED.value;
                    if (res.data.docs && !isReferred) {
                        this.$notify({
                            group: "BEV",
                            title: "Success",
                            text: this.$messages.infoMessages
                                .documentGenerating,
                            type: "success",
                        });
                    }
                    this.$router.push({ path: `/quotes/${res.data.id}` });
                }
            });
        },
    },
    watch: {
        quickExtensions: function (newVal, oldVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                this.quickExtensionOnChangeHandler(newVal, oldVal);
            }
        },
        brokerageValue: function (newVal, oldVal) {
            if (newVal !== oldVal && !this.recalculatingPremiumLoading) {
                this.brokerageChangeHandler();
            }
        },
    },
};
</script>

<style scoped>
.product-container {
    height: auto;
    background: var(--baseLight);
    border-radius: 8px;
}
.brokerage-slider {
    padding: 0 !important;
}
.commision-slider {
    min-width: 300px;
}
.border-top {
    border-top: 0.5px solid white;
}
.border-bottom {
    border-bottom: 0.5px solid white;
}
</style>
