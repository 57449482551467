<template>
    <div class="col-sm-12 col-md-12 pl-0 mt-40px">
        <div
            class="w-100 h-100"
            v-if="
                !isObjectEmpty(userDetails) &&
                !userDetailLoading &&
                !userDetailError
            "
        >
            <p
                v-if="detailFor === 'policyUser'"
                class="fw-600 font-22 sm-font-18 mb-10px mt-0"
            >
                {{ userDetails.firstName }} {{ userDetails.lastName }}
            </p>
            <div class="details font-16 sm-font-14">
                <p v-if="detailFor === 'policyUser'">
                    {{ userDetails.addressLine1 }}<br />
                    {{ userDetails.addressLine2
                    }}<br v-if="userDetails.addressLine2" />
                    {{ userDetails.city }}, {{ userDetails.country }} -
                    {{ userDetails.zipCode }}
                </p>
                <p v-if="detailFor === 'createdByUser'">
                    <span
                        ><b>Broker Name:</b> {{ userDetails.firstName }}
                        {{ userDetails.lastName }}</span
                    ><br />
                    <span
                        ><b>Company Name:</b>
                        {{ userDetails.brokerCompanyName || "" }}</span
                    ><br />
                    <span
                        ><b>Office Location:</b>
                        {{ userDetails.officeLocation || "" }}</span
                    ><br />
                    <span><b>Team:</b> {{ userDetails.team || "----" }}</span>
                </p>
                <p
                    class="d-flex align-items-center mt-20px"
                    v-if="userDetails.mobileNumber"
                >
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="'white'"
                        :imageUrl="$imagePath.call"
                        :imageAlt="'phone-icon'"
                        width="20px"
                        height="20px"
                        class="mr-15px"
                    ></bev-image>
                    <span>{{ userDetails.mobileNumber }}</span>
                </p>
                <p class="d-flex align-items-center mt-5px" v-if="isValidEmail">
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="'white'"
                        :imageUrl="$imagePath.email"
                        :imageAlt="'mail-icon'"
                        width="20px"
                        height="20px"
                        class="mr-15px"
                    ></bev-image>
                    <a
                        :href="`mailto:${userDetails.email}?subject=${
                            details.policyNumber || details.quoteNumber || ''
                        }`"
                        class="text-break"
                        >{{ userDetails.email }}</a
                    >
                </p>
            </div>
        </div>

        <bev-loading-block
            v-if="userDetailLoading || userDetailError"
            :loading="userDetailLoading"
            :error="userDetailError"
            :retryHandler="getDetails"
            errorMessage="Failed to load user details"
        />
    </div>
</template>

<script>
/**
 * Details of user for whom policy or quote is being generated
 *
 * @param {Object} details details for Quote or Policy
 * @param {String} detailFor quote or policy for which the Details are being shown
 * @param {Function} setUserDetails function to set or change User Details
 * @param {Function} setUserDetails function to set or change Broker Details
 *
 */

import UserService from "@/services/UserService";
export default {
    props: {
        details: Object,
        detailFor: String,
        setUserDetails: Function,
        setBrokerDetails: Function,
    },
    data() {
        return {
            userDetails: {},
            brokerDetails: {},
            userDetailLoading: false,
            userDetailError: false,
        };
    },
    mounted() {
        this.getDetails();
    },
    computed: {
        isValidEmail() {
            return (
                !/@bev-dummy\.com/.test(this.userDetails.email) &&
                this.userDetails.email
            );
        },
    },
    methods: {
        async getDetails() {
            this.userDetailLoading = true;
            this.userDetailError = false;
            if (this.detailFor === "policyUser") {
                await this.getUserDetail(this.details.userId, "user");
            } else {
                await this.getUserDetail(this.details.creatorUserId, "creator");
                if (this.details.brokerId) {
                    await this.getBrokerDetails();
                }
            }
            this.userDetailLoading = false;
        },
        async getUserDetail(userId, userType) {
            // Get user detail according to userId or createrUserId in quote/policy info
            try {
                let res = await UserService.getUserDetails(userId);
                const { data } = res;
                const { attributes } = data;
                this.userDetails = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    addressLine1: attributes.addressLine1[0],
                    addressLine2: attributes.addressLine2[0],
                    city: attributes.city[0],
                    country: attributes.country[0],
                    zipCode: attributes.zipCode[0],
                    mobileNumber: attributes.mobileNumber[0],
                    team: attributes.team[0] || "",
                    email:
                        this.detailFor === "policyUser"
                            ? attributes.clientEmail[0]
                            : data.email,
                };

                if (userType === "user") {
                    this.setUserDetails(this.userDetails);
                }
            } catch (err) {
                this.userDetailError = true;
            }
        },
        async getBrokerDetails() {
            // Get broker detail according to broker in quote/policy info
            try {
                let response = await UserService.getGroupDetailByName(
                    this.details.brokerId
                );
                this.userDetails = {
                    ...this.userDetails,
                    brokerCompanyName:
                        response.data[0].attributes?.brokerCompanyName?.[0] ||
                        "",
                    officeLocation:
                        response.data[0].attributes?.officeLocation?.[0] || "",
                };
                this.setBrokerDetails(response.data[0]);
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        "Failed to get Broker Organization Details"
                );
            }
        },
    },
};
</script>

<style scoped>
.details {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
}
</style>
