<template>
    <div
        :class="`heading-block text-${align} ${width}`"
        class="position-relative"
    >
        <div class="mb-10px d-md-buttons">
            <slot></slot>
        </div>
        <div>
            <div class="heading" :class="headingSizeClass">
                {{ heading }}
            </div>
            <div
                class="description d-flex align-items-center justify-content-center"
                :class="descriptionSizeClass"
                v-if="description"
            >
                <span v-html="description"></span>
                <span
                    v-if="linkInDescription && linkInDescription.text"
                    class="cursor-pointer fw-bold text-activeColorV2"
                    @click="linkInDescription.clickHandler()"
                >
                    {{ linkInDescription.text }}
                </span>
                <bev-image
                    v-if="descriptionTooltipText"
                    :addBackground="true"
                    :backgroundColor="'white'"
                    :imageAlt="'info'"
                    :imageUrl="$imagePath.info"
                    width="20px"
                    height="20px"
                    class="cursor-pointer ml-5px"
                    v-tooltip="{
                        content: descriptionTooltipText,
                        placement: 'right',
                        html: true,
                        showTriggers: (events) => [...events, 'click'],
                        autoHide: true,
                        delay: 0,
                    }"
                ></bev-image>
            </div>
        </div>
        <div class="mt-10px d-sm-buttons">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/**
 * Heading Block
 *
 * @param {String} heading heading of block
 * @param {String} description description of block
 * @param {String} align text alignment
 * @param {String} width width of block
 * @param {String} headingSize font size of heading
 * @param {String} descriptionSize font size of description
 * @param {Object} linkInDescription Data for the link to be shown in the heading
 * @param {String} descriptionTooltipText tooltip text to show with the description
 */

export default {
    props: {
        heading: String,
        description: String,
        align: String,
        width: {
            type: String,
            default: "",
        },
        headingSizeClass: {
            type: String,
            default: "font-22",
        },
        descriptionSizeClass: {
            type: String,
            default: "font-16",
        },
        linkInDescription: {
            type: Object,
            default: () => {
                return {};
            },
        },
        descriptionTooltipText: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped>
.d-sm-buttons {
    display: flex;
    justify-content: center;
}
.d-md-buttons {
    display: none;
}
@media screen and (min-width: 768px) {
    .d-sm-buttons {
        display: none;
    }
    .d-md-buttons {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
