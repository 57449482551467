<template>
    <button
        type="button"
        class="bev-radio-tag"
        :class="{ selected: selected }"
        @click="clickHandler"
        v-tooltip="{ content: tooltipText, html: true }"
    >
        <slot></slot>
    </button>
</template>

<script>
/**
 * BEV Radio tag component
 * @param {Boolean} selected value for the radio
 * @param {Function} clickHandler click handler for the radio tag
 **/

export default {
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        clickHandler: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        tooltipText: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped>
.bev-radio-tag {
    max-width: 100%;
    padding: 12px 25px;
    border-radius: 6px;
    background-color: var(--baseDark);
    border: solid 0.5px transparent;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    cursor: pointer;
}

.bev-radio-tag.small {
    padding: 8px 25px;
    font-size: 12px;
}

.bev-radio-tag:hover {
    border: solid 0.5px white;
}

.bev-radio-tag:focus {
    box-shadow: 0 0 6px 0 white;
}

.bev-radio-tag:disabled {
    opacity: 0.7;
}

.bev-radio-tag.selected,
.bev-radio-tag.selected:hover {
    background-color: var(--activeColorV1);
    color: var(--baseLight);
}
</style>
