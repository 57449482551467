<template>
    <div
        class="bg-baseLight py-40px px-60px sm-py-20px sm-px-20px br-8px bev-container overflow-y-auto policy-detail"
    >
        <div
            class="detail-content"
            v-if="!detailLoading && !detailError && !isObjectEmpty(details)"
        >
            <Comments
                :commentOnNumber="commentOnNumber"
                :newCommentAddedCounter="newCommentAddedCounter"
                v-if="showComment"
            />
            <top-actions
                :details="details"
                :userDetails="userDetails"
                :brokerDetails="brokerDetails"
                :detailFor="detailFor"
                :setDetails="setDetails"
                :actions="actions"
                @userUpdated="userUpdatedHandler()"
                @newCommentAdded="newCommentAddedHandler()"
            ></top-actions>
            <div
                class="position-relative d-flex flex-column align-items-center px-40px sm-px-0"
            >
                <MergedLogo
                    :logoWidth="'100px'"
                    :logoMaxWidth="'100px'"
                    class="bev-logo"
                />
                <heading-block
                    class="mt-20px w-100"
                    :align="'center'"
                    :heading="convertToStartCase(detailFor)"
                    :description="description"
                    :headingSizeClass="'font-40 sm-font-16'"
                    :descriptionSizeClass="'font-16 sm-font-12'"
                ></heading-block>
            </div>
            <StatusInfoBar
                v-if="detailFor === 'quote'"
                :statusFor="'Quote'"
                :statusClass="
                    $policyValues.quoteStatus[details.quoteStatus].statusColor
                "
                :statusText="
                    $policyValues.quoteStatus[details.quoteStatus].text
                "
                :statusReason="statusReason"
            />
            <StatusInfoBar
                v-if="detailFor === 'policy'"
                :statusFor="'Policy'"
                :statusClass="
                    $policyValues.policyStatus[details.policyStatus].statusColor
                "
                :statusText="
                    $policyValues.policyStatus[details.policyStatus].text
                "
                :customStatusText="customStatusText"
                :statusReason="statusReason"
            />
            <p
                v-if="isClonedFrom"
                class="text-right text-light-orange fst-italic mt-10px"
            >
                *This {{ detailFor }} has been cloned. Please check
                <span
                    class="fw-bold text-activeColorV2 cursor-pointer"
                    @click="goToClonedQuote"
                    >here</span
                >
                for reference.
            </p>
            <info-block
                :details="details"
                :setUserDetails="setUserDetails"
                :setBrokerDetails="setBrokerDetails"
                :detailFor="detailFor"
                :userUpdatedCounter="userUpdatedCounter"
            ></info-block>
            <cover-details
                :details="details"
                :userDetails="userDetails"
                :setDetails="setDetails"
                :brokerDetails="brokerDetails"
                :premiumData="premiumData"
                :key="productUpdateCounter"
                @premiumCalculating="setPremiumCalculating"
                @premiumUpdated="onPremiumUpdate"
                :isEntityStatusReferred="isEntityStatusReferred"
            ></cover-details>
            <div
                class="d-flex align-items-center justify-content-between font-18 sm-font-16 fw-600 pt-15px pb-10px border-top text-activeColorV3"
            >
                <p>
                    {{ productLabelHandler("totalGrossPremium", "label") }}
                </p>
                <MonetaryFieldWithLoader
                    :loading="premiumCalculating"
                    :value="premiumData.grossPremium"
                    :isReferred="details.referred && isEntityStatusReferred"
                    :recommendedValue="recommendedPremiumData.grossPremium"
                />
            </div>
            <div
                class="d-flex align-items-center justify-content-between font-18 sm-font-16 fw-600 py-10px border-bottom"
            >
                <p>
                    {{ productLabelHandler("ipt", "label") }}
                </p>
                <MonetaryFieldWithLoader
                    :loading="premiumCalculating"
                    :value="premiumData.ipt"
                    :isReferred="details.referred && isEntityStatusReferred"
                    :recommendedValue="recommendedPremiumData.ipt"
                />
            </div>
            <div
                class="d-flex align-items-center justify-content-between font-20 sm-font-14 fw-600 py-10px text-activeColorV2"
                v-if="
                    details.referred &&
                    details[statusKey] ===
                        $policyValues.quoteStatus.REFERRED.value &&
                    isUnderwriter
                "
            >
                <p>
                    {{ productLabelHandler("recommendedPremium", "label") }}
                </p>
                <p>
                    {{
                        thousandMask(
                            details.recommendedPremium || "",
                            "currency"
                        )
                    }}
                </p>
            </div>
            <div
                class="d-flex align-items-center justify-content-between font-20 sm-font-14 fw-600 py-10px text-activeColorV1"
            >
                <p>
                    {{ premiumHandler.label }}
                </p>
                <MonetaryFieldWithLoader
                    :loading="premiumCalculating"
                    :value="premiumHandler.value"
                />
            </div>
            <div
                :class="`d-flex align-items-center justify-content-between font-18 sm-font-16 fw-600 pt-10px pb-15px text-activeColorV2 ${
                    showBrokerageSlider ? 'mt-20px' : ''
                }`"
            >
                <div class="d-flex align-items-end">
                    <p class="mr-20px">
                        ({{ productLabelHandler("commission", "label") }})
                    </p>
                    <bev-range-slider
                        label=""
                        v-model="brokerageValue"
                        :tooltipValueHandler="brokerageTooltipHandler"
                        :min="0"
                        :max="maximumBrokerage"
                        :interval="0.5"
                        :unit="'%'"
                        :sliderClass="'brokerage-slider'"
                        disabled
                        class="commision-slider"
                        v-if="showBrokerageSlider"
                    ></bev-range-slider>
                </div>

                <MonetaryFieldWithLoader
                    :loading="premiumCalculating"
                    :value="premiumData.commission"
                    :isReferred="details.referred && isEntityStatusReferred"
                    :recommendedValue="recommendedPremiumData.commission"
                />
            </div>
            <MTA
                v-if="detailFor === 'policy' && !detailError && !detailLoading"
                :endorsementUpdateCounter="endorsementUpdateCounter"
            />

            <detail-actions
                class="mt-20px"
                v-if="showActions"
                :details="details"
                :setDetails="setDetails"
                :userDetails="userDetails"
                :detailFor="detailFor"
                :actions="actions"
                :updateEndorsementCounter="updateEndorsementCounter"
            ></detail-actions>
        </div>
        <bev-loading-block
            v-if="detailLoading || detailError"
            :loading="detailLoading"
            :error="detailError"
            :retryHandler="getData"
            :errorMessage="detailErrorMessage"
        />
    </div>
</template>
<script>
/**
 * Main Component to show Detail component for Quote or Policy
 *
 * @param {String} detailFor quote or policy for which the Details are being shown
 */

import QuoteService from "@/services/QuoteService";
import PolicyService from "@/services/PolicyService";
import ProductService from "@/services/ProductService";
import ReferenceDataService from "@/services/ReferenceDataService";
import { mapGetters, mapMutations, mapState } from "vuex";
import CoverDetails from "@/components/Views/Details/CoverDetails/Main.vue";
import DetailActions from "@/components/Views/Details/DetailActions";
import InfoBlock from "@/components/Views/Details/InfoBlock/Main.vue";
import StatusInfoBar from "@/components/Views/Details/StatusInfoBar.vue";
import TopActions from "@/components/Views/Details/TopActions.vue";
import Comments from "@/components/Views/Details/Comments/Main.vue";
import MTA from "@/components/Views/Details/MTA/Main.vue";
import MergedLogo from "@/components/Common/MergedLogo.vue";
import MonetaryFieldWithLoader from "@/components/Common/MonetaryFieldWithLoader";
import BevRangeSlider from "@/components/Common/RangeSlider";
export default {
    props: {
        detailFor: {
            type: String,
            default: () => {
                return "policy";
            },
        },
    },
    mounted() {
        this.getData();
    },
    components: {
        CoverDetails,
        DetailActions,
        InfoBlock,
        StatusInfoBar,
        TopActions,
        Comments,
        MTA,
        MergedLogo,
        MonetaryFieldWithLoader,
        BevRangeSlider,
    },
    computed: {
        ...mapState("auth", ["userId"]),
        ...mapGetters("auth", [
            "isUnderwriter",
            "isBroker",
            "isNormalUser",
            "userAttributes",
            "isReadOnly",
        ]),
        ...mapState("buyPolicy", ["productsJSON", "products", "addedQuote"]),
        ...mapState("referenceData", ["currencyList"]),
        ...mapGetters(["productRequestBody", "getTenantType"]),
        brokerageValue() {
            return this.addedQuote?.brokerage || this.maximumBrokerage;
        },
        maximumBrokerage() {
            try {
                const defaultMaxCommission =
                    Number(this.userAttributes?.maximumCommission) || 25;
                if (this.isUnderwriter && this.addedQuote?.maximumCommission) {
                    return Math.max(
                        this.addedQuote.maximumCommission,
                        defaultMaxCommission
                    );
                }

                return defaultMaxCommission;
            } catch (e) {
                return 25;
            }
        },
        showBrokerageSlider() {
            if (this.detailFor === "policy" && this.addedQuote) {
                if (
                    this.addedQuote.policyStatus ===
                        this.$policyValues.policyStatus.REFERRED.value ||
                    this.addedQuote.policyStatus ===
                        this.$policyValues.policyStatus.BROKER_REVIEW.value
                ) {
                    return this.isUnderwriter || this.isBroker;
                }
                return false;
            }
            return false;
        },
        isClonedFrom() {
            return (
                this.details.oldQuoteReferenceId ||
                this.details.oldPolicyReferenceId
            );
        },
        productsData() {
            if (this.details.products) {
                return JSON.parse(this.details.products);
            }
            return {};
        },
        showComment() {
            return (
                !this.isNormalUser &&
                !this.isReadOnly &&
                this.findInList(
                    this.commentDisabledOn,
                    this.detailFor === "quote"
                        ? this.details.quoteStatus
                        : this.details.policyStatus,
                    "string"
                ) < 0
            );
        },
        commentOnNumber() {
            return this.detailFor === "quote"
                ? this.details.quoteNumber
                : this.details.policyNumber;
        },
        showActions() {
            return !this.isObjectEmpty(this.details);
        },
        statusKey() {
            return this.detailFor === "quote" ? "quoteStatus" : "policyStatus";
        },
        premiumHandler() {
            let premiumValue = "";
            if (
                this.details[this.statusKey] !==
                this.$policyValues.quoteStatus.REFERRED.value
            ) {
                // If detail is for quote and quoteStatus is not Referred show premium
                premiumValue = this.premiumData.totalPremium;
            } else {
                if (this.isUnderwriter) {
                    // If detail is for quote and quoteStatus is Referred and user is underwriter show recommended premium
                    premiumValue =
                        this.premiumData.totalPremium ||
                        this.details.recommendedPremium ||
                        "";
                } else {
                    // If detail is for quote and quoteStatus is Referred and user is not underwriter show '*Referred'
                    premiumValue = "*Referred";
                }
            }
            return {
                label: this.productLabelHandler("premium", "label"),
                value: premiumValue,
            };
        },
        premiumData() {
            if (!this.isObjectEmpty(this.productsData)) {
                return this.premiumDataCalculator();
            }
            return {
                commission: 0,
                grossPremium: 0,
                ipt: 0,
                totalPremium: 0,
            };
        },
        recommendedPremiumData() {
            if (
                !this.isObjectEmpty(this.productsData) &&
                this.details.referred
            ) {
                return this.premiumDataCalculator(true);
            }
            return {
                commission: 0,
                grossPremium: 0,
                ipt: 0,
                totalPremium: 0,
            };
        },
        policyStatusBarInfo() {
            // Getter to set status top bar info
            if (
                this.details.policyStatus ===
                this.$policyValues.policyStatus.CANCELLED.value
            ) {
                return {
                    color: this.$policyValues.policyStatus[
                        this.details.policyStatus
                    ].statusColor,
                    text: this.$policyValues.policyStatus[
                        this.details.policyStatus
                    ].text,
                };
            } else if (this.details.claimIssued) {
                return {
                    color: this.$policyValues.policyStatus["CLAIMED"]
                        .statusColor,
                    text: this.$policyValues.policyStatus["CLAIMED"].text,
                };
            } else {
                return {
                    color: "",
                    text: "",
                };
            }
        },
        statusReason() {
            if (this.detailFor === "quote") {
                if (
                    this.details.quoteStatus ===
                        this.$policyValues.quoteStatus.UNDERWRITER_DECLINED
                            .value ||
                    this.details.quoteStatus ===
                        this.$policyValues.quoteStatus.REJECTED.value
                ) {
                    return (
                        this.details?.statusChangeReason || "No Reason given"
                    );
                }
            } else if (this.detailFor === "policy") {
                if (
                    this.details.policyStatus ===
                        this.$policyValues.policyStatus.CANCELLED.value ||
                    this.details.policyStatus ===
                        this.$policyValues.policyStatus.CANCELLATION_REQUESTED
                            .value ||
                    this.details.policyStatus ===
                        this.$policyValues.policyStatus.CANCELLATION_APPROVED
                            .value
                ) {
                    return (
                        this.details?.statusChangeReason || "No Reason given"
                    );
                }
            }
            return "";
        },
        isEntityStatusReferred() {
            if (this.detailFor === "quote") {
                return (
                    this.details.quoteStatus ===
                    this.$policyValues.quoteStatus.REFERRED.value
                );
            } else {
                return (
                    this.details.policyStatus ===
                    this.$policyValues.policyStatus.REFERRED.value
                );
            }
        },
        customStatusText() {
            if (
                this.details.policyStatus ===
                this.$policyValues.policyStatus.CANCELLATION_REQUESTED.value
            ) {
                return this.$messages.infoMessages.cancellationRequestCreated;
            }
            return "";
        },
    },
    data() {
        return {
            detailLoading: false,
            detailError: false,
            detailErrorMessage: "",
            id: this.$route.params.id,
            details: {},
            card: null,
            description:
                this.detailFor === "policy"
                    ? this.$messages.infoMessages.policyInfoDescription
                    : this.$messages.infoMessages.quoteInfoDescription,
            quoteProcessing: false,
            userDetails: {},
            brokerDetails: {},
            commentDisabledOn: [
                "APPROVED",
                "REJECTED",
                "ARCHIEVED",
                "CANCELLED",
                "CLAIMED",
                "EXPIRED",
            ],
            workflowLoading: false,
            actions: [],
            endorsementUpdateCounter: 0,
            productUpdateCounter: 0,
            premiumCalculating: false,
            userUpdatedCounter: 0,
            newCommentAddedCounter: 0,
        };
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "clearPolicyValues",
            "setProductsJSON",
            "setAddedQuote",
            "setPolicyPremiumData",
            "setSelectedCurrency",
        ]),
        brokerageTooltipHandler(value) {
            return value + "%";
        },
        goToClonedQuote() {
            this.$router.push({
                path: `/${
                    this.details.oldQuoteReferenceId ? "quotes" : "policies"
                }/${this.isClonedFrom}`,
            });
        },
        userUpdatedHandler() {
            this.userUpdatedCounter++;
        },
        newCommentAddedHandler() {
            this.newCommentAddedCounter++;
        },
        premiumDataCalculator(referred) {
            return Object.keys(this.productsData).reduce(
                (acc, curr) => {
                    let currProductData = this.productsData[curr];
                    if (referred) {
                        if (this.productsData[curr].recommendedPremiumValues) {
                            currProductData =
                                this.productsData[curr]
                                    .recommendedPremiumValues;
                        } else {
                            currProductData = {};
                        }
                    }
                    acc = {
                        commission:
                            acc.commission +
                            (currProductData.brokerageAbs || 0),
                        grossPremium:
                            acc.grossPremium +
                            (currProductData.grossPremiumWithAddon ||
                                currProductData.grossPremium ||
                                0),
                        ipt: acc.ipt + (currProductData.ipt || 0),
                        totalPremium:
                            acc.totalPremium +
                            (currProductData.grossPremiumPlusIpt || 0),
                    };
                    return acc;
                },
                {
                    commission: 0,
                    grossPremium: 0,
                    ipt: 0,
                    totalPremium: 0,
                }
            );
        },
        setPremiumCalculating(value) {
            this.premiumCalculating = value;
        },
        async onPremiumUpdate(response) {
            let products = { ...this.productsData };
            if (response.data.body && response.data.body.products) {
                Object.keys(response.data.body.products).forEach((product) => {
                    products[product].grossPremium =
                        response.data.body.products[product].grossPremium;
                    products[product].grossPremiumWithAddon =
                        response.data.body.products[
                            product
                        ].totals?.grossPremium;
                    products[product].ipt =
                        response.data.body.products[product].totals?.iptAbs;
                    products[product].brokerageAbs =
                        response.data.body.products[
                            product
                        ].totals?.brokerageAbs;
                    products[product].grossPremiumPlusIpt =
                        response.data.body.products[
                            product
                        ].totals?.grossPremiumPlusIpt;
                    if (
                        products[product].quickExtensions &&
                        products[product].quickExtensions.length
                    ) {
                        products[product].quickExtensions.forEach((ext) => {
                            ext.premium.grossPremium =
                                response.data.body.products[product].addons[
                                    ext.key
                                ].grossPremium;
                        });
                    }
                });
                await this.setDetails({
                    ...this.details,
                    products: JSON.stringify(products),
                });
                await this.setDetails({
                    ...this.details,
                    premium: this.premiumData.totalPremium,
                    ipt: this.premiumData.ipt,
                    grossPremium: this.premiumData.grossPremium,
                });
            }
        },
        updateEndorsementCounter() {
            this.endorsementUpdateCounter++;
        },
        async setDetails(data, productUpdate, actionUpdate) {
            this.details = data;
            // Set addedQuote in BuyPolicy state for edit quote/policy flow
            this.setAddedQuote(data);
            // Set currency
            this.setSelectedCurrency(data?.currency?.currencyCode || "GBP");

            // Set policyPremiumData in BuyPolicy state for edit quote/policy flow
            this.setPolicyPremiumData({
                totalPremium: data.premium,
                grossPremium: data.grossPremium,
                ipt: data.ipt,
            });
            if (productUpdate) {
                this.productUpdateCounter++;
            }
            if (actionUpdate) {
                await this.getPolicyWorkflowData();
            }
        },
        setUserDetails(data) {
            this.userDetails = data;
        },
        setBrokerDetails(data) {
            this.brokerDetails = data;
        },
        formatInsuredLocation() {
            return `${this.details.addressLine1}, ${
                this.details.addressLine2
                    ? this.details.addressLine2 + ", "
                    : ""
            } ${this.details.city} ${
                this.details.zipcode ? ", " + this.details.zipcode : ""
            }, ${this.details.country}`;
        },
        async getAllProducts() {
            // Get all productJSON and set to BuyPolicy state
            this.clearPolicyValues("SelectProduct");
            try {
                let response = await ProductService.searchProducts(
                    0,
                    1000,
                    this.productRequestBody
                );
                this.setProductsJSON(response.data.content);
                return response;
            } catch (err) {
                throw new Error("Failed to load product details", {
                    cause: err.response,
                });
            }
        },
        getPolicyQuoteApiUrlParam() {
            if (this.isBroker) {
                let brokerId =
                    this.userAttributes.brokerId ||
                    this.subDomainDetail.brokerId;
                return `?brokerId=${brokerId}`;
            } else if (this.isNormalUser) {
                return `?userId=${this.userId}`;
            } else {
                return "";
            }
        },
        async getDetails() {
            // Handler to get detail according to quote/policy
            try {
                let res = "";
                if (this.detailFor === "quote") {
                    res = await QuoteService.getSingleQuote(
                        this.id,
                        this.getPolicyQuoteApiUrlParam()
                    );
                } else {
                    res = await PolicyService.getSinglePolicy(
                        this.id,
                        this.getPolicyQuoteApiUrlParam()
                    );
                }
                await this.setDetails(res.data, false, true);
                return res;
            } catch (err) {
                throw new Error(`Failed to load ${this.detailFor} details`, {
                    cause: err.response,
                });
            }
        },
        async getData() {
            this.detailLoading = true;
            this.detailError = false;
            try {
                await this.getAllProducts();
                await this.getDetails();
                this.detailLoading = false;
            } catch (error) {
                this.detailErrorMessage =
                    error?.cause?.data?.message || error.message;
                this.detailError = true;
                this.detailLoading = false;
            }
        },
        async getPolicyWorkflowData() {
            this.workflowLoading = true;
            let role = this.isUnderwriter
                ? "underwriter"
                : this.isBroker
                ? "broker"
                : "";
            try {
                if (role) {
                    const response =
                        await ReferenceDataService.getPolicyWorkflow(
                            role,
                            this.details[this.statusKey],
                            this.detailFor,
                            this.getTenantType
                        );
                    this.actions =
                        response.data.content?.[0]?.metaData.actions || [];
                } else {
                    this.actions = [];
                }
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message ||
                        `Failed to load ${this.detailFor} actions`
                );
            } finally {
                this.workflowLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.border-top {
    border-top: 0.5px solid white;
}
.border-bottom {
    border-bottom: 0.5px solid white;
}
.commision-slider {
    min-width: 300px;
}
.brokerage-slider {
    padding: 0 !important;
}
</style>
