<template>
    <div class="sm-mx--10px">
        <div class="gradient_background w-100 mt-40px">
            <span class="gradient_heading font-26 sm-font-18"
                >Cover Details</span
            >
        </div>
        <div class="w-100 h-auto">
            <div
                class="d-flex align-items-center justify-content-between location-detail bg-baseDark mt-10px"
                v-if="locationDetails"
            >
                <div
                    class="font-18 text-activeColorV3 py-10px px-25px sm-font-14"
                    :class="
                        checkIfExistInReferred('locationDetails') &&
                        'text-yellow'
                    "
                >
                    Location
                    <span class="font-12 text-activeColorV2 fst-italic">{{
                        checkIfExistInReferred("locationDetails")
                            ? "*referred"
                            : ""
                    }}</span>
                </div>
                <div
                    class="font-15 py-10px px-25px fw-600 sm-font-12"
                    :class="
                        checkIfExistInReferred('locationDetails') &&
                        'text-yellow'
                    "
                >
                    {{ locationDetails }}
                </div>
            </div>
            <div v-for="(product, key) in productsList" :key="key">
                <DetailProductSection
                    :details="details"
                    :productHeading="key"
                    :productInfo="product"
                    :commonFields="commonFields"
                    :isReferred="details.referred"
                    :quoteStatus="details.quoteStatus"
                    :updateValue="updateProductValue"
                    @extensionPremiumUpdated="
                        (data) => extensionPremiumUpdated(data)
                    "
                    :isEntityStatusReferred="isEntityStatusReferred"
                />
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Quote or Policy Details List component
 *
 * @param {Object} details details for Quote or Policy
 * @param {Function} setDetails funtion to set details
 * @param {Function} userDetails details of user for whom policy is being purchased
 * @param {Function} brokerDetails details of broker who purchased the policy
 */

import DetailProductSection from "@/components/Views/Details/CoverDetails/DetailProductSection.vue";
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
import LivePricing from "@/services/LivePricing";
import { _set } from "@/helper/object";
export default {
    components: { DetailProductSection },
    mounted() {
        if (!this.isObjectEmpty(this.details)) {
            this.productsList = JSON.parse(this.details.products);
            this.commonFields = this.productsList["common_fields"];
            delete this.productsList["common_fields"];
        }
        this.calculateAndSetPremium();
    },
    data() {
        return {
            productsList: {},
            commonFields: {},
        };
    },
    props: {
        details: {
            type: Object,
            default: () => {
                return {};
            },
        },
        setDetails: {
            type: Function,
            default: () => {
                return {};
            },
        },
        userDetails: {
            type: Object,
            default: () => {
                return {};
            },
        },
        brokerDetails: {
            type: Object,
            default: () => {
                return {};
            },
        },
        premiumData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isEntityStatusReferred: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("auth", ["isUnderwriter"]),
        locationDetails() {
            return `${this.details.addressLine2}, ${
                this.details.addressLine1
                    ? this.details.addressLine1 + ", "
                    : ""
            }${this.details.city}, ${this.details.country} ${
                this.details.zipcode ? ", " + this.details.zipcode : ""
            }`;
        },
    },
    methods: {
        // Update product field handler
        extensionPremiumUpdated(data) {
            this.productsList[data.product].quickExtensions = this.productsList[
                data.product
            ].quickExtensions.map((ext) => {
                if (ext.key === data.extension.key) {
                    ext.premium.grossPremium = data.value;
                }
                return ext;
            });
            this.$emit("premiumCalculating", true);
            this.calculateAndSetPremium();
        },
        updateProductValue(
            value,
            key,
            product,
            valueType,
            addonFor,
            isSubProduct = false
        ) {
            // If value is a number set number value after removing comma
            let keyValue =
                valueType === "number"
                    ? Number(this.removeCommaFromNumber(value))
                    : value;
            if (addonFor) {
                // If value is for an addon set respective value in product's addonFor addons
                _set(
                    key,
                    this.productsList[addonFor].addOns[product],
                    keyValue
                );
            } else {
                _set(key, this.productsList[product], keyValue);
                if (isSubProduct) {
                    _set(
                        "grossPremium",
                        this.productsList[product],
                        this.getTotalSubProductPremiums(product)
                    );
                }
                this.setDetails({
                    ...this.details,
                    products: JSON.stringify({
                        ...this.productsList,
                        common_fields: this.commonFields,
                    }),
                });
            }
            this.$emit("premiumCalculating", true);
            this.calculateAndSetPremium();
        },
        getTotalSubProductPremiums(product) {
            const currentProductSubProducts =
                this.productsList[product]?.premiumBySubProduct;
            return Object.keys(currentProductSubProducts).reduce(
                (acc, curr) => {
                    return (
                        acc +
                        (currentProductSubProducts[curr].grossPremium || 0)
                    );
                },
                0
            );
        },
        calculatePremiumData(products, result) {
            Object.keys(products).forEach((product) => {
                result[product] = {
                    grossPremium: products[product].grossPremium,
                };
                if (
                    products[product].quickExtensions &&
                    products[product].quickExtensions.length
                ) {
                    result[product].addons = {};
                    products[product].quickExtensions.forEach((ext) => {
                        result[product].addons[ext.key] = {
                            grossPremium: ext.premium.grossPremium || 0,
                        };
                    });
                }
                if (
                    products[product].addOns &&
                    !this.isObjectEmpty(products[product].addOns)
                ) {
                    this.calculatePremiumData(products[product].addOns, result);
                }
            });
        },
        calculateAndSetPremium: debounce(async function () {
            try {
                let premiums = {};
                this.calculatePremiumData(this.productsList, premiums);
                const response = await LivePricing.livePricingCore({
                    product: "arch_contingency",
                    query: "totals",
                    productConfig: {
                        products: premiums,
                        brokeragePct: this.details.brokerage,
                    },
                });
                this.$emit("premiumUpdated", response);
            } catch (err) {
                this.displayErrorMessage("Failed to recalculate premium");
            } finally {
                this.$emit("premiumCalculating", false);
            }
        }, 300),
        checkIfExistInReferred(key) {
            if (!this.details?.referred) {
                return false;
            }
            if (this.details?.referredFields?.length) {
                return this.details.referredFields.indexOf(key) > -1;
            }
            return false;
        },
    },
};
</script>
