<template>
    <div
        class="bev-dropdown position-relative"
        :class="[containerClass, disabled ? 'disabled' : '']"
    >
        <v-popover
            distance="5"
            :container="`.${containerClass}`"
            :open="dropOpen"
            :disabled="listLoading || disabled"
            :placement="'bottom-end'"
            :boundary="`.${containerClass}`"
        >
            <div class="tooltip-target cursorPointer">
                <fieldset
                    class="drop-target"
                    :class="{ error: haveError, open: dropOpen }"
                >
                    <legend class="d-flex">
                        {{ dropDownLabel }}
                        <bev-image
                            v-if="infoImage.show"
                            :addBackground="true"
                            :backgroundColor="$colors.bevColors.inputInfoIcon"
                            :imageAlt="'info'"
                            :imageUrl="$imagePath.info"
                            width="16px"
                            height="16px"
                            v-tooltip="{
                                content: infoImage.info,
                                html: true,
                                placement: infoImage.tooltipPlacement || 'auto',
                                showTriggers: (events) => [...events, 'click'],
                                autoHide: true,
                                delay: 0,
                            }"
                            class="cursor-pointer mx-5px"
                        ></bev-image>
                    </legend>
                    <div
                        class="drop-value position-relative text-left overflow-hidden"
                    >
                        <p class="value-text">
                            {{ modelValue.length ? selectedTextHandler : "" }}
                        </p>
                        <bev-image
                            :addBackground="true"
                            :backgroundColor="'white'"
                            :imageUrl="$imagePath.caretDown"
                            :imageAlt="'caret-down'"
                            :width="'12px'"
                            :height="'7px'"
                            class="cursor-pointer position-absolute dropdown-caret-icon"
                            :class="{ open: dropOpen }"
                            v-if="!listLoading"
                        ></bev-image>
                        <spinner
                            v-if="listLoading"
                            :spinnerColor="$colors.bevColors.veryLightSecondary"
                            :width="'20px'"
                            :height="'20px'"
                            class="position-absolute dropdown-caret-icon"
                        ></spinner>
                    </div>
                    <spinner
                        class="center-positioned"
                        v-if="dropLoading"
                        :spinnerColor="$colors.bevColors.veryLightSecondary"
                        :width="'12px'"
                        :height="'12px'"
                    ></spinner>
                </fieldset>
            </div>
            <template #popper>
                <div class="popover-content">
                    <div
                        class="search-input-container"
                        v-if="
                            searchKey &&
                            ((ajax && ajaxList.length > 0) ||
                                (!ajax && options.length > 0) ||
                                pageLoading ||
                                searchInput !== '')
                        "
                    >
                        <div :class="['bev-input', 'active']">
                            <bev-image
                                class="search-icon"
                                :addBackground="true"
                                :backgroundColor="'white'"
                                :imageUrl="$imagePath.search"
                                :imageAlt="'search-icon'"
                                :width="'15px'"
                                :height="'15px'"
                            ></bev-image>
                            <input
                                v-model="searchInput"
                                @input="searchKeyup"
                                :type="'text'"
                                :placeholder="'Search'"
                            />
                        </div>
                    </div>
                    <ul
                        class="popover-list"
                        @scroll="getMoreDataOnScroll($event)"
                    >
                        <li class="drop-item px-0 select-all">
                            <bev-checkbox
                                inputLabel="Select All"
                                @update:modelValue="changeAllSelected"
                                class="drop-text"
                                :modelValue="isAllSelected"
                                extraClass="small fw-bold"
                            ></bev-checkbox>
                        </li>
                        <!-- isAllSelected -->
                        <li
                            class="drop-item px-0"
                            v-for="(option, index) in filteredResultList"
                            :key="index"
                            :class="{
                                active:
                                    ajax && responseInfo.uniqueKey && modelValue
                                        ? modelValue[responseInfo.uniqueKey] ===
                                          option[responseInfo.uniqueKey]
                                        : modelValue === option,
                            }"
                        >
                            <bev-checkbox
                                :inputLabel="
                                    optionTextHandler
                                        ? optionTextHandler(option)
                                        : option
                                "
                                @update:modelValue="optionChanged(option)"
                                class="drop-text"
                                :modelValue="
                                    isOptionSelected(option) || isAllSelected
                                "
                                extraClass="small"
                            ></bev-checkbox>
                        </li>
                        <li v-if="pageLoading">
                            <spinner
                                :spinnerColor="
                                    $colors.bevColors.veryLightSecondary
                                "
                                :width="'20px'"
                                :height="'20px'"
                                class="m-auto"
                            ></spinner>
                        </li>
                        <li
                            class="drop-item"
                            v-if="
                                filteredResultList.length === 0 && !pageLoading
                            "
                        >
                            No results found
                        </li>
                    </ul>
                </div>
            </template>
        </v-popover>
        <input-error
            v-if="haveError"
            :errorMessage="errorMessage"
        ></input-error>
    </div>
</template>

<script>
/**
 * Used in places where multiselect drop down is needed
 * @param {Array<any>} options drop down options
 * @param {String} dropDownLabel drop down label
 * @param {Function} optionTextHandler handler to render label for the dropdown options
 * @param {Boolean} haveError show showing error with the dropdown
 * @param {String} errorMessage error message to show if haveError is true
 * @param {null} modelValue selected option in dropdown
 * @param {String} containerClass dropdown container class
 * @param {Boolean} ajax to set if the options are need to be fetched by ajax
 * @param {String} apiUrl if ajax, which api url to call to fetch options
 * @param {String} apiMethod which api method to use for the ajax call
 * @param {Function} apiRequestBody if method is post, request body for the ajax call
 * @param {Object} responseInfo information related to the response of the ajax, which is being used to handle response on the component
 * @param {Boolean} disabled to set if dropdown is disbaled
 * @param {Boolean} dropLoading to show loader on the dropdown
 * @param {Boolean} showSearch to show search input on the dropdown
 * @param {Function} showItemCheck function to check every item before appending to the list
 * @param {Object} infoImage image props for showing info icon
 * @param {String} sorting if sorting is being used in the ajax call
 * * @param model for two way binding data to dropdown
 **/

import axios from "axios";
import debounce from "lodash.debounce";
export default {
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        optionTextHandler: Function,
        dropDownLabel: {
            type: String,
            default: "",
        },
        haveError: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
        modelValue: null,
        containerClass: {
            type: String,
            required: true,
        },
        ajax: Boolean,
        apiUrl: String,
        apiMethod: {
            type: String,
            default: () => {
                return "get";
            },
        },
        apiRequestBody: {
            type: Function,
            default: () => {
                return {};
            },
        },
        apiRequestParam: String,
        responseInfo: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
        dropLoading: {
            type: Boolean,
            default: false,
        },
        searchKey: {
            type: String,
            default: () => {
                return "";
            },
        },
        showItemCheck: {
            type: Function,
            default: () => {
                return true;
            },
        },
        infoImage: {
            type: Object,
            default: () => {
                return {};
            },
        },
        sorting: {
            type: String,
            default: () => {
                return "";
            },
        },
        refreshAjaxCounter: {
            type: Number,
            default: () => 0,
        },
        setFirstEntryAsInitial: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ["update:modelValue", "changeIsAllSelected"],
    data() {
        return {
            dropOpen: false,
            paginationBusy: false,
            pageLoading: false,
            searchInput: "",
            pageNumber: 0,
            size: 30,
            ajaxList: [],
            totalItems: 0,
            listLoading: false,
            isAllSelected: false,
            innerOptions: [],
        };
    },
    computed: {
        pageCount() {
            return this.getTotalCount(this.totalItems, this.size);
        },
        filteredResultList() {
            let finalList;
            if (this.ajax) {
                finalList = this.ajaxList;
            } else {
                finalList = this.innerOptions.filter((option) => {
                    return this.showItemCheck(option);
                });
            }
            return finalList;
        },
        getResultList() {
            let finalList;
            if (this.ajax) {
                finalList = this.ajaxList;
            } else {
                finalList = this.options.filter((option) => {
                    return this.showItemCheck(option);
                });
            }
            return finalList;
        },
        apiData() {
            return (
                this.apiUrl +
                JSON.stringify(this.apiRequestBody(this.searchInput)) +
                this.apiRequestParam
            );
        },
        selectedTextHandler() {
            return this.modelValue
                .map((s) => {
                    return this.optionTextHandler(s);
                })
                .join(", ");
        },
    },
    mounted() {
        if (this.ajax && !this.disabled) {
            this.getListItems(true);
        } else {
            this.innerOptions = this.options;
            if (this.setFirstEntryAsInitial) {
                this.$emit("update:modelValue", [this.getResultList[0]]);
            }
        }
    },
    methods: {
        optionExist(option) {
            return (
                this.modelValue.findIndex((op) => {
                    return (
                        op[this.responseInfo.uniqueKey] ===
                        option[this.responseInfo.uniqueKey]
                    );
                }) > -1
            );
        },
        searchKeyup() {
            if (this.ajax) {
                this.pageLoading = true;
            }
            this.ajaxList = [];
            this.searchResults();
        },
        searchResults: debounce(function () {
            if (this.ajax) {
                this.getListItems(true, true);
            } else {
                if (this.searchInput) {
                    this.innerOptions = this.innerOptions.filter((result) => {
                        return result[this.searchKey]
                            .toLowerCase()
                            .includes(this.searchInput.toLowerCase());
                    });
                } else {
                    this.innerOptions = this.options;
                }
            }
        }, 500),
        optionChanged(option) {
            let existingSelected = this.modelValue;
            if (this.isOptionSelected(option)) {
                this.$emit("changeIsAllSelected", false);
                this.isAllSelected = false;
                existingSelected = existingSelected.filter((s) => {
                    return (
                        s[this.responseInfo.uniqueKey] !==
                        option[this.responseInfo.uniqueKey]
                    );
                });
            } else {
                existingSelected = [...existingSelected, option];
            }
            this.$emit("update:modelValue", existingSelected);
        },
        async getListItems(initial, initialForSearch) {
            if (initial) {
                this.pageNumber = 0;
                if (!initialForSearch) {
                    this.listLoading = true;
                }
                this.ajaxList = [];
                this.totalItems = 0;
            }
            try {
                let searachKeyParam =
                    this.searchInput !== ""
                        ? `&${this.searchKey}=${this.searchInput}`
                        : "";
                let response = await axios[this.apiMethod](
                    this.apiUrl +
                        `?page=${this.pageNumber}&size=${this.size}${
                            this.apiRequestParam
                                ? `&${this.apiRequestParam}`
                                : ""
                        }${
                            this.sorting ? "&sort=" + this.sorting : ""
                        }${searachKeyParam}`,
                    this.apiMethod === "post"
                        ? this.apiRequestBody(this.searchInput)
                        : {}
                );
                const resultData = this.responseInfo.onRoot
                    ? response.data
                    : response.data[this.responseInfo.resultKey];
                if (this.responseInfo.pagination) {
                    if (resultData) {
                        this.appendToList(
                            resultData,
                            this.ajaxList,
                            this.responseInfo.uniqueKey,
                            this.showItemCheck
                        );
                        this.totalItems =
                            response.data[this.responseInfo.totalResultKey];
                    }
                } else {
                    this.appendToList(
                        resultData,
                        this.ajaxList,
                        this.responseInfo.uniqueKey,
                        this.showItemCheck
                    );
                }
                if (this.responseInfo.applyFilter) {
                    this.ajaxList = this.ajaxList.filter((p) =>
                        this.responseInfo.applyFilter(p)
                    );
                }
                if (this.isAllSelected) {
                    this.$emit("update:modelValue", this.ajaxList);
                }
                this.paginationBusy = false;
                this.pageLoading = false;
                this.listLoading = false;
                if (initial && !initialForSearch) {
                    if (this.setFirstEntryAsInitial) {
                        this.$emit("update:modelValue", [this.ajaxList[0]]);
                    }
                }
            } catch (err) {
                this.paginationBusy = false;
                this.pageLoading = false;
                this.listLoading = false;
            }
        },
        getMoreDataOnScroll(e) {
            if (this.ajax && this.apiUrl && this.responseInfo.pagination) {
                if (
                    Math.round(e.target.scrollTop + e.target.clientHeight) >=
                    e.target.scrollHeight
                ) {
                    if (
                        this.pageNumber + 1 < this.pageCount &&
                        !this.paginationBusy
                    ) {
                        this.pageNumber++;
                        this.paginationBusy = true;
                        this.pageLoading = true;
                        this.getListItems(false);
                    }
                }
            }
        },
        addToList(source, target, uniqueKey, itemCheck) {
            this.appendToList(source, target, uniqueKey, itemCheck);
        },
        isOptionSelected(option) {
            return (
                this.modelValue.findIndex((s) => {
                    if (this.responseInfo.itemType === "string") {
                        return s === option;
                    } else {
                        return (
                            s[this.responseInfo.uniqueKey] ===
                            option[this.responseInfo.uniqueKey]
                        );
                    }
                }) > -1
            );
        },
        changeAllSelected(e) {
            this.isAllSelected = e;
            this.$emit("changeIsAllSelected", e);
            if (!e) {
                this.$emit("update:modelValue", []);
            } else {
                this.$emit("update:modelValue", this.getResultList);
            }
        },
    },
    watch: {
        apiData: function (value) {
            if (value) {
                this.getListItems(true);
            }
        },
        refreshAjaxCounter: function (value) {
            if (value) {
                this.$emit("update:modelValue", []);
                this.getListItems(true);
            }
        },
        modelValue: function (value) {
            if (value && value.length === 0) {
                this.isAllSelected = false;
            }
        },
    },
};
</script>

<style scoped>
.popover-content {
    padding: 0px 20px;
}
.search-input-container {
    margin: 16px 0;
    background: var(--baseDark);
    border-radius: 6px;
}
.search-input-container input {
    transition: unset !important;
    background: var(--baseDark);
}
ul {
    padding: 0 20px !important;
    margin: 0 -20px !important;
}
.select-all {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.14);
}
</style>
