<template>
    <div class="product-section">
        <div
            class="row product-heading font-18 sm-font-14"
            @click="caretOpen = !caretOpen"
        >
            <div class="col-sm-10 col-md-10 col-lg-11">
                {{ $policyValues.productLabels[productHeading] }}
            </div>
            <div class="col-sm-2 col-md-2 col-lg-1">
                <div
                    class="d-flex align-items-center justify-content-end h-100"
                >
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="$colors.bevColors.danger"
                        :imageUrl="$imagePath.delete"
                        :imageAlt="'bin-icon'"
                        width="20px"
                        height="20px"
                        v-tooltip="'Remove Addon'"
                        :clickHandler="
                            removeProductHandler.bind(
                                this,
                                productHeading,
                                addonFor
                            )
                        "
                        v-if="!isDirectBuyFlow"
                    ></bev-image>
                    <bev-image
                        :addBackground="true"
                        :backgroundColor="
                            caretOpen
                                ? $colors.bevColors.activeColorV1
                                : 'white'
                        "
                        :imageUrl="$imagePath.caretDown"
                        :imageAlt="'caret-down'"
                        :width="'12px'"
                        :height="'7px'"
                        class="cursor-pointer ml-10px"
                        :class="{ open: caretOpen }"
                    ></bev-image>
                </div>
            </div>
        </div>
        <slide-up-down :active="caretOpen" :duration="1000">
            <div class="product-info">
                <div
                    class="product-info-item mb-15px"
                    v-for="(info, key) in getProductValue"
                    :key="key"
                >
                    <div class="row m-0" v-if="isEditable(key)">
                        <div
                            class="col-sm-6 col-md-6 col-lg-6 info-key font-15 sm-font-12"
                        >
                            <div class="d-flex align-items-center">
                                <label
                                    :class="
                                        checkIfExistInReferred(key) &&
                                        'text-yellow'
                                    "
                                    >{{ productLabelHandler(key, "label") }}
                                    <span
                                        class="font-12 text-activeColorV2 fst-italic"
                                        >{{
                                            checkIfExistInReferred(key)
                                                ? "*referred"
                                                : ""
                                        }}</span
                                    ></label
                                >
                                <bev-image
                                    :addBackground="true"
                                    :backgroundColor="
                                        $colors.bevColors.activeColorV1
                                    "
                                    :imageUrl="$imagePath.edit"
                                    :clickHandler="
                                        redirectToEditStep.bind(
                                            this,
                                            productLabelHandler(
                                                key,
                                                'component'
                                            ),
                                            productHeading
                                        )
                                    "
                                    :imageAlt="'edit-icon'"
                                    width="20px"
                                    height="20px"
                                    class="ml-10px"
                                    v-if="
                                        productLabelHandler(
                                            key,
                                            'component'
                                        ) !== 'SelectPeril'
                                    "
                                ></bev-image>
                            </div>
                        </div>
                        <div
                            class="col-sm-6 col-md-6 col-lg-6 info-value text-right font-15 sm-font-12"
                            :class="
                                checkIfExistInReferred(key) && 'text-yellow'
                            "
                        >
                            {{ info }}
                        </div>
                    </div>
                    <div class="row m-0" v-if="renderNoEditableField(key)">
                        <div
                            class="col-sm-6 col-md-6 col-lg-6 info-key font-16 sm-font-14 text-activeColorV1"
                        >
                            <label
                                :class="[
                                    'font-16',
                                    isSubProductPremiumField(key)
                                        ? 'text-activeColorV2'
                                        : 'text-activeColorV1 fw-600',
                                ]"
                                >{{
                                    key === "grossPremium"
                                        ? $policyValues.productLabels[
                                              this.productHeading
                                          ] + " - Gross Premium"
                                        : productLabelHandler(key, "label")
                                }}</label
                            >
                        </div>
                        <div
                            :class="[
                                'col-sm-6 col-md-6 col-lg-6 info-value text-right',
                                isSubProductPremiumField(key)
                                    ? 'text-activeColorV2'
                                    : 'text-activeColorV1',
                            ]"
                        >
                            <span v-if="!recalculatingPremiumLoading">
                                {{ isReferred ? "*Referred" : info }}
                            </span>
                            <spinner
                                v-if="recalculatingPremiumLoading"
                                :spinnerColor="
                                    $colors.bevColors.veryLightSecondary
                                "
                                :width="'20px'"
                                :height="'20px'"
                                class="ml-auto"
                            ></spinner>
                        </div>
                    </div>
                </div>
                <div class="row m-0" v-if="productQuickExtensions.length">
                    <div class="col-sm-12 py-10px">
                        <QuickExtensions
                            :productExtensions="productQuickExtensions"
                            :productHeading="productHeading"
                            :recalculatingPremiumLoading="
                                recalculatingPremiumLoading
                            "
                            :isReferred="isReferred"
                            :checkIfExistInReferred="checkIfExistInReferred"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="productInfo.addOns && !isObjectEmpty(productInfo.addOns)"
                class="addon-product"
            >
                <div v-for="(product, key) in productInfo.addOns" :key="key">
                    <ProductSection
                        :productHeading="key"
                        :productInfo="product"
                        :redirectToEditStep="redirectToEditStep"
                        :showProductActions="true"
                        :refreshProducts="refreshProducts"
                        :parentProduct="productInfo"
                        v-if="findInProducts(key)"
                    />
                </div>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
/**
    Single Product Block for the Cover Summary Page in Buy Policy Flow
    * @param {String} productHeading heading for the product
    * @param {Object} productInfo product info
    * @param {Function} redirectToEditStep handler to render respective question bundler component for the product field
    * @param {Function} refreshProducts handler to refresh productDataMap
    * @param {Function} findInProducts handler to find product in added products
    * @param {String} addonFor if product is an addon for some other product
    * @param {Function} recalculatePremium handler to recalculate premium if product premium change
**/
import { mapState, mapMutations, mapGetters } from "vuex";
import QuickExtensions from "@/components/Views/BuyPolicy/CoverSummary/QuickExtensions/Main.vue";
export default {
    name: "ProductSection",
    props: {
        parentProduct: Object,
        productHeading: {
            type: String,
            default: "",
        },
        productInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
        redirectToEditStep: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        refreshProducts: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        findInProducts: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        addonFor: {
            type: String,
            default: "",
        },
        recalculatePremium: {
            type: Function,
            default: () => {
                return () => {};
            },
        },
        recalculatingPremiumLoading: {
            type: Boolean,
            default: false,
        },
        isReferred: {
            type: Boolean,
            default: false,
        },
        checkIfExistInReferred: Function,
    },
    components: {
        QuickExtensions,
    },
    computed: {
        ...mapState("buyPolicy", [
            "productsFlow",
            "referredFields",
            "isEditingOld",
            "isDirectBuyFlow",
            "productsJSON",
            "quickExtensions",
            "cloneFrom",
            "quickExtensions",
        ]),
        ...mapGetters("auth", ["isNormalUser"]),
        ...mapGetters("buyPolicy", ["formattedCommonFields"]),
        getProductValue() {
            return this.getFormattedProductValue(
                this.productHeading,
                this.productInfo,
                this.formattedCommonFields,
                this.parentProduct,
                false,
                this.isReferred
            );
        },
    },
    mounted() {
        this.setProductQuickExtensions();
    },
    data() {
        return {
            caretOpen: true,
            productQuickExtensions: [],
        };
    },
    methods: {
        ...mapMutations("buyPolicy", [
            "setReferredFields",
            "removeProductFromFlow",
            "clearPolicyValues",
            "removeProduct",
            "setQuickExtensions",
        ]),
        /*
            handler to remove product from the productsFlow and product in BuyPolicy store
            If after removal productsFlow is empty redirect to starting of the flow
        */
        setProductQuickExtensions() {
            this.productQuickExtensions = JSON.parse(
                JSON.stringify(
                    this.productsJSON.find((product) => {
                        return product.name === this.productHeading;
                    })?.quickExtensions || []
                )
            );

            this.productQuickExtensions.forEach((ext, index) => {
                let existingIndex = this.quickExtensions[this.productHeading]
                    ? this.quickExtensions[this.productHeading]?.findIndex(
                          (e) => ext.key === e.key
                      )
                    : -1;
                this.productQuickExtensions[index].additionalFields =
                    this.productQuickExtensions[index].additionalFields.map(
                        (f) => {
                            if (
                                f.type === "list" &&
                                typeof f?.value === "string"
                            ) {
                                return {
                                    ...f,
                                    value: JSON.parse(f.value),
                                };
                            }
                            return f;
                        }
                    );
                if (existingIndex > -1) {
                    this.productQuickExtensions[index] = {
                        ...this.quickExtensions[this.productHeading][
                            existingIndex
                        ],
                        selected: true,
                    };
                }
            });
        },
        renderNoEditableField(key) {
            return (
                !this.isEditable(key) &&
                (key !== "grossPremium" ||
                    (key === "grossPremium" && !this.isNormalUser))
            );
        },
        removeSingleProductHandler(productName) {
            // remove a single product from the productsFlow and product in BuyPolicy store
            this.removeProductFromFlow(productName);
            this.removeProduct(productName);
            this.setReferredFields(
                this.referredFields.filter((r) => r.product !== productName)
            );
            if (this.productsFlow.length === 0) {
                // If after removal productsFlow is empty
                this.clearPolicyValues("SelectProduct");
            } else {
                // If after removal productsFlow is no empty, refresh productDataMap
                this.refreshProducts(false);
            }
            let currentQuickExtensions = JSON.parse(
                JSON.stringify(this.quickExtensions)
            );
            delete currentQuickExtensions[productName];
            this.setQuickExtensions({
                ...currentQuickExtensions,
            });
        },
        removeProductHandler(productName) {
            this.removeSingleProductHandler(productName);
            if (
                this.productInfo.addOns &&
                !this.isObjectEmpty(this.productInfo.addOns)
            ) {
                // if producthas addons run removeSingleProductHandler for every addon
                Object.keys(this.productInfo.addOns).forEach((p) => {
                    this.removeSingleProductHandler(p);
                });
            }
            // recalculate premium after product removal
            this.recalculatePremium();
        },
        isEditable(key) {
            return !this.$policyValues.nonEditable[key];
        },
        isSubProductPremiumField(key) {
            return this.$policyValues.subProductPremiumField[key];
        },
    },
};
</script>

<style scoped lang="scss">
.product-heading {
    word-spacing: 1px;
    color: var(--activeColorV3);
    background: var(--baseDark);
    padding: 10px;
    cursor: pointer;
    margin: 2px 0;
}
.product-info {
    padding: 0 10px;
    .product-info-item {
        border-bottom: 0.5px solid var(--baseShade2);
        padding: 10px 0;
        margin: 0;
        .info-key {
            font-weight: 200;
            line-height: 26px;
            padding-left: 15px;
        }
        .info-value {
            font-weight: 600;
            padding-right: 15px;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
.svg-image:hover {
    background: var(--activeColorV1) !important;
}
.addon-product {
    // border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 30px;
    .product-heading {
        font-size: 16px;
    }
    .product-info {
        .product-info-item {
            .info-key,
            .info-value {
                font-size: 14px;
            }
        }
    }
}
.addon-premium {
    padding: 10px;
    border-top: 0.5px solid var(--baseShade2);
}

.product-heading .svg-image {
    transition: 0.2s all ease-in-out;
}
.product-heading .svg-image.open {
    transform: translateY(-50%) rotate(180deg);
    margin-top: 7px;
}
</style>
