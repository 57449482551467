<template>
    <div
        class="comment-content px-30px py-30px"
        @scroll="getCommentOnScroll($event)"
    >
        <bev-loading-block
            v-if="
                commentListArray.length === 0 || commentLoading || commentError
            "
            :loading="commentLoading"
            :error="commentError"
            :retryHandler="getCommentsForQuote.bind(this, true)"
            :errorMessage="commentErrorMessage || 'No comment found'"
        />
        <DayComments
            v-for="(value, propertyName) in commentList"
            :key="propertyName"
            :date="propertyName"
            :commentList="value"
        />
        <spinner
            v-if="pageLoading"
            :spinnerColor="$colors.bevColors.veryLightSecondary"
            :width="'20px'"
            :height="'20px'"
            class="mt-10px m-auto"
        ></spinner>
    </div>
</template>

<script>
/**
 * Comment List for the quote
 */
import CommentService from "@/services/CommentService";
import _groupBy from "lodash.groupby";
import { mapState, mapMutations } from "vuex";
import DayComments from "@/components/Views/Details/Comments/CommentList/SingleDayCommentList.vue";
export default {
    data() {
        return {
            commentListArray: [],
            listBusy: false,
            pageLoading: false,
            pageNumber: 0,
            size: 30,
            totalElements: 0,
            totalPages: 0,
            commentLoading: false,
            commentError: false,
            commentErrorMessage: "",
        };
    },
    props: {
        commentOnNumber: String,
    },
    components: {
        DayComments,
    },
    computed: {
        ...mapState("comment", ["addedComment", "newCommentAdded"]),
        commentList() {
            if (this.commentListArray.length) {
                return _groupBy(this.commentListArray, (item) => {
                    return this.formatDateValue(
                        item.creationTime,
                        "date",
                        "MM/dd/yyyy"
                    );
                });
            }
            return {};
        },
    },
    mounted() {
        this.getCommentsForQuote(true);
    },
    methods: {
        ...mapMutations("comment", ["setNewCommentAdded", "setAddedComment"]),
        getCommentOnScroll(e) {
            if (
                Math.ceil(e.target.scrollTop + e.target.clientHeight) >=
                e.target.scrollHeight
            ) {
                if (this.pageNumber + 1 < this.totalPages && !this.listBusy) {
                    this.listBusy = true;
                    this.pageNumber = this.pageNumber + 1;
                    this.pageLoading = true;
                    this.getCommentsForQuote(false);
                }
            }
        },
        async getCommentsForQuote(freshCall) {
            if (freshCall) {
                this.pageNumber = 0;
                this.totalElements = 0;
                this.totalPages = 0;
                this.commentLoading = true;
                this.listBusy = true;
                this.commentError = false;
            }

            try {
                const response = await CommentService.getComments(
                    this.pageNumber,
                    this.size,
                    this.commentOnNumber
                );
                var existingIds = new Set(
                    this.commentListArray.map((d) => d.id)
                );
                this.commentListArray = [
                    ...this.commentListArray,
                    ...response.data.content.filter(
                        (d) => !existingIds.has(d.id)
                    ),
                ];
                this.totalPages = response.data.totalPages;
            } catch (err) {
                this.displayErrorMessage(
                    err?.response?.data?.message || "Failed to load comments"
                );
                if (freshCall) {
                    this.commentError = true;
                    this.commentErrorMessage = "Failed to load comment";
                }
            } finally {
                this.commentLoading = false;
                this.pageLoading = false;
                this.listBusy = false;
            }
        },
    },
    watch: {
        newCommentAdded: function (val) {
            if (val) {
                if (this.isObjectEmpty(this.commentList)) {
                    this.getCommentsForQuote(true);
                } else {
                    this.commentListArray.unshift(this.addedComment);
                }
                this.setAddedComment(null);
                this.setNewCommentAdded(false);
            }
        },
    },
};
</script>

<style scoped>
.comment-content {
    height: calc(100% - 170px);
    overflow: auto;
}
</style>
