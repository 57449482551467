<template>
    <div
        class="pb-30px pt-50px sm-pt-30px px-20px w-100 h-100 d-flex flex-column overflow-y-auto"
    >
        <buy-policy-heading-block
            :align="'center'"
            :heading="`Event Liability - Financial Information`"
            :description="''"
            :headingSizeClass="'font-22 sm-font-16'"
            :descriptionSizeClass="'font-16 sm-font-12'"
            class="less-width-description"
        >
            <CoverSummaryButton
                :clickHandler="setLiability.bind(this, false, false, true)"
            />
        </buy-policy-heading-block>
        <bev-form
            class="event-liability-form"
            :submitData="setLiability.bind(this, true, false, true)"
            :submitButton="{
                text: 'Next',
                buttonType: 'submit',
            }"
            :resetButton="{
                text: 'Back',
                clickHandler: setLiability.bind(this, false, true, false),
                buttonType: 'button',
            }"
        >
            <template v-slot:input-fields>
                <div class="event-liability-form-content pt-20px mt-30px">
                    <div class="row">
                        <p class="font-18 fw-600 mb-20px px-15px">
                            Public Liability
                        </p>
                        <div class="col-sm-12">
                            <bev-input
                                :inputType="'text'"
                                :validationType="'whole_number'"
                                :inputLabel="
                                    productLabelHandler(
                                        'noOfAttendees',
                                        'label'
                                    ) + '*'
                                "
                                :inputClass="'no_of_attendees'"
                                v-model="v$.noOfAttendees.$model"
                                :haveError="
                                    v$.noOfAttendees.$error &&
                                    (v$.noOfAttendees.required.$invalid ||
                                        v$.noOfAttendees.maxValue.$invalid)
                                "
                                :errorMessage="noOfAttendeesErrorMessage"
                            >
                            </bev-input>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <bev-dropdown
                                :options="indemnityLimits"
                                :dropDownLabel="
                                    productLabelHandler(
                                        'limitOfIndemnity',
                                        'label'
                                    ) + '*'
                                "
                                :containerClass="'limitOfIndemnity'"
                                v-model="v$.limitOfIndemnity.$model"
                                :fullWidthInput="false"
                                :optionTextHandler="limitOptionTextHandler"
                                :haveError="
                                    v$.limitOfIndemnity.$error &&
                                    v$.limitOfIndemnity.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'limit of indemnity'
                                    )
                                "
                            >
                            </bev-dropdown>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <option-selector
                                :question="
                                    productLabelHandler(
                                        'insureParticipants',
                                        'label'
                                    ) + '*'
                                "
                                :selectHandler="setInsureParticipants"
                                :error="
                                    v$.insureParticipants.$error &&
                                    v$.insureParticipants.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :selectedOption="insureParticipants"
                                :options="['Yes', 'No']"
                            >
                            </option-selector>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <option-selector
                                :question="
                                    productLabelHandler(
                                        'eventCarriedOutPrior',
                                        'label'
                                    ) + '*'
                                "
                                :selectHandler="setEventCarriedOutPrior"
                                :error="
                                    v$.eventCarriedOutPrior.$error &&
                                    v$.eventCarriedOutPrior.required.$invalid
                                "
                                :errorMessage="
                                    $messages.errorMessages.dropdownSelectError(
                                        'an option'
                                    )
                                "
                                :selectedOption="eventCarriedOutPrior"
                                :options="['Yes', 'No']"
                            >
                            </option-selector>
                        </div>
                        <div class="col-sm-12 mt-30px">
                            <bev-checkbox
                                class="w-auto"
                                v-model="employersLiabilityEnabled"
                                :extraClass="'medium'"
                            >
                                <template v-slot:label>
                                    <p>
                                        {{
                                            productLabelHandler(
                                                "employersLiabilityEnabled",
                                                "label"
                                            )
                                        }}
                                    </p>
                                </template>
                            </bev-checkbox>
                        </div>
                        <template v-if="employersLiabilityEnabled">
                            <p class="font-18 fw-600 mt-20px px-15px">
                                Employers Liability
                            </p>
                            <div class="col-sm-12 mt-30px">
                                <bev-input
                                    :inputType="'text'"
                                    :validationType="'whole_number'"
                                    :inputLabel="
                                        productLabelHandler(
                                            'noOfEmployeesEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :inputClass="'noOfEmployees'"
                                    v-model="noOfEmployeesEL"
                                    :haveError="
                                        v$.noOfEmployeesEL.$error &&
                                        v$.noOfEmployeesEL.required.$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.fieldCannotEmptyError(
                                            'Number of Employees'
                                        )
                                    "
                                    :infoImage="{
                                        show: true,
                                        info: noOfEmployeeELTooltipText,
                                        tooltipPlacement: 'right',
                                    }"
                                ></bev-input>
                            </div>
                            <p class="font-16 fw-600 mt-30px px-15px">
                                Employee Split
                            </p>
                            <div class="col-sm-12 mt-30px">
                                <bev-input
                                    :inputType="'text'"
                                    :validationType="'whole_number'"
                                    :inputLabel="
                                        productLabelHandler(
                                            'clericalEmployeeSplitEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :inputClass="'clericalEmployeeSplitEL'"
                                    v-model="clericalEmployeeSplitEL"
                                    :haveError="
                                        v$.clericalEmployeeSplitEL.$error &&
                                        v$.clericalEmployeeSplitEL.required
                                            .$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.fieldCannotEmptyError(
                                            'Clerical Employee Split'
                                        )
                                    "
                                ></bev-input>
                            </div>
                            <div class="col-sm-12 col-lg-6 mt-30px">
                                <bev-input
                                    :inputType="'text'"
                                    :validationType="'whole_number'"
                                    :inputLabel="
                                        productLabelHandler(
                                            'manualLightEmployeeSplitEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :inputClass="'manualLightEmployeeSplitEL'"
                                    v-model="manualLightEmployeeSplitEL"
                                    :haveError="
                                        v$.manualLightEmployeeSplitEL.$error &&
                                        v$.manualLightEmployeeSplitEL.required
                                            .$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.fieldCannotEmptyError(
                                            'Manual (Light) Employee Split'
                                        )
                                    "
                                    :infoImage="{
                                        show: true,
                                        info: manualLightEmployeeSplitELTooltipText,
                                        tooltipPlacement: 'right',
                                    }"
                                ></bev-input>
                            </div>
                            <div class="col-sm-12 col-lg-6 mt-30px">
                                <bev-input
                                    :inputType="'text'"
                                    :validationType="'whole_number'"
                                    :inputLabel="
                                        productLabelHandler(
                                            'manualHeavyEmployeeSplitEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :inputClass="'clericalEmployeeSplitEL'"
                                    v-model="manualHeavyEmployeeSplitEL"
                                    :haveError="
                                        v$.manualHeavyEmployeeSplitEL.$error &&
                                        v$.manualHeavyEmployeeSplitEL.required
                                            .$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.fieldCannotEmptyError(
                                            'Manual (Heavy) Employee Split'
                                        )
                                    "
                                    :infoImage="{
                                        show: true,
                                        info: manualHeavyEmployeeSplitELTooltipText,
                                        tooltipPlacement: 'right',
                                    }"
                                ></bev-input>
                            </div>
                            <div
                                class="col-sm-12 mt-30px"
                                v-if="
                                    manualHeavyEmployeeSplitEL &&
                                    manualHeavyEmployeeSplitEL > 0
                                "
                            >
                                <basic-textarea
                                    :inputLabel="
                                        productLabelHandler(
                                            'manualHeavyEmployeeSplitDescriptionEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :inputClass="'manualHeavyEmployeeSplitDescriptionEL'"
                                    :haveError="
                                        v$.manualHeavyEmployeeSplitDescriptionEL
                                            .$error &&
                                        v$.manualHeavyEmployeeSplitDescriptionEL
                                            .required.$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.fieldCannotEmptyError(
                                            'This Elaboration'
                                        )
                                    "
                                    v-model="
                                        v$.manualHeavyEmployeeSplitDescriptionEL
                                            .$model
                                    "
                                ></basic-textarea>
                            </div>
                            <div class="col-sm-12 mt-30px">
                                <option-selector
                                    :question="
                                        productLabelHandler(
                                            'noHazardousActivityEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :selectHandler="setNoHazardousActivityEL"
                                    :error="
                                        v$.noHazardousActivityEL.$error &&
                                        v$.noHazardousActivityEL.required
                                            .$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.dropdownSelectError(
                                            'an option'
                                        )
                                    "
                                    :selectedOption="noHazardousActivityEL"
                                    :options="['Yes', 'No']"
                                    :infoImage="{
                                        show: true,
                                        info: noHazardousActivityELTooltipText,
                                        tooltipPlacement: 'right',
                                    }"
                                >
                                </option-selector>
                            </div>
                            <div class="col-sm-12 mt-30px">
                                <option-selector
                                    :question="
                                        productLabelHandler(
                                            'employeeUnderUKLawEL',
                                            'label'
                                        ) + '*'
                                    "
                                    :selectHandler="setEmployeeUnderUKLawEL"
                                    :error="
                                        v$.employeeUnderUKLawEL.$error &&
                                        v$.employeeUnderUKLawEL.required
                                            .$invalid
                                    "
                                    :errorMessage="
                                        $messages.errorMessages.dropdownSelectError(
                                            'an option'
                                        )
                                    "
                                    :selectedOption="employeeUnderUKLawEL"
                                    :options="['Yes', 'No']"
                                >
                                </option-selector>
                            </div>
                            <div class="col-sm-12 mt-30px">
                                <bev-input
                                    :inputType="'text'"
                                    :inputLabel="
                                        productLabelHandler('ernEL', 'label')
                                    "
                                    :inputClass="'ernEL'"
                                    v-model="v$.ernEL.$model"
                                >
                                </bev-input>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </bev-form>
        <SaveAsDraftButton :dataSaveHandler="setLiability" />
    </div>
</template>

<script>
/**
 * QuestionBundle component for PublicLiability
 */
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue, maxValue } from "@vuelidate/validators";
import BasicTextarea from "@/components/Common/Input/BasicTextarea";
import OptionSelector from "@/components/Views/BuyPolicy/OptionSelector";
import CoverSummaryButton from "@/components/Views/BuyPolicy/CoverSummaryButton";
import SaveAsDraftButton from "@/components/Views/BuyPolicy/SaveAsDraftButton";
import BuyPolicyHeadingBlock from "@/components/Views/BuyPolicy/BuyPolicyHeadingBlock";
import customHtml from "@/constant/customHtml";
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        OptionSelector,
        CoverSummaryButton,
        SaveAsDraftButton,
        BuyPolicyHeadingBlock,
        BasicTextarea,
    },
    created() {
        this.noOfAttendees = this.checkNumberValueAndSet(
            this.questionBundles["EventLiability"]?.noOfAttendees
        );
        this.insureParticipants =
            this.questionBundles["EventLiability"]?.insureParticipants || null;
        this.limitOfIndemnity =
            this.questionBundles["EventLiability"]?.limitOfIndemnity || null;
        this.eventCarriedOutPrior =
            this.questionBundles["EventLiability"]?.eventCarriedOutPrior ||
            null;
        this.employersLiabilityEnabled =
            this.questionBundles["EventLiability"]?.employersLiabilityEnabled ||
            false;
        this.initializeEmployersLiability();
    },
    validations() {
        // Validations for every form field
        return {
            noOfAttendees: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(640000),
            },
            limitOfIndemnity: {
                required,
            },
            insureParticipants: {
                required,
            },
            eventCarriedOutPrior: {
                required,
            },
            noOfEmployeesEL: {
                required: this.employersLiabilityEnabled ? required : false,
            },
            clericalEmployeeSplitEL: {
                required: this.employersLiabilityEnabled ? required : false,
            },
            manualLightEmployeeSplitEL: {
                required: this.employersLiabilityEnabled ? required : false,
            },
            manualHeavyEmployeeSplitEL: {
                required: this.employersLiabilityEnabled ? required : false,
            },
            manualHeavyEmployeeSplitDescriptionEL: {
                required:
                    this.employersLiabilityEnabled &&
                    this.manualHeavyEmployeeSplitEL &&
                    this.manualHeavyEmployeeSplitEL > 0
                        ? required
                        : false,
            },
            noHazardousActivityEL: {
                required: this.employersLiabilityEnabled ? required : false,
            },
            employeeUnderUKLawEL: {
                required: this.employersLiabilityEnabled ? required : false,
            },
            ernEL: {},
        };
    },
    computed: {
        ...mapState("buyPolicy", ["questionBundles"]),
        ...mapGetters("buyPolicy", ["checkFlowComplete"]),
        noOfAttendeesErrorMessage() {
            if (this.v$.noOfAttendees.required.$invalid) {
                return this.$messages.errorMessages.fieldCannotEmptyError(
                    "Number of Attendees/Delegates/Exhibitors"
                );
            } else {
                return "Please enter a value between 0 - 640,000";
            }
        },
        employersLiabilityData() {
            if (!this.employersLiabilityEnabled) {
                return {
                    noOfEmployeesEL: null,
                    clericalEmployeeSplitEL: null,
                    manualHeavyEmployeeSplitEL: null,
                    manualLightEmployeeSplitEL: null,
                    manualHeavyEmployeeSplitDescriptionEL: "",
                    noHazardousActivityEL: "",
                    employeeUnderUKLawEL: "",
                    ernEL: "",
                };
            }
            return {
                noOfEmployeesEL: this.noOfEmployeesEL,
                clericalEmployeeSplitEL: this.clericalEmployeeSplitEL,
                manualHeavyEmployeeSplitEL: this.manualHeavyEmployeeSplitEL,
                manualLightEmployeeSplitEL: this.manualLightEmployeeSplitEL,
                ...(this.manualHeavyEmployeeSplitEL &&
                    this.manualHeavyEmployeeSplitEL > 0 && {
                        manualHeavyEmployeeSplitDescriptionEL:
                            this.manualHeavyEmployeeSplitDescriptionEL,
                    }),
                noHazardousActivityEL: this.noHazardousActivityEL,
                employeeUnderUKLawEL: this.employeeUnderUKLawEL,
                ...(this.ernEL && {
                    ernEL: this.ernEL,
                }),
            };
        },
    },
    data() {
        return {
            indemnityLimits: [1000000, 2000000, 5000000, 10000000],
            limitOfIndemnity: "",
            insureParticipants: "",
            noOfAttendees: null,
            eventCarriedOutPrior: "",
            employersLiabilityEnabled: false,
            noOfEmployeesEL: null,
            clericalEmployeeSplitEL: null,
            manualHeavyEmployeeSplitEL: null,
            manualLightEmployeeSplitEL: null,
            manualHeavyEmployeeSplitDescriptionEL: "",
            noHazardousActivityEL: "",
            employeeUnderUKLawEL: "",
            ernEL: "",
            noOfEmployeeELTooltipText: customHtml.noOfEmployeeELTooltip,
            manualLightEmployeeSplitELTooltipText:
                customHtml.manualLightEmployeeSplitELTooltip,
            manualHeavyEmployeeSplitELTooltipText:
                customHtml.manualHeavyEmployeeSplitELTooltip,
            noHazardousActivityELTooltipText:
                customHtml.noHazardousActivityELTooltip,
        };
    },
    methods: {
        ...mapMutations("buyPolicy", ["setQuestionBundle", "setBuyPolicyStep"]),
        ...mapActions("buyPolicy", [
            "takeToPreviousStep",
            "takeToNextStep",
            "updateDataBundleWithValidations",
        ]),
        initializeEmployersLiability() {
            this.noOfEmployeesEL = this.checkNumberValueAndSet(
                this.questionBundles["EventLiability"]?.noOfEmployeesEL
            );
            this.clericalEmployeeSplitEL = this.checkNumberValueAndSet(
                this.questionBundles["EventLiability"]?.clericalEmployeeSplitEL
            );
            this.manualLightEmployeeSplitEL = this.checkNumberValueAndSet(
                this.questionBundles["EventLiability"]
                    ?.manualLightEmployeeSplitEL
            );
            this.manualHeavyEmployeeSplitEL = this.checkNumberValueAndSet(
                this.questionBundles["EventLiability"]
                    ?.manualHeavyEmployeeSplitEL
            );
            this.manualHeavyEmployeeSplitDescriptionEL =
                this.questionBundles["EventLiability"]
                    ?.manualHeavyEmployeeSplitDescriptionEL || "";
            this.noHazardousActivityEL =
                this.questionBundles["EventLiability"]?.noHazardousActivityEL ||
                "";
            this.employeeUnderUKLawEL =
                this.questionBundles["EventLiability"]?.employeeUnderUKLawEL ||
                "";
            this.ernEL = this.questionBundles["EventLiability"]?.ernEL || "";
        },
        limitOptionTextHandler(option) {
            return this.thousandMask(option, "currency");
        },
        setLiability(forNext, forBack, notSaveForDraft) {
            // Handler to take every filled data and set into PublicLiability questionBundle
            this.updateDataBundleWithValidations({
                bundleName: "EventLiability",
                bundleData: {
                    noOfAttendees: this.noOfAttendees,
                    insureParticipants: this.insureParticipants,
                    limitOfIndemnity: this.limitOfIndemnity,
                    eventCarriedOutPrior: this.eventCarriedOutPrior,
                    employersLiabilityEnabled: this.employersLiabilityEnabled,
                    ...this.employersLiabilityData,
                },
                self: this,
            });
            if (forBack) {
                this.takeToPreviousStep();
            } else if (notSaveForDraft) {
                if (!this.v$.$invalid) {
                    if (this.checkFlowComplete && !forNext) {
                        this.setBuyPolicyStep({
                            step: "CoverSummary",
                            back: false,
                        });
                    } else {
                        this.takeToNextStep();
                    }
                }
            } else if (!this.employersLiabilityEnabled) {
                this.initializeEmployersLiability();
            }
        },
        setInsureParticipants(choice) {
            this.insureParticipants = choice;
        },
        setEventCarriedOutPrior(choice) {
            this.eventCarriedOutPrior = choice;
        },
        setNoHazardousActivityEL(choice) {
            this.noHazardousActivityEL = choice;
        },
        setEmployeeUnderUKLawEL(choice) {
            this.employeeUnderUKLawEL = choice;
        },
    },
    watch: {
        employeeUnderUKLawEL: function (val) {
            if (val === "No") {
                this.$notify({
                    group: "BEV",
                    title: "Info",
                    text: "Non-UK employees will not be covered under this EL product and cover should be under a policy in their country of domicile (i.e. workers comp in US)",
                    type: "info",
                });
            }
        },
    },
};
</script>

<style scoped>
.event-liability-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.event-liability-form-content {
    width: 800px;
    max-width: 100%;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}
</style>
